import React, { useState, createContext, useContext, ReactNode } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateTourClickActions, updateOpenDrawerHolidays } from "../../redux/organizations/organizationSlice";
// UTILS
import { getTourBackground, getTourFontColor } from "../../utils/utils";

// Define the Context Type
interface TourContextType {
	startTour: () => void;
}

// Create Context
const TourContext = createContext<TourContextType | undefined>(undefined);

// Tour Provider
export const TourProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");
	const steps: Step[] = [
		{
			target: ".tourOrgHSteep1",
			content: t("tour.tourOrganizationHoliday1"),
			placement: "bottom",
			disableBeacon: true,
		},
		{
			target: ".tourOrganizationHolidaySteep2",
			content: t("tour.tourOrganizationHoliday2"),
			placement: "top",
			disableBeacon: true,
		},
		{
			target: ".tourOrganizationHolidaySteep0",
			content: t("tour.tourOrganizationHoliday3"),
			placement: "top",
			disableBeacon: true,
		},
		{
			target: ".tourOrgHSteep3",
			content: t("tour.tourOrganizationHoliday4"),
			placement: "top",
			disableBeacon: true,
		},
		{
			target: ".tourOrgHSteep4",
			content: t("tour.tourOrganizationHoliday5"),
			placement: "top",
			disableBeacon: true,
		},
		{
			target: ".tourOrgHSteep5",
			content: t("tour.tourOrganizationHoliday6"),
			placement: "top",
			disableBeacon: true,
		},
		{
			target: ".tourOrgHSteep6",
			content: t("tour.tourOrganizationHoliday7"),
			placement: "top",
			disableBeacon: true,
		},
	];

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [runTour, setRunTour] = useState<boolean>(false);
	const [stepIndex, setStepIndex] = useState<number>(0);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const startTour = () => {
		setRunTour(true);
		setStepIndex(0);
	};

	const handleJoyrideCallback = (data: CallBackProps) => {
		const { status, type, index }: any = data;
		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			setRunTour(false);
			setStepIndex(0);
		} else if (type === "step:after") {
			if (index === 1) {
				dispatch(updateTourClickActions(true));
				setStepIndex((prev) => prev + 1);
			} else if (index === 2) {
				dispatch(updateOpenDrawerHolidays(true));
				setRunTour(false);
				setTimeout(() => {
					setRunTour(true);
					setStepIndex((prev) => prev + 1);
				}, 1000); // Wait for the drawer animation to finish
			} else if (index === 6) {
				dispatch(updateOpenDrawerHolidays(false));
				setStepIndex((prev) => prev + 1);
			} else {
				setStepIndex((prev) => prev + 1);
			}
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<TourContext.Provider value={{ startTour }}>
			{children}
			<Joyride
				continuous
				run={runTour}
				steps={steps}
				stepIndex={stepIndex}
				callback={handleJoyrideCallback}
				showProgress
				showSkipButton
				disableScrolling
				styles={{
					options: {
						zIndex: 9999,
						arrowColor: "#f39c12",
						backgroundColor: `${getTourBackground(theme)}`,
						textColor: `${getTourFontColor(theme)}`,
						primaryColor: "#2968c0",
						overlayColor: "rgba(0, 0, 0, 0.4)",
					},
					buttonBack: {
						color: "white",
						backgroundColor: "gray",
					},
					buttonSkip: {
						color: "white",
						backgroundColor: "#f94d4f",
					},
				}}
			/>
		</TourContext.Provider>
	);
};

// Hook to use Tour Context
export const useTour = (): TourContextType => {
	const context = useContext(TourContext);
	if (!context) {
		throw new Error("useTour must be used within a TourProvider");
	}
	return context;
};
