export const findDuplicateIndices = (checkData: any[], keys: string[], messageTypeSelected: string) => {
	const seen = new Map<string, number>();
	let copy = structuredClone(checkData);
	if (messageTypeSelected !== "ALL") {
		copy = copy.filter((item) => item.type === messageTypeSelected);
	}
	copy.forEach((item, index) => {
		const keyCombination = keys.map((key) => item[key]).join("|");
		const hasOnlyZeros = keys.every((key) => item[key] === 0 || item[key] === "0");
		if (hasOnlyZeros || seen.has(keyCombination)) {
			copy[index].isValid = false;
		} else {
			seen.set(keyCombination, index);
		}
	});

	return copy;
};

export const updateSpecificKeys = (arr1: any[], arr2: any[], keysToUpdate: string[]) =>
	arr1.map((obj1: any) => {
		const obj2: any = arr2.find((o) => o.idMessage === obj1.idMessage);
		if (obj2) {
			keysToUpdate.forEach((key) => {
				if (key in obj2) {
					// eslint-disable-next-line no-param-reassign
					obj1[key] = obj2[key].toString();
					// eslint-disable-next-line no-param-reassign
					obj1.isValid = true;
				}
			});
		}
		return obj1;
	});

const replaceEmptyStrings = (obj: any) =>
	Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === "" ? 0 : value]));

export const buildCustomKeysObject = (messages: any, adapterInterfaceKeys: any) =>
	messages.map((msg: any) => ({
		isValid: true,
		idKey: "",
		methodType: "POST",
		idMessage: msg.id,
		message: msg.short_message,
		type: msg.message_type.name,
		...replaceEmptyStrings(adapterInterfaceKeys),
	}));

export const buildAssignedKeysobject = (data: any, keys: any) => {
	const copy = structuredClone(data);
	keys.forEach((item: any) => {
		const index = copy.findIndex((x: any) => x.idMessage === item.message_id);
		if (index >= 0) {
			const target = copy[index];
			target.isValid = true;
			target.idKey = item.id;
			target.methodType = "PUT";
			Object.entries(item.value).forEach(([key, value]) => {
				target[key] = value;
			});
		}
	});
	return copy;
};
