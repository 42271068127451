import React from "react";
// ANT DESIGN COMPONENTS
import { Button, Tooltip } from "antd";
// FLOAT BUTTON
import { Fab } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
// REDUX
import { useSelector } from "react-redux";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// COMPONENTS
import { useTour } from "../../tour/TourOrganizationHoliday";
// INTERFACE
import { RootState } from "../../../app/store";

function OrganizationInfoButton() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { startTour } = useTour();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerHolidays } = useSelector((state: RootState) => state.organization);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<>
			{!openDrawerHolidays && (
				<Fab
					icon={
						<Tooltip placement='left' title={t("general.information")}>
							<Button type='primary' shape='circle' icon={<FontAwesomeIcon icon={faCircleInfo} />} />
						</Tooltip>
					}
					mainButtonStyles={{
						backgroundColor: "#2968c0",
						width: "40px",
						height: "40px",
						position: "fixed",
						bottom: "30px",
						right: "30px",
					}}
					event='hover'
				>
					{/* Holidays */}
					<Tooltip placement='left' title={t("general.howToAddHoliday")}>
						<div className='tourOrgHSteep1' style={{ margin: "-10px -15px 0px 10px" }}>
							<Button onClick={startTour} type='primary' shape='circle' icon={<FontAwesomeIcon icon={faCalendar} />} />
						</div>
					</Tooltip>
				</Fab>
			)}
		</>
	);
}

export default OrganizationInfoButton;
