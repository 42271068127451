/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { AdapterInterface } from "../../interfaces/Adapter.interface";

export interface AdapterState {
	adapter: AdapterInterface;
	openDrawerAdapter: boolean;
	openModalCreateNewAdapter: boolean;
	adapterRefreshTable: boolean;
	openModalDeleteAdapter: boolean;
	formCreateEdit: string;
	openDrawerAssignActions: boolean;
}

const initialState: AdapterState = {
	adapter: {
		created_at: "2024-04-22T20:08:33.235602Z",
		updated_at: "2025-02-18T19:19:02.146561Z",
		server_id: null,
		deleted_at: null,
		name: "CEC-Swift-3C16F",
		version: "1.0.0",
		auth_credentials: {
			username: "",
			password: "",
		},
		socket_address: "127.0.0.1:1234",
		auth_type: "PASSWORD",
		type: "WEBSOCKET",
		protocol_type: "MCE",
		lift_type: "ELEVATOR",
		dictionary: {},
		interface: {
			key: "",
			value: "",
			adapter: "",
		},
		keys: [],
		total_bacnet_channels: 0,
		id: "uuid-123",
		active: true,
	},
	openDrawerAdapter: false,
	openModalCreateNewAdapter: false,
	adapterRefreshTable: false,
	openModalDeleteAdapter: false,
	formCreateEdit: "CREATE",
	openDrawerAssignActions: false,
};

export const adapterSlice = createSlice({
	name: "adapter",
	initialState,
	reducers: {
		updateAdapter: (state, action: PayloadAction<AdapterInterface>) => {
			state.adapter = action.payload;
		},
		updateOpenDrawerAdapter: (state, action: PayloadAction<boolean>) => {
			state.openDrawerAdapter = action.payload;
		},
		updateOpenModalCreateNewAdapter: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateNewAdapter = action.payload;
		},
		updateAdapterRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.adapterRefreshTable = action.payload;
		},
		updateOpenModalDeleteAdapter: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteAdapter = action.payload;
		},
		updateFormCreateEdit: (state, action: PayloadAction<string>) => {
			state.formCreateEdit = action.payload;
		},
		updateOpenDrawerAssignActions: (state, action: PayloadAction<boolean>) => {
			state.openDrawerAssignActions = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateAdapter,
	updateOpenDrawerAdapter,
	updateOpenModalCreateNewAdapter,
	updateAdapterRefreshTable,
	updateOpenModalDeleteAdapter,
	updateFormCreateEdit,
	updateOpenDrawerAssignActions,
} = adapterSlice.actions;

export default adapterSlice.reducer;
