import React from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";

function SelectInterfaceItem({ setJsonItem }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const KEYS = ["key", "value", "bit", "byte", "name", "bacnet"];

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Select className='generalStyles__width100' size='small' onChange={setJsonItem}>
			{KEYS.map((item: string) => (
				<Option key={item} value={item}>
					{item}
				</Option>
			))}
		</Select>
	);
}

export default SelectInterfaceItem;
