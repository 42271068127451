import React from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";

function SelectInterfaceTemplates({ setJsonInterface }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const TEMPLATES = [
		{ name: "DEFAULT", template: { byte: "", bit: "", value: "" } },
		{ name: "BACNET", template: { bacnet: "" } },
	];

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChange = (newValue: string) => {
		const index = TEMPLATES.findIndex((item: any) => item.name === newValue);
		setJsonInterface(TEMPLATES[index].template);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Select className='generalStyles__width100' size='small' onChange={onChange}>
			{TEMPLATES.map((item: any) => (
				<Option key={item.name} value={item.name}>
					{item.name}
				</Option>
			))}
		</Select>
	);
}

export default SelectInterfaceTemplates;
