import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faPlugCirclePlus } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateAdapter } from "../../../redux/adapters/adapterSlice";
import { updateOpenDrawerAssignInterfaceAdapter } from "../../../redux/keys/keysSlice";
import { usePutAdapterMutation } from "../../../redux/adapters/adapterAPI";
// INTERFACES
import { RootState } from "../../../app/store";
import { AdapterInterface } from "../../../interfaces/Adapter.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import DrawerCreateInterfaceObject from "../../components/drawer/DrawerCreateInterfaceObject";
import ButtonClose from "../../components/buttons/ButtonClose";
import ButtonSave from "../../components/buttons/ButtonSave";
import GlobalAlert2 from "../../home/GlobalAlert2";
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import DrawerRowItem from "../../components/drawer/DrawerRowItem";
import TableColumnLiftType from "../../components/table/TableColumnLiftType";

function KeysAssignInterfaceAdapter() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const BLANK_ADAPTER: AdapterInterface = {
		created_at: "",
		updated_at: "",
		server_id: null,
		deleted_at: null,
		name: "",
		version: "",
		auth_credentials: {
			username: "",
			password: "",
		},
		socket_address: "",
		auth_type: "",
		type: "",
		protocol_type: "",
		lift_type: "",
		dictionary: {},
		interface: {},
		keys: [],
		total_bacnet_channels: 0,
		id: "",
		active: true,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [jsonInterface, setJsonInterface] = useState<any>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { adapter } = useSelector((state: RootState) => state.adapter);
	const { openDrawerAssignInterfaceAdapter } = useSelector((state: RootState) => state.key);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPutAdapter,
		{
			isLoading: isLoadingPutAdapter,
			isSuccess: isSuccessPutAdapter,
			isError: isErrorPutAdapter,
			error: errorPutAdapter,
			reset: resetPutAdapter,
		},
	] = usePutAdapterMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const activateLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const copy = structuredClone(adapter);
		copy.interface = jsonInterface;
		triggerPutAdapter({ id: adapter.id, token, body: copy });
	};

	const onClickClose = () => {
		dispatch(updateOpenDrawerAssignInterfaceAdapter(false));
		resetPutAdapter();
		dispatch(updateAdapter(BLANK_ADAPTER));
		setJsonInterface({});
	};

	const initValues = () => {
		if (
			adapter &&
			typeof adapter.interface === "object" &&
			adapter.interface !== null &&
			!Array.isArray(adapter.interface)
		) {
			setJsonInterface({});
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (openDrawerAssignInterfaceAdapter) {
			initValues();
		} else {
			onClickClose();
		}
	}, [openDrawerAssignInterfaceAdapter]);

	useEffect(() => {
		if (isErrorPutAdapter || isSuccessPutAdapter) {
			onClickClose();
		}
	}, [isErrorPutAdapter, isSuccessPutAdapter]);

	useEffect(() => {
		activateLoading();
	}, [jsonInterface]);

	useEffect(() => {
		if (
			adapter &&
			typeof adapter.interface === "object" &&
			adapter.interface !== null &&
			!Array.isArray(adapter.interface)
		) {
			setJsonInterface(adapter.interface);
		}
	}, [adapter]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='600'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerAssignInterfaceAdapter}
			footer={
				<div className='container generalStyles__flex mt-4'>
					<div className='generalStyles__width100 generalStyles__mrFix'>
						<ButtonClose onClick={onClickClose} />
					</div>
					<div className='generalStyles__width100 generalStyles__mlFix'>
						<ButtonSave onClick={onClickSave} disabledButton={false} isLoading={false} />
					</div>
				</div>
			}
		>
			<Spin spinning={isLoadingPutAdapter || isLoading}>
				{/** ------------------------------------------------------------- */}
				{/** COMPONENT DRAWER DIVIDER */}
				<DrawerRowDivider icon={faPlugCirclePlus} title={t("adapter.adapterInformation")} />
				{/** ------------------------------------------------------------- */}
				{/** ADAPTER INFORMATION */}
				<div className={`drawer__box__${theme}`}>
					{/* ID */}
					<DrawerRowItem title='ID' value={adapter && adapter.id ? adapter.id : "--"} />
					{/* NAME */}
					<DrawerRowItem title={t("general.name")} value={adapter && adapter.name ? adapter.name : "--"} />
					{/* VERSION */}
					<DrawerRowItem title={t("adapter.version")} value={adapter && adapter.version ? adapter.version : "--"} />
					{/* SOCKET ADDRESS */}
					<DrawerRowItem
						title={t("edgeManagement.channels.socketAddress")}
						value={adapter && adapter.socket_address ? adapter.socket_address : "--"}
					/>
					{/* LIFT TYPE */}
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title'>Lift {t("edgeManagement.lifts.type")}</p>
						</div>
						<div className='drawer__textContainer'>
							<div className='drawer__text'>
								{adapter && adapter.lift_type ? <TableColumnLiftType liftType={adapter.lift_type || ""} /> : "--"}
							</div>
						</div>
					</div>
				</div>
				<br />
				<DrawerCreateInterfaceObject
					jsonInterface={jsonInterface}
					setJsonInterface={setJsonInterface}
					displayJsonItem={false}
					displayJsonTemplate={false}
				/>
			</Spin>
			<GlobalAlert2
				isError={isErrorPutAdapter}
				isSuccess={isSuccessPutAdapter}
				requestType='PUT'
				error={errorPutAdapter}
				name='Adapter'
			/>
		</Drawer>
	);
}

export default KeysAssignInterfaceAdapter;
