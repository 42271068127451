import React, { useState, useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateFilters,
	updateBlockFilters,
} from "../../../redux/reports/reportsSlice";
import { usePostCarServiceLogMutation } from "../../../redux/reports/chartsAPI";
// COMPONENTS
import ReportsHeader from "../ReportsHeader";
import ChartBarTimeLineByDay from "../charts/ChartBarTimeLineByDay";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTotalCarsInBank, GenerateCarServiceServiceLogs } from "../utils";

const ReportCarServiceChartServiceLog = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [chartData, setChartData] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);
	const { filters, reportRefreshTable } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetChart, { data, isLoading }] = usePostCarServiceLogMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getChartData = async () => {
		const token = await GETJwtToken();
		triggerGetChart({
			token,
			body: {
				bank_id: filters.bankId,
				start_date: filters.startDate,
				end_date: filters.endDate,
				timezone: filters.timezoneCode,
				...(filters.liftType !== "ALL" && { lift_type: filters.liftType }),
			},
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (data && data.results) {
			const response = GenerateCarServiceServiceLogs(data.results);
			setChartData(response);
		} else {
			setChartData([{ value: 0, name: "", tag: "" }]);
		}
	}, [data]);

	useEffect(() => {
		if (
			filters.bankId !== "" &&
			filters.startDate !== "" &&
			filters.endDate !== "" &&
			filters.timezoneCode !== "" &&
			filters.liftType !== "" &&
			!isLoading
		) {
			getChartData();
		}
	}, [
		filters.bankId,
		filters.startDate,
		filters.endDate,
		filters.timezoneCode,
		filters.liftType,
	]);

	useEffect(() => {
		if (chartData && chartData.length > 0) {
			const response = getTotalCarsInBank(chartData);
			const copy: ReportSliceFiltersInterface = structuredClone(filters);
			copy.selectedCarsList = response;
			copy.carsList = response;
			dispatch(updateFilters(copy));
		}
	}, [chartData]);

	useEffect(() => {
		dispatch(updateBlockFilters(isLoading));
	}, [isLoading]);

	useEffect(() => {
		if (reportRefreshTable) {
			getChartData();
		}
	}, [reportRefreshTable]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission(
				"view-report-management-car-service-service-log-chart",
				permissions
			) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "CHART",
							levelTypeName: "BANKS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: [],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText2",
							displayDownloadCSVReport: false,
							displayDownloadPDFReport: true,
							reportTitle: "Car Service / Service Log",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: false,
							displayDateByRange: false,
							displayDate: true,
							displaySelectLiftType: true,
							displayAdvanceFilterCharts: false,
							displayAdvanceFiltersTable: false,
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
							dateTypeOptions: [
								{ id: "TODAY", name: "Day" },
								{ id: "WEEK", name: "Week" },
							],
							dateDaysLimit: 0,
							displayMinimumActivation: false,
							dateWeeksLimit: 0,
							dateMonthsLimit: 0,
							dateQuarterLimit: 0,
							dateYearsLimit: 0,
							dateType: "WEEK",
							bankSearchAndFilterString: "",
						}}
					/>
					<ChartBarTimeLineByDay
						data={chartData}
						isLoading={isLoading}
						bottomLabel='HOUR'
						leftLabel='CARS'
					/>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default ReportCarServiceChartServiceLog;
