/* eslint-disable no-use-before-define */
/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
// ANT DESIGN COMPONENTS
import { Table, Alert, Dropdown, Space, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faTrash,
	faPenToSquare,
	faBan,
	faBuildingCircleArrowRight,
	faCalendarDay,
	faStar,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import {
	updateOpenModalCreateNewOrganization,
	updateOpenDrawerOrganization,
	updateOrganization,
	updateOrganizationBreadcrumb,
	updateOpenDrawerHolidays,
	updateFormCreateEdit,
} from "../../../redux/organizations/organizationSlice";
import { updateGlobalAlert } from "../../../redux/home/homeSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";
import ModalDeleteItemOrganization from "../../components/modal/ModalDeleteItemOrganization";
import TableColumnOrganizationType from "../../components/table/TableColumnOrganizationType";
// INTERFACES
import {
	OrganizationInterface,
	OrganizationNavigateBreadcrumbInterface,
} from "../../../interfaces/Organizations.interface";
import { RootState } from "../../../app/store";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

const OrganizationTable = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;
	const dropdownRefs = useRef<(HTMLSpanElement | null)[]>([]);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [organizations, setOrganizations] = useState<OrganizationInterface[]>([]);
	const [organization, setOrganization] = useState<OrganizationInterface>({
		id: "",
		address: "",
		city: "",
		country: "",
		name: "",
		parent_id: "",
		phone_code: "",
		phone: "",
		state: "",
		type: "",
		servers: [],
	});
	const [idOrganizationSelected, setIdOrganizationSelected] = useState("");
	const [openModalDelete, setOpenModalDelete] = useState(false);
	const [total, setTotal] = useState<number>(0);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: RootState) => state.user);
	const { theme, userLogged, globalOrganizationId, globalOrganizationName } = useSelector(
		(state: RootState) => state.home
	);
	const { organizationRefreshTable, organizationBreadcrumb, organizationUpdateBreadcrumb, tourClickActions } =
		useSelector((state: RootState) => state.organization);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetOrganizationsTable, { data, isLoading, isError, isFetching }] =
		useLazyGetOrganizationAndChildsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenDrawerHolidays = () => {
		dispatch(updateOrganization(organization));
		dispatch(updateOpenDrawerHolidays(true));
	};

	const handleOpenModalCreateSubOrganization = () => {
		dispatch(updateOrganization(organization));
		dispatch(updateFormCreateEdit("ASSIGN"));
		dispatch(updateOpenModalCreateNewOrganization(true));
	};

	const handleOpenModalEdit = () => {
		dispatch(updateOrganization(organization));
		dispatch(updateFormCreateEdit("EDIT"));
		dispatch(updateOpenModalCreateNewOrganization(true));
	};

	const handleOpenModalDelete = () => {
		setOpenModalDelete(true);
	};

	const onCellClick = (record: OrganizationInterface) => {
		if (record) {
			dispatch(updateOrganization(record));
			dispatch(updateOpenDrawerOrganization(true));
		}
	};

	const navigateThrowOrganization = (record: OrganizationNavigateBreadcrumbInterface, updateBreadCrumb: boolean) => {
		refreshTable(record.id, updateBreadCrumb, record.name);
		setIdOrganizationSelected(record.id);
	};

	const refreshTable = async (orgId: string, updateBreadcrumb: boolean, orgName: string) => {
		const token = await GETJwtToken();
		const response = await triggerGetOrganizationsTable({
			token,
			orgId,
			searchAndFilterString: "",
		}).unwrap();
		if (updateBreadcrumb && response && response.data && response.data.children) {
			dispatch(updateOrganizationBreadcrumb([...organizationBreadcrumb, { orgId, name: orgName }]));
		}
		if (response && response.data && !response.data.children) {
			dispatch(
				updateGlobalAlert({
					title: orgName,
					description: t("general.noSubOrganizationsAssigned"),
					status: "info",
				})
			);
		}
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetOrganizationsTable({
			token,
			orgId: globalOrganizationId,
			searchAndFilterString: "",
		});
	};

	// ************************************************ */
	// TABLE ACTIONS ********************************** */
	const manageActions = (record: OrganizationInterface) => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faBan} />
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const CREATE_SUB_ORGANIZATION = validatePermission("orgs-create", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faBuildingCircleArrowRight}
							text={t("organizationManagement.createSubOrganization")}
							onClick={handleOpenModalCreateSubOrganization}
						/>
					),
					key: "1",
			  }
			: null;

		const HOLIDAYS = validatePermission("holidays-view", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faCalendarDay}
							text={t("general.holidays")}
							onClick={handleOpenDrawerHolidays}
						/>
					),
					key: "2",
			  }
			: null;

		const EDIT = validatePermission("orgs-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faPenToSquare}
							text={t("organizationManagement.edit")}
							onClick={handleOpenModalEdit}
						/>
					),
					key: "3",
			  }
			: null;

		const DELETE = validatePermission("orgs-delete", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faTrash}
							text={t("organizationManagement.delete")}
							onClick={handleOpenModalDelete}
						/>
					),
					key: "4",
			  }
			: null;

		// return object with valid actions
		if (organization) {
			if (EDIT || DELETE || CREATE_SUB_ORGANIZATION || HOLIDAYS) {
				if (record.id === userLogged.org_id) {
					return [CREATE_SUB_ORGANIZATION, HOLIDAYS];
				}
				return [CREATE_SUB_ORGANIZATION, HOLIDAYS, EDIT, DELETE];
			}
		}
		return [BLANK];
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		// clean breadcrumb
		dispatch(updateOrganizationBreadcrumb([]));
		if (globalOrganizationId !== "") {
			setIdOrganizationSelected(globalOrganizationId);
			getInitialData();
			dispatch(updateOrganizationBreadcrumb([{ orgId: globalOrganizationId, name: globalOrganizationName }]));
		}
	}, [globalOrganizationId]);

	useEffect(() => {
		if (data && data.data) {
			if (data.data.children) {
				setOrganizations([
					{
						id: data.data.id,
						address: data.data.address,
						city: data.data.city,
						country: data.data.country,
						name: data.data.name,
						phone_code: data.data.phone_code,
						phone: data.data.phone,
						state: data.data.state,
						type: data.data.type,
					},
					...data.data.children,
				]);
				// Save total
				if (data.data.children) {
					setTotal(data.data.children.length);
				}
			} else {
				setOrganizations([
					{
						id: data.data.id,
						address: data.data.address,
						city: data.data.city,
						country: data.data.country,
						name: data.data.name,
						phone_code: data.data.phone_code,
						phone: data.data.phone,
						state: data.data.state,
						type: data.data.type,
					},
				]);
			}
		}
	}, [data]);

	useEffect(() => {
		if (organizationRefreshTable) {
			refreshTable(idOrganizationSelected, false, "");
		}
	}, [organizationRefreshTable]);

	useEffect(() => {
		// update breadcrumb from organization breadcrumb
		// remove items from breadcrumb and load again
		if (organizationUpdateBreadcrumb && organizationUpdateBreadcrumb.update) {
			navigateThrowOrganization(organizationUpdateBreadcrumb, false);
		}
	}, [organizationUpdateBreadcrumb]);

	useEffect(() => {
		if (tourClickActions) {
			if (dropdownRefs.current[0]) {
				dispatch(updateOrganization(organizations[0]));
				dropdownRefs.current[0].click();
			}
		}
	}, [tourClickActions]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("orgs-view", permissions) ? (
				<>
					{!isError ? (
						<Table
							rowClassName={(record, index) => {
								if (record.id === userLogged.org_id) {
									return "table-row-active";
								}
								return getTableRowClass(index, theme);
							}}
							className='customTable'
							dataSource={organizations}
							loading={isLoading || isFetching}
							rowKey={(record) => record.id}
							size='small'
							pagination={{
								showSizeChanger: true,
								pageSizeOptions: ["10", "20", "50", "100"],
							}}
							footer={() => (
								<div className='generalStyles__flexEnd'>
									<Tag>{total} Rows</Tag>
								</div>
							)}
							scroll={{ x: 900 }}
						>
							{/** COLUMN - NAME */}
							<Column
								onCell={(record: OrganizationNavigateBreadcrumbInterface) => ({
									onClick: () => {
										navigateThrowOrganization(record, true);
									},
								})}
								render={(text, record: any) => (
									<div>
										{record.id === userLogged.org_id ? (
											<FontAwesomeIcon className='generalStyles__warning generalStyles__mrFix' icon={faStar} />
										) : (
											<span className='generalStyles__mrFix25' />
										)}
										<span>{text}</span>
									</div>
								)}
								title={t("organizationManagement.organizationName")}
								dataIndex='name'
								key='name'
								className='generalStyles__tableHeaderLink'
							/>
							{/** COLUMN - TYPE */}
							<Column
								onCell={(record: OrganizationInterface) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("organizationManagement.type")}
								dataIndex='type'
								key='type'
								render={(text) => <TableColumnOrganizationType type={text || ""} />}
							/>
							{/** COLUMN - ADDRESS */}
							<Column
								onCell={(record: OrganizationInterface) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("organizationManagement.address")}
								dataIndex='address'
								key='address'
								render={(text) => <div>{text}</div>}
							/>
							{/** COLUMN - ACTIONS */}
							<Column
								title={t("organizationManagement.actions")}
								key='action'
								className='tourOrganizationHolidaySteep2'
								render={(_: any, record: OrganizationInterface, index: number) => (
									<Dropdown
										menu={{
											items: manageActions(record),
										}}
										trigger={["click"]}
									>
										<span // ✅ Move ref to a `span` instead of `Space`
											ref={(el) => (dropdownRefs.current[index] = el)}
											className={`generalStyles__hoverCursor tourOrganizationHolidaySteep${index}`}
											onClick={() => setOrganization(record)}
											style={{ display: "inline-flex", cursor: "pointer" }} // Ensures it behaves like a button
										>
											<Space
												className='generalStyles__hoverCursor'
												onClick={() => {
													setOrganization(record);
												}}
												// eslint-disable-next-line no-return-assign
											>
												<FontAwesomeIcon icon={faEllipsis} />
											</Space>
										</span>
									</Dropdown>
								)}
							/>
						</Table>
					) : (
						<Alert
							message={t("organizationManagement.errorDescription.error")}
							description={t("organizationManagement.errorDescription.text1")}
							type='error'
							showIcon
							className='mt-3'
						/>
					)}
				</>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty
							description={t("organizationManagement.listNotAvailableOrganizations")}
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					</div>
				</>
			)}
			{/** DELETE ITEM COMPONENT */}
			<ModalDeleteItemOrganization
				nameComponent='organization'
				nameItem={organization.name}
				id={organization.id}
				onClickClose={() => {
					setOpenModalDelete(false);
				}}
				openModalDelete={openModalDelete}
			/>
		</div>
	);
};

export default OrganizationTable;
