import React, { useEffect, useState } from "react";
import { Button, Upload, Drawer, Spin, Divider } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useUploadServerFileMutation } from "../../../redux/servers/serverAPI";
import { updateOpenModalImportServer } from "../../../redux/servers/serverSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

function ServerImportFile() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [file, setFile] = useState<File | null>(null);
	const [fileList, setFileList] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalImportServer } = useSelector((state: any) => state.server);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [uploadServerFile, { isSuccess, isLoading: isLoadingApi, isError, error }] =
    useUploadServerFileMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const resetFileInput = () => {
		setFile(null);
		setFileList([]);
	};

	const showIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const initData = () => {
		showIsLoading();
	};

	const onClickClose = () => {
		dispatch(updateOpenModalImportServer(false));
		resetFileInput();
	};

	const handleUpload = async () => {
		if (!file) {
			return;
		}

		try {
			const token = await GETJwtToken();
			const formData = new FormData();
			formData.append("file", file);

			await uploadServerFile({ formData, token }).unwrap();
			onClickClose();
		} catch (err) {
			resetFileInput();
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************** */
	useEffect(() => {
				initData();
				if (openModalImportServer) {
					resetFileInput();
				}
	}, [openModalImportServer]);

	useEffect(() => {
		onClickClose();
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='550' placement='right' onClose={onClickClose} closable={false} open={openModalImportServer}>
			<Spin spinning={isLoading || isLoadingApi} tip={t("general.loading")}>
				{/** ---------------------------------------------------- */}
				{/** IMPORT SERVER FILE  */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faDownload} className='generalStyles__info generalStyles__mrFix' />
							{t("edgeManagement.servers.importData")}
						</h5>
					</Divider>
				</div>

				<div className={`drawer__box__${theme} row`}>
					{/** UPLOAD FILE  */}
					<div className='col-md-12'>
						<Upload
							beforeUpload={(fileB) => {
								setFile(fileB);
								setFileList([fileB]);
								return false;
							}}
							fileList={fileList}
							accept=".dat"
							maxCount={1}
						>
							<Button icon={<UploadOutlined />}>{t("edgeManagement.servers.selectFile")}</Button>
						</Upload>
					</div>
				</div>

				{/** ---------------------------------------------------- */}
				<div className='mt-4'>
					<Button type='default' onClick={onClickClose} className='buttonStyle__3'>
						{t("edgeManagement.close")}
					</Button>
					<Button onClick={handleUpload} disabled={!file || isLoadingApi} type='primary' className='buttonStyle__3'>
						{t("general.uploadFile")}
					</Button>
				</div>
			</Spin>

			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2 isError={isError} isSuccess={isSuccess} requestType='POST' error={error} name='Server' />
		</Drawer>
	);
}

export default ServerImportFile;
