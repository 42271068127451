import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const serverAPI = createApi({
	reducerPath: "serverAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: [
		"tagGetServers",
		"tagPostServer",
		"tagPutServer",
		"tagDeleteServer",
		"tagGetSecretClientToken",
	],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ALL SERVERS */
		getServers: builder.query({
			query: (body: any) => ({
				url: `/servers/?&page=${body.page}&limit=${body.limit}${
					body.searchAndFilterString || ""
				}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetServers"],
		}),
		//* *********************************************************** */
		//* GET SERVERS BY ORGANIZATION */
		getServersByOrganization: builder.query({
			query: (body: any) => ({
				url: `/servers/?filter_target=org_id&filter=${body.orgId}&limit=9999`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetServers"],
		}),
		//* *********************************************************** */
		//* POST SECRET CLIENT TOKEN */
		postSecretClientToken: builder.mutation({
			query: (body: any) => ({
				url: "/servers/oauth",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* CREATE SERVER */
		postServer: builder.mutation({
			query: (body: any) => ({
				url: "/servers/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetServers"],
		}),
		//* *********************************************************** */
		//* EDIT SERVER */
		putServer: builder.mutation({
			query: (body: any) => ({
				url: `/servers/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetServers"],
		}),
		//* *********************************************************** */
		//* DELETE SERVER */
		deleteServer: builder.mutation({
			query: (body: any) => ({
				url: `/servers/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetServers"],
		}),

		//* *********************************************************** */
		//* EXPORT SERVER INFO*/
		getExportServerInfo: builder.query({
			query: (body: any) => ({
				url: `/servers/export/${body.serverId}`,
				headers: { authorization: body.token },
				responseHandler: async (response) => {
					if (!response.ok) throw new Error("Error retrieving the file.");
					const blob = await response.blob();
					const contentDisposition = response.headers.get("Content-Disposition") || "";
					return { blob, contentDisposition };
				},
			}),
			providesTags: ["tagGetServers"],
		}),
		uploadServerFile: builder.mutation({
			query: ({ formData, token }) => ({
				url: `/servers/import`,
				method: "POST",
				headers: { authorization: token },
				body: formData,
			}),
			invalidatesTags: ["tagGetServers"],
		}),
	}),
});

export const {
	useLazyGetServersQuery,
	useLazyGetServersByOrganizationQuery,
	usePostServerMutation,
	usePostSecretClientTokenMutation,
	usePutServerMutation,
	useDeleteServerMutation,
	useLazyGetExportServerInfoQuery,
	useUploadServerFileMutation,
} = serverAPI;
