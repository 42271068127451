import React from "react";
// ANT DESIGN COMPONENTS
import { Button, Tooltip, Upload, message } from "antd";
// XLSX
import * as XLSX from "xlsx";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
// INTERFACES
import { ButtonUploadCSVInterface } from "../../../interfaces/Components.interface";
import { RootState } from "../../../app/store";

function ButtonSquareUploadCSV({ setUploadCSVData, disabledButton, isLoading }: ButtonUploadCSVInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleUpload = (file: File) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			try {
				const binaryStr = e.target?.result;
				const workbook = XLSX.read(binaryStr, { type: "binary" });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(sheet);
				message.success("File uploaded and data processed successfully!");
				//
				// eslint-disable-next-line no-console
				setUploadCSVData(jsonData);
				//
			} catch (error) {
				message.error("Error reading the Excel file. Please try again.");
			}
		};
		reader.readAsBinaryString(file); // Read the file as binary
		return false; // Prevent default upload behavior
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Upload disabled={disabledButton} accept='.xlsx, .xls' beforeUpload={handleUpload} showUploadList={false}>
				<Tooltip title={t("general.uploadFile")}>
					<Button
						loading={isLoading}
						disabled={disabledButton}
						className={`${
							disabledButton ? `buttonStyle__inactive__1__${theme}` : "buttonStyle__20"
						} generalStyles__mlFix`}
						style={{ marginTop: "-5px" }}
						icon={<FontAwesomeIcon className='' icon={faUpload} />}
					/>
				</Tooltip>
			</Upload>
		</div>
	);
}

export default ButtonSquareUploadCSV;
