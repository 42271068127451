import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Divider, Drawer } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalCreateEditUser, updateUser } from "../../../redux/users/userSlice";
import { usePostUserMutation, usePutUserMutation } from "../../../redux/users/userAPI";
import { updateOrganizationAndChilds } from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationSelectOrganizationSubOrganization from "../../organizationManagement/organizations/OrganizationSelectOrganizationSubOrganization";
import FormInputText from "../../components/form/FormInputText";
import FormInputPhoneNumber from "../../components/form/FormInputPhoneNumber";
import FormInputSelectRole from "../../components/form/FormInputSelectRole";
import GlobalAlert2 from "../../home/GlobalAlert2";
import ButtonClose from "../../components/buttons/ButtonClose";
import ButtonSave from "../../components/buttons/ButtonSave";
// INTERFACES
import { UserInterface } from "../../../interfaces/User.interface";
import { RootState } from "../../../app/store";

function UsersCreateEditForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const BLANK_USER = {
		first_name: "",
		middle_name: "",
		last_name: "",
		role_id: "",
		org_id: "",
		email: "",
		contact_info: [
			{
				id: "",
				address: "",
				phone: "1",
				phone_code: "",
				priority: 0,
			},
		],
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [user, setUser] = useState<UserInterface>(BLANK_USER);
	const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { userLogged } = useSelector((state: RootState) => state.home);
	const { openModalCreateEditUser, formCreateEdit, user: selectedUser } = useSelector((state: RootState) => state.user);
	const { organizationAndChilds } = useSelector((state: RootState) => state.organization);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPostUser,
		{
			isSuccess: isSuccessPostUser,
			isLoading: isLoadingPostUser,
			isError: isErrorPostUser,
			error: errorPostUser,
			reset: resetPostUser,
		},
	] = usePostUserMutation();
	const [
		triggerPutUser,
		{
			isSuccess: isSuccessPutUser,
			isError: isErrorPutUser,
			error: errorPutUser,
			isLoading: isLoadingPutUser,
			reset: resetPutUser,
		},
	] = usePutUserMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const cleanOrganizationsList = () => {
		dispatch(
			updateOrganizationAndChilds({
				childs: [],
			})
		);
	};

	const initData = () => {
		if (formCreateEdit === "create") {
			cleanOrganizationsList();
			const copy: UserInterface = structuredClone(user);
			copy.org_id = userLogged.org.id;
			setUser(copy);
		}
	};

	const onChangeFormValue = (newValue: string, variableName: string) => {
		const copy: UserInterface = structuredClone(user);
		copy[variableName] = newValue;
		setUser(copy);
	};

	const onChangeFormValueContactInfo = (newValue: string, variableName: string) => {
		const copy: UserInterface = structuredClone(user);
		copy.contact_info[0][variableName] = newValue;
		setUser(copy);
	};

	const onClickClose = () => {
		resetPostUser();
		resetPutUser();
		cleanOrganizationsList();
		setUser(BLANK_USER);
		dispatch(updateOpenModalCreateEditUser(false));
		dispatch(updateUser(BLANK_USER));
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (formCreateEdit === "create") {
			triggerPostUser({ token, body: user });
		} else {
			triggerPutUser({
				id: selectedUser.id,
				body: user,
				token,
			});
		}
	};

	const validateEmail = (email: string) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const checkFormIsValid = () => {
		let formIsValid = true;
		if (
			user.first_name === "" ||
			user.last_name === "" ||
			user.contact_info[0].address === "" ||
			user.contact_info[0].phone === "" ||
			user.role_id === "" ||
			user.org_id === "" ||
			!validateEmail(user.email)
		) {
			formIsValid = false;
		}
		return formIsValid;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalCreateEditUser) {
			initData();
		}
	}, [openModalCreateEditUser]);

	useEffect(() => {
		onClickClose();
		cleanOrganizationsList();
	}, [isSuccessPostUser, isSuccessPutUser, isErrorPostUser, isErrorPutUser]);

	useEffect(() => {
		if (organizationAndChilds && organizationAndChilds.data && organizationAndChilds.data.idOrganizationSelected) {
			const copy: UserInterface = structuredClone(user);
			copy.org_id = organizationAndChilds.data.idOrganizationSelected;
			setUser(copy);
		}
	}, [organizationAndChilds]);

	useEffect(() => {
		if (formCreateEdit === "edit" && selectedUser && selectedUser.contact_info) {
			setUser({
				first_name: selectedUser.first_name,
				middle_name: selectedUser.middle_name,
				last_name: selectedUser.last_name,
				role_id: selectedUser.role_id,
				org_id: selectedUser.org_id,
				email: selectedUser.email,
				contact_info: [
					{
						id: selectedUser.contact_info[0].id,
						address: selectedUser.contact_info[0].address,
						phone: selectedUser.contact_info[0].phone,
						phone_code: selectedUser.contact_info[0].phone_code,
						priority: selectedUser.contact_info[0].priority,
					},
				],
			});
		}
	}, [selectedUser]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='600' placement='right' onClose={onClickClose} closable={false} open={openModalCreateEditUser}>
			<Spin
				spinning={isLoadingPostUser || organizationsAndChildsIsLoading || isLoadingPutUser}
				tip={t("general.loading")}
			>
				<div>
					{/** ------------------------------------------------------------- */}
					{/** CREATE NEW USER  */}
					<div>
						<Divider orientation='left' className='generalStyles__drawerDivider'>
							<h5>
								<FontAwesomeIcon icon={faUser} className='generalStyles__info generalStyles__mrFix' />
								{formCreateEdit === "create" ? (
									<> {t("userManagement.createNewUser")}</>
								) : (
									<> {t("userManagement.editUser")}</>
								)}
							</h5>
						</Divider>
					</div>
				</div>
				{/** ------------------------------------------------------------- */}
				{/** USER FORM  */}
				<div className='container'>
					<div className={`drawer__box__${theme} row`}>
						{/** COMPONENTS FORM - INPUT EMAIL */}
						<FormInputText
							inputValue={user.email}
							inputLabelName='Email'
							inputOnChange={onChangeFormValue}
							inputVariableName='email'
							isRequired
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - INPUT FIRST NAME */}
						<FormInputText
							inputValue={user.first_name}
							inputLabelName={t("userManagement.firstName")}
							inputOnChange={onChangeFormValue}
							inputVariableName='first_name'
							isRequired
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - INPUT MIDDLE NAME */}
						<FormInputText
							inputValue={user.middle_name}
							inputLabelName={t("userManagement.middleName")}
							inputOnChange={onChangeFormValue}
							inputVariableName='middle_name'
							isRequired={false}
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - INPUT LAST NAME */}
						<FormInputText
							inputValue={user.last_name}
							inputLabelName={t("userManagement.lastName")}
							inputOnChange={onChangeFormValue}
							inputVariableName='last_name'
							isRequired
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - INPUT ADDRESS */}
						<FormInputText
							inputValue={user.contact_info[0].address}
							inputLabelName={t("userManagement.address")}
							inputOnChange={onChangeFormValueContactInfo}
							inputVariableName='address'
							isRequired
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - INPUT PHONE NUMBER */}
						<FormInputPhoneNumber
							inputValue={user.contact_info[0].phone}
							inputLabelName={t("userManagement.phone")}
							inputOnChange={onChangeFormValueContactInfo}
							inputVariableName='phone'
						/>
					</div>
				</div>
				{/** ---------------------------------------------------- */}
				{/** GET ORGANIZATION AND CHILDS ----------- */}
				{formCreateEdit === "create" && (
					<OrganizationSelectOrganizationSubOrganization
						setOrganizationsAndChildsIsLoading={setOrganizationsAndChildsIsLoading}
					/>
				)}
				{/** ---------------------------------------------------- */}
				{/** ROLE */}
				<FormInputSelectRole
					orgId={user.org_id}
					inputValue={user.role_id}
					inputOnChange={onChangeFormValue}
					inputVariableName='role_id'
				/>
				{/** CLOSE AND SAVE BUTTON */}
				<div className='container generalStyles__flex mt-4'>
					<div className='generalStyles__width100 generalStyles__mrFix'>
						<ButtonClose onClick={onClickClose} />
					</div>
					<div className='generalStyles__width100 generalStyles__mlFix'>
						<ButtonSave onClick={onClickSave} disabledButton={!checkFormIsValid()} isLoading={false} />
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostUser}
				isSuccess={isSuccessPostUser}
				requestType='POST'
				error={errorPostUser}
				name='User'
			/>
			<GlobalAlert2
				isError={isErrorPutUser}
				isSuccess={isSuccessPutUser}
				requestType='PUT'
				error={errorPutUser}
				name='User'
			/>
		</Drawer>
	);
}

export default UsersCreateEditForm;
