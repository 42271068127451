import React from "react";
// ANT DESIGN COMPONENT
import { Tag } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "../components/drawer/DrawerRowDivider";

function LiftnetInformation() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<br />
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faCodeBranch} title={t("general.version")} />
			{/** ************************************************* */}
			<div className={`drawer__box__${theme}`}>
				{/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
				<div className='generalStyles__flex'>
					<div>
						<p className='drawer__title'>Version:</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							<Tag color='blue'>1.0.5</Tag>
						</p>
					</div>
				</div>
				{/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
				<div className='generalStyles__flex'>
					<div>
						<p className='drawer__title'>{t("general.date")}:</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>Monday - March 31. 2025</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LiftnetInformation;
