import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { usePostFaultLogReportMutation } from "../../../redux/reports/reportsAPI";
// COMPONENTS
import ReportsTable from "../ReportsTable";
import ReportsHeader from "../ReportsHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const ReportFaultLog = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [loadPage, setLoadPage] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortBy, setSortBy] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isError, error }] =
		usePostFaultLogReportMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const generateTableRequestBody = ({ downloadReport }: any) => {
		const body: any = {
			bank_id: filters.bankId,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			download: downloadReport,
			page: currentPage,
            items_per_page: pageSize,
			sort_by: sortBy,
            sort_direction: sortDirection,
		};
		// Include lift type if value is not ALL
		if (filters.liftType !== "ALL") {
			body.lift_type = filters.liftType;
		}
		return body;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLoadPage(true);
		setTimeout(() => {
			setLoadPage(false);
		}, 1000);
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={loadPage}>
			{validatePermission(
				"view-report-management-faults-log-by-bank",
				permissions
			) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "REPORT",
							levelTypeName: "BANKS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: [
								"lift_name",
								"date",
								"cleared",
								"floor",
								"fault",
								"direction",
							],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText2",
							displayDownloadCSVReport: true,
							displayDownloadPDFReport: true,
							reportTitle: "Faults / Faults Log by Bank",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: true,
							displayDateByRange: true,
							displayDate: true,
							displaySelectLiftType: true,
							displayAdvanceFilterCharts: false,
							displayAdvanceFiltersTable: false,
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
							dateTypeOptions: [
								{ id: "TODAY", name: "Day" },
								{ id: "WEEK", name: "Week" },
								{ id: "MONTH", name: "Month" },
								{ id: "QUARTER", name: "Quarter" },
								{ id: "YEAR", name: "Year" },
							],
							dateDaysLimit: 0,
							displayMinimumActivation: false,
							dateWeeksLimit: 0,
							dateMonthsLimit: 0,
							dateQuarterLimit: 0,
							dateYearsLimit: 0,
							dateType: "MONTH",
							bankSearchAndFilterString: "&filter_target=type&filter=ELEVATOR",
						}}
					/>
					<ReportsTable
						response={data}
						generateTableRequestBody={generateTableRequestBody}
						csvUrl={`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/faults/log`}
						displayExpandibleRowTable={false}
						groupColumns={null}
						triggerGetReport={triggerGetReport}
						isLoading={isLoading}
						isError={isError}
						error={error}
						updatePageSize={setPageSize}
                        updateCurrentPage={setCurrentPage}
						sortBy={setSortBy}
                        sortDirection={setSortDirection}
					/>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</Spin>
	);
};

export default ReportFaultLog;
