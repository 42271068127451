import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Spin } from "antd";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteMessageMutation } from "../../../redux/messages/messagesAPI";
import { updateOpenModalDelete, updateMessage, updateFormAction } from "../../../redux/messages/messagesSlice";
// COMPONENTS
import ModalDeleteItemContainer from "../../components/modal/ModalDeleteItemContainer";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";

function MessageDeleteModal() {
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const BLANK_MESSAGE = {
		created_at: "",
		updated_at: "",
		deleted_at: null,
		short_message: "NORMAL",
		description: "",
		keys: [
			{
				created_at: "",
				updated_at: "",
				adapter: null,
				message: null,
				deleted_at: null,
				value: { bit: "1", byte: "1" },
				id: "",
				message_id: "",
				adapter_id: "",
			},
		],
		message_type: {
			created_at: "",
			updated_at: "",
			deleted_at: null,
			name: "CARSERVICE",
			id: 2,
		},
		message_type_id: 2,
		enum_id: 1,
		id: "",
		position: true,
	};
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { message, openModalDelete } = useSelector((state: RootState) => state.message);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerDeleteMessage, { isLoading, isSuccess, isError, error, reset: resetDeleteMessage }] =
		useDeleteMessageMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenModalDelete(false));
		dispatch(updateFormAction("CREATE"));
		dispatch(updateMessage(BLANK_MESSAGE));
	};

	const onClickDelete = async () => {
		const token = await GETJwtToken();
		triggerDeleteMessage({ id: message.id, token });
		dispatch(updateMessage(BLANK_MESSAGE));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalDelete) {
			resetDeleteMessage();
		}
	}, [openModalDelete]);

	useEffect(() => {
		if (isSuccess || isError) {
			dispatch(updateOpenModalDelete(false));
		}
	}, [isSuccess, isError]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal onOk={onClickClose} closable={false} onCancel={onClickClose} open={openModalDelete} footer={null}>
			<Spin spinning={isLoading}>
				<ModalDeleteItemContainer
					name={message.short_message}
					onClickDelete={onClickDelete}
					onClickClose={onClickClose}
				/>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2 isError={isError} isSuccess={isSuccess} requestType='DELETE' error={error} name='Message' />
		</Modal>
	);
}

export default MessageDeleteModal;
