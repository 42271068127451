/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { KeyInterface } from "../../interfaces/Key.interface";

export interface KeyState {
	key: KeyInterface;
	openDrawerKey: boolean;
	openModalCreateNewKey: boolean;
	keyRefreshTable: boolean;
	openModalDeleteKey: boolean;
	openImportKeys: boolean;
	showTable: boolean;
	formCreateEdit: string;
	openDrawerAssignActions: boolean;
	openDrawerAssignInterfaceAdapter: boolean;
	openDrawerAssignInterfaceBank: boolean;
}

const initialState: KeyState = {
	key: {
		id: "",
		adapter_id: "",
		message_id: "",
		value: "",
		identifier: {},
	},
	openDrawerKey: false,
	openModalCreateNewKey: false,
	keyRefreshTable: false,
	openModalDeleteKey: false,
	openImportKeys: false,
	formCreateEdit: "create",
	openDrawerAssignActions: false,
	showTable: false,
	openDrawerAssignInterfaceAdapter: false,
	openDrawerAssignInterfaceBank: false,
};

export const keySlice = createSlice({
	name: "keyType",
	initialState,
	reducers: {
		updateKey: (state, action: PayloadAction<KeyInterface>) => {
			state.key = action.payload;
		},
		updateOpenDrawerKey: (state, action: PayloadAction<boolean>) => {
			state.openDrawerKey = action.payload;
		},
		updateOpenModalCreateNewKey: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateNewKey = action.payload;
		},
		updateKeyRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.keyRefreshTable = action.payload;
		},
		updateOpenModalDeleteKey: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteKey = action.payload;
		},
		updateOpenImportKeys: (state, action: PayloadAction<boolean>) => {
			state.openImportKeys = action.payload;
		},
		updateFormCreateEdit: (state, action: PayloadAction<string>) => {
			state.formCreateEdit = action.payload;
		},
		updateShowTable: (state, action: PayloadAction<boolean>) => {
			state.showTable = action.payload;
		},
		updateOpenDrawerAssignInterfaceAdapter: (state, action: PayloadAction<boolean>) => {
			state.openDrawerAssignInterfaceAdapter = action.payload;
		},
		updateOpenDrawerAssignInterfaceBank: (state, action: PayloadAction<boolean>) => {
			state.openDrawerAssignInterfaceBank = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateKey,
	updateOpenDrawerKey,
	updateOpenModalCreateNewKey,
	updateKeyRefreshTable,
	updateOpenModalDeleteKey,
	updateOpenImportKeys,
	updateFormCreateEdit,
	updateShowTable,
	updateOpenDrawerAssignInterfaceAdapter,
	updateOpenDrawerAssignInterfaceBank,
} = keySlice.actions;

export default keySlice.reducer;
