/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACE
import {
	OrganizationInterface,
	OrganizationNavigateBreadcrumbInterface,
} from "../../interfaces/Organizations.interface";

interface OrganizationState {
	organization: OrganizationInterface;
	openModalDeleteOrganization: boolean;
	formCreateEdit: string;
	openModalCreateNewOrganization: boolean;
	openModalCreateSubOrganization: boolean;
	openModalEditOrganization: boolean;
	openDrawerOrganization: boolean;
	openDrawerHolidays: boolean;
	organizationRefreshTable: boolean;
	organizationList: Array<any>;
	organizationAndChilds: {
		data: {
			idOrganizationSelected: string;
		};
		childs: [];
	};
	organizationBreadcrumb: Array<any>;
	organizationUpdateBreadcrumb: OrganizationNavigateBreadcrumbInterface;
	organizationMonitoringViewData: Object;
	organizationSelectMultipleData: Array<any>;
	clearFilters: boolean;
	tourClickActions: boolean;
}

const initialState: OrganizationState = {
	organization: {
		id: "",
		address: "",
		city: "",
		country: "",
		name: "",
		parent_id: "",
		phone_code: "",
		phone: "",
		state: "",
		type: "",
	},
	openModalDeleteOrganization: false,
	formCreateEdit: "CREATE",
	openModalCreateNewOrganization: false,
	openModalCreateSubOrganization: false,
	openModalEditOrganization: false,
	openDrawerOrganization: false,
	openDrawerHolidays: false,
	organizationRefreshTable: false,
	organizationList: [],
	organizationAndChilds: {
		data: {
			idOrganizationSelected: "",
		},
		childs: [],
	},
	organizationBreadcrumb: [],
	organizationUpdateBreadcrumb: {
		update: false,
		id: "",
		name: "",
	},
	organizationMonitoringViewData: [],
	organizationSelectMultipleData: [],
	clearFilters: false,
	tourClickActions: false,
};

export const organizationSlice = createSlice({
	name: "organization",
	initialState,
	reducers: {
		updateOrganization: (state, action: PayloadAction<OrganizationInterface>) => {
			state.organization = action.payload;
		},
		updateFormCreateEdit: (state, action: PayloadAction<string>) => {
			state.formCreateEdit = action.payload;
		},
		updateOpenModalCreateNewOrganization: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateNewOrganization = action.payload;
		},
		updateOpenModalCreateSubOrganization: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateSubOrganization = action.payload;
		},
		updateOpenModalDeleteOrganization: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteOrganization = action.payload;
		},
		updateOpenModalEditOrganization: (state, action: PayloadAction<boolean>) => {
			state.openModalEditOrganization = action.payload;
		},
		updateOpenDrawerOrganization: (state, action: PayloadAction<boolean>) => {
			state.openDrawerOrganization = action.payload;
		},
		updateOpenDrawerHolidays: (state, action: PayloadAction<boolean>) => {
			state.openDrawerHolidays = action.payload;
		},
		updateOrganizationRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.organizationRefreshTable = action.payload;
		},
		updateOrganizationList: (state, action: PayloadAction<any>) => {
			state.organizationList = action.payload;
		},
		updateOrganizationAndChilds: (state, action: PayloadAction<any>) => {
			state.organizationAndChilds = action.payload;
		},
		updateOrganizationBreadcrumb: (state, action: PayloadAction<any>) => {
			state.organizationBreadcrumb = action.payload;
		},
		updateOrganizationUpdateBreadcrumb: (state, action: PayloadAction<OrganizationNavigateBreadcrumbInterface>) => {
			state.organizationUpdateBreadcrumb = action.payload;
		},
		updateOrganizationMonitoringViewData: (state, action: PayloadAction<Object>) => {
			state.organizationMonitoringViewData = action.payload;
		},
		updateOrganizationSelectMultipleData: (state, action: PayloadAction<any>) => {
			state.organizationSelectMultipleData = action.payload;
		},
		updateClearFilters: (state, action: PayloadAction<boolean>) => {
			state.clearFilters = action.payload;
		},
		updateTourClickActions: (state, action: PayloadAction<boolean>) => {
			state.tourClickActions = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateOrganization,
	updateOpenModalCreateNewOrganization,
	updateOpenModalDeleteOrganization,
	updateFormCreateEdit,
	updateOpenModalCreateSubOrganization,
	updateOpenModalEditOrganization,
	updateOpenDrawerOrganization,
	updateOpenDrawerHolidays,
	updateOrganizationRefreshTable,
	updateOrganizationList,
	updateOrganizationAndChilds,
	updateOrganizationBreadcrumb,
	updateOrganizationUpdateBreadcrumb,
	updateOrganizationMonitoringViewData,
	updateOrganizationSelectMultipleData,
	updateClearFilters,
	updateTourClickActions,
} = organizationSlice.actions;

export default organizationSlice.reducer;
