import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faPlugCirclePlus } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateBank } from "../../../redux/banks/bankSlice";
import { updateOpenDrawerAssignInterfaceBank } from "../../../redux/keys/keysSlice";
import { usePutBankMutation } from "../../../redux/banks/bankAPI";
// INTERFACES
import { RootState } from "../../../app/store";
import { BankInterface } from "../../../interfaces/Bank.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import DrawerCreateInterfaceObject from "../../components/drawer/DrawerCreateInterfaceObject";
import ButtonClose from "../../components/buttons/ButtonClose";
import ButtonSave from "../../components/buttons/ButtonSave";
import GlobalAlert2 from "../../home/GlobalAlert2";
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import DrawerRowItem from "../../components/drawer/DrawerRowItem";
import TableColumnLiftType from "../../components/table/TableColumnLiftType";

function KeysAssignInterfaceBank() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const BLANK_BANK: BankInterface = {
		id: "",
		name: "",
		type: "ELEVATOR",
		channel_id: "",
		server_id: "",
		columns: 0,
		stops: 0,
		number_of_lifts: 0,
		logical_port: 0,
		floor_count: 0,
		floor_below_lobby: 0,
		board: 1,
		rear_doors: false,
		active: true,
		control_panel: "",
		lifts: [],
		offsets: [],
		floor_names: [],
		direct_link: "",
		configurations: [],
		data_blocks: [],
		start_floor_no: 1,
		interface: {},
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [jsonInterface, setJsonInterface] = useState<any>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { bank } = useSelector((state: RootState) => state.bank);
	const { openDrawerAssignInterfaceBank } = useSelector((state: RootState) => state.key);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPutBank,
		{
			isLoading: isLoadingPutBank,
			isSuccess: isSuccessPutBank,
			isError: isErrorPutBank,
			error: errorPutBank,
			reset: resetPutBank,
		},
	] = usePutBankMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const activateLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const copy = structuredClone(bank);
		delete copy.lifts;
		delete copy.bank_floors;
		copy.interface = jsonInterface;
		triggerPutBank({ id: bank.id, token, body: copy });
	};

	const onClickClose = () => {
		dispatch(updateOpenDrawerAssignInterfaceBank(false));
		resetPutBank();
		dispatch(updateBank(BLANK_BANK));
		setJsonInterface({});
	};

	const initValues = () => {
		if (bank && typeof bank.interface === "object" && bank.interface !== null && !Array.isArray(bank.interface)) {
			setJsonInterface({});
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (openDrawerAssignInterfaceBank) {
			initValues();
		} else {
			onClickClose();
		}
	}, [openDrawerAssignInterfaceBank]);

	useEffect(() => {
		if (isErrorPutBank || isSuccessPutBank) {
			onClickClose();
		}
	}, [isErrorPutBank, isSuccessPutBank]);

	useEffect(() => {
		activateLoading();
	}, [jsonInterface]);

	useEffect(() => {
		if (bank && typeof bank.interface === "object" && bank.interface !== null && !Array.isArray(bank.interface)) {
			setJsonInterface(bank.interface);
		}
	}, [bank]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='600'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerAssignInterfaceBank}
			footer={
				<div className='container generalStyles__flex mt-4'>
					<div className='generalStyles__width100 generalStyles__mrFix'>
						<ButtonClose onClick={onClickClose} />
					</div>
					<div className='generalStyles__width100 generalStyles__mlFix'>
						<ButtonSave onClick={onClickSave} disabledButton={false} isLoading={false} />
					</div>
				</div>
			}
		>
			<Spin spinning={isLoadingPutBank || isLoading}>
				{/** ------------------------------------------------------------- */}
				{/** COMPONENT DRAWER DIVIDER */}
				<DrawerRowDivider icon={faPlugCirclePlus} title={t("edgeManagement.banks.bankInformation")} />
				{/** ------------------------------------------------------------- */}
				{/** BANK INFORMATION */}
				<div className={`drawer__box__${theme}`}>
					{/* ID */}
					<DrawerRowItem title='ID' value={bank.id} />
					{/* NAME */}
					<DrawerRowItem title={t("general.name")} value={bank.name} />
					{/* LIFT TYPE */}
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title'>Lift {t("edgeManagement.lifts.type")}</p>
						</div>
						<div className='drawer__textContainer'>
							<div className='drawer__text'>
								{bank && bank.type ? <TableColumnLiftType liftType={bank.type || ""} /> : "--"}
							</div>
						</div>
					</div>
				</div>
				<br />
				<DrawerCreateInterfaceObject
					jsonInterface={jsonInterface}
					setJsonInterface={setJsonInterface}
					displayJsonItem
					displayJsonTemplate
				/>
			</Spin>
			<GlobalAlert2
				isError={isErrorPutBank}
				isSuccess={isSuccessPutBank}
				requestType='PUT'
				error={errorPutBank}
				name='Bank'
			/>
		</Drawer>
	);
}

export default KeysAssignInterfaceBank;
