import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useLazyGetMessagesTypeQuery } from "../../../redux/messageType/messagesTypeAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import { SelectMessagesInterface } from "../../../interfaces/Components.interface";
import { MessageInterface } from "../../../interfaces/Message.interface";

function SelectMessageType({ setFilteredValue }: SelectMessagesInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const [messageTypes, setMessageTypes] = useState<MessageInterface[]>([]);
	const [messageTypeSelected, setMessageTypeSelected] = useState<string>("ALL");

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetMessageTypes, { data, isLoading, isFetching, isError, error }] = useLazyGetMessagesTypeQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetMessageTypes({
			token,
		});
	};

	const onChange = (newValue: string) => {
		const index = messageTypes.findIndex((item: any) => item.id === newValue);
		setMessageTypeSelected(newValue);
		setFilteredValue(messageTypes[index].name);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (data && data.data) {
			setMessageTypes([
				{
					created_at: "",
					updated_at: "",
					deleted_at: null,
					name: "ALL",
					messages: [],
					id: 0,
				},
				...data.data,
			]);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className=' generalStyles__width100'>
			<Select
				loading={isLoading || isFetching}
				className='generalStyles__width250px'
				size='middle'
				value={messageTypeSelected}
				placeholder={t("general.messageType")}
				onChange={onChange}
				showSearch
				optionFilterProp='label' // Ensures Ant Design uses `label` for searching
				filterOption={(input, option) =>
					typeof option?.label === "string" && option.label.toLowerCase().includes(input.toLowerCase())
				}
			>
				{messageTypes.map((item: MessageInterface) => (
					<Option key={item.id} value={item.id} label={item.name}>
						{item.name}
					</Option>
				))}
			</Select>
			<GlobalAlert2 isError={isError} isSuccess={false} requestType='GET' error={error} name='Messages' />
		</div>
	);
}

export default SelectMessageType;
