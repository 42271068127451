import React, { useCallback, useRef, useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Button, Card, Tag, Spin } from "antd";
// MOMENT
import moment from "moment";
// REDUX
import { useSelector } from "react-redux";
import { usePostMonitoringCommandMutation } from "../../../redux/monitoring/monitoringAPI";
// AUTHORIZATION
import GETJwtTokenSocket from "../../../redux/authentication/authenticationSocket";
import GETJwtToken from "../../../redux/authentication/authentication";

function MonitoringWebSocketConnection({
	size,
	add,
	remove,
	clear,
	readFrequency,
	serverId,
	serverName,
	channelType,
	firstLiftId,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const socket = useRef<WebSocket | null>(null);
	const myIntervalReadMessages: any = useRef(null);
	const myIntervalSendPing: any = useRef(null);
	const myIntervalRefreshQueue: any = useRef(null);
	const myIntervalCheckSocketConnection: any = useRef(null);
	const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_EDGE_SERVER;
	// WebSocket states
	const CONNECTING = 0;
	const OPEN = 1;
	const CLOSING = 2;
	const CLOSED = 3;

	// ************************************************ */
	// WEB SOCKET CONNECTIONS ************************* */
	/**
	 * GET Current Ready State = socket.current?.readyState
	 * WebSocket.CONNECTING... = 0
	 * WebSocket.OPEN......... = 1
	 * WebSocket.CLOSING...... = 2
	 * WebSocket.CLOSED....... = 3
	 */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [time, setTime] = useState(moment().format("HH:mm:ss"));
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { showQueueInfo } = useSelector((state: any) => state.monitoring);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [postCommand, { isLoading: isLoadingPostCommand }] = usePostMonitoringCommandMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const extractMessages = (data: any) => {
		if (data) {
			add(data);
		}
	};

	const checkSocketConnection = () => {
		if (socket.current?.readyState === CLOSED) {
			// eslint-disable-next-line no-use-before-define
			onConnect();
		}
	};

	const refreshAll = async () => {
		const token = await GETJwtToken();
		firstLiftId.map((lift: any) => {
			const BODY = {
				token,
				body: {
					cmd_adapter: false,
					cmd_off: false,
					lift_id: lift.id,
					short_msg: "refresh",
					type: "CONTROL",
				},
				serverId,
			};
			postCommand(BODY);
			return true;
		});
	};

	// ************************************************ */
	// TIME INTERVAL ********************************** */
	const onStartIntervalReadMessages = () => {
		myIntervalReadMessages.current = setInterval(() => {
			remove();
		}, readFrequency);
	};

	const onStopIntervalReadMessages = () => {
		clearInterval(myIntervalReadMessages.current);
		myIntervalReadMessages.current = null;
	};

	const onStopIntervalSendPing = () => {
		clearInterval(myIntervalSendPing.current);
		myIntervalSendPing.current = null;
	};

	const onStartIntervalCheckSocketConnection = () => {
		myIntervalCheckSocketConnection.current = setInterval(() => {
			checkSocketConnection();
		}, 15 * 1000);
	};

	const onStopIntervalCheckSocketConnection = () => {
		clearInterval(myIntervalCheckSocketConnection.current);
		myIntervalCheckSocketConnection.current = null;
	};

	const onStartIntervalRefreshQueue = () => {
		myIntervalRefreshQueue.current = setInterval(() => {
			clear();
			// eslint-disable-next-line no-console
			console.info("Clear Queue");
		}, 1000 * 60 * 10);
	};

	const onStopIntervalRefreshQueue = () => {
		clearInterval(myIntervalRefreshQueue.current);
	};

	// ************************************************ */
	// USE CALLBACKS ********************************** */

	const onSocketOpen = useCallback(() => {
		onStartIntervalReadMessages();
		socket.current?.send(`Check First connection server ${serverId}`);
		if (channelType !== "ADAPTER") {
			refreshAll();
		}
	}, []);

	const onSocketClose = useCallback(() => {
		// setIsConnected(false);
	}, []);

	const onSocketMessage = useCallback((dataStr) => {
		if (dataStr) {
			extractMessages(JSON.parse(dataStr));
		}
	}, []);

	const onConnect = useCallback(async () => {
		if (socket.current?.readyState !== WebSocket.OPEN) {
			const token = await GETJwtTokenSocket();
			socket.current = new WebSocket(`${SOCKET_URL}?serverID=${serverId}&access_token=${token}
`);
			socket.current.addEventListener("open", onSocketOpen);
			socket.current.addEventListener("close", onSocketClose);
			socket.current.addEventListener("message", (event: any) => {
				onSocketMessage(event.data);
			});
		}
	}, []);

	const onDisconnect = () => {
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current.send(`DISCONNECT connection server ${serverId}`);
		} else {
			// eslint-disable-next-line no-console
			console.warn("WebSocket is not open. Skipping DISCONNECT message.");
		}
		clear();
		onStopIntervalReadMessages();
		onStopIntervalSendPing();
		onStopIntervalRefreshQueue();
		socket.current?.close();
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (serverId) {
			onConnect();
			onStartIntervalRefreshQueue();
			onStartIntervalCheckSocketConnection();
		}
		//
		const interval = setInterval(() => {
			setTime(moment().tz(userTimeZone).format("HH:mm:ss z"));
		}, 1000);
		return () => {
			clear();
			onDisconnect();
			onStopIntervalCheckSocketConnection();
			clearInterval(interval);
		};
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoadingPostCommand}>
			{showQueueInfo && (
				<div>
					<Card
						title={serverName}
						style={{ width: 400, marginLeft: "15px", marginTop: "10px" }}
						extra={
							<div>
								{socket.current?.readyState !== OPEN && (
									<Button onClick={onConnect} disabled={socket.current?.readyState === OPEN}>
										Connect
									</Button>
								)}
								{socket.current?.readyState === OPEN && (
									<Button onClick={onDisconnect} disabled={socket.current?.readyState !== OPEN}>
										Disconnect
									</Button>
								)}
								<Button
									className='generalStyles__mlFix'
									onClick={refreshAll}
									disabled={socket.current?.readyState !== OPEN}
								>
									Refresh
								</Button>
							</div>
						}
					>
						<p style={{ marginBottom: "-1px" }}>
							<b
								className={`${socket.current?.readyState === OPEN ? "generalStyles__info" : "generalStyles__warning"}`}
							>
								Status:
							</b>{" "}
							<b style={{ color: "white" }}>
								{socket.current?.readyState === OPEN && <Tag color='green'>OPEN</Tag>}
								{socket.current?.readyState === CONNECTING && <Tag color='cyan'>CONNECTING</Tag>}
								{socket.current?.readyState === CLOSING && <Tag color='orange'>CLOSING</Tag>}
								{socket.current?.readyState === CLOSED && <Tag color='red'>CLOSED</Tag>}
							</b>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b
								className={`${socket.current?.readyState === OPEN ? "generalStyles__info" : "generalStyles__warning"}`}
							>
								Socket Connection:
							</b>{" "}
							<span>EDGE</span>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b
								className={`${socket.current?.readyState === OPEN ? "generalStyles__info" : "generalStyles__warning"}`}
							>
								Server:
							</b>
							<span>{serverId}</span>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b
								className={`${socket.current?.readyState === OPEN ? "generalStyles__info" : "generalStyles__warning"}`}
							>
								Channel Type:
							</b>
							<span>{channelType}</span>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b
								className={`${socket.current?.readyState === OPEN ? "generalStyles__info" : "generalStyles__warning"}`}
							>
								Queue Messages:
							</b>
							<span>{size}</span>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b
								className={`${socket.current?.readyState === OPEN ? "generalStyles__info" : "generalStyles__warning"}`}
							>
								Read Frequency:
							</b>
							<span>{readFrequency}ms</span>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b
								className={`${socket.current?.readyState === OPEN ? "generalStyles__info" : "generalStyles__warning"}`}
							>
								Watch:
							</b>
							<span>
								{" "}
								{userTimeZone} -{time}
							</span>
						</p>
					</Card>
				</div>
			)}
		</Spin>
	);
}

export default MonitoringWebSocketConnection;
