import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// XLSX
import * as XLSX from "xlsx";
// MOMENT
import moment from "moment";
// ANT DESIGN COMPONENTS
import { Input, Switch, Button, Table, InputNumber, Select, Badge, Upload, message, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleCheck,
	faCircleExclamation,
	faDownload,
	faSave,
	faTrash,
	faUpload,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateBreadcrumbTitle, updateGlobalAlert } from "../../../redux/home/homeSlice";
import {
	updateConfigurationFileBreadcrumb,
	updateShowSpecialSwitches,
} from "../../../redux/configurationFiles/configurationFilesSlice";
import {
	useLazyGetOffsetsByDataBlockQuery,
	usePostDefinitionMutation,
	usePutDefinitionMutation,
	useDeleteDefinitionMutation,
	useLazyGetAllOffsetsTypesQuery,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACE
import {
	DefinitionByteInterface,
	DefinitionsInterface,
	DefinitionsInterfacePostPut,
	DefinitionBitInterface,
	SpecialSwitchInterface,
} from "../../../interfaces/Configurations.interface";
// COMPONENTS
import SpecialSwitchesTable from "../specialSwitches/SpecialSwitchesTable";
// UTILS
import {
	getOffsetsCountByConfiguration,
	getOffsetsAttributesByConfiguration,
	addSpecialSwitchesToDefinitions,
	getOffsetIdByName,
	getTableRowOffsetClass,
	getDefinitionsArrayByOffsetType,
	generateExcelAndDownloadDefinitions,
	buildDefinitionsByteArray,
} from "../../../utils/utils";

function DefinitionsTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const navigate = useNavigate();
	const { theme } = useSelector((state: any) => state.home);
	const { Column } = Table;
	const { Option } = Select;
	const { TextArea } = Input;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [definitionBytes, setDefinitionBytes] = useState<DefinitionByteInterface[]>([]);
	const [byteCount, setByteCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [specialSwitches, setSpecialSwitches] = useState<SpecialSwitchInterface[]>([]);
	const [dataTable, setDataTable] = useState<DefinitionsInterface[]>([]);
	const [attributes, setAttributes] = useState<any>({});
	const [idOffsetType, setIdOffsetType] = useState("");
	const [offsetTypeList, setOffsetTypeList] = useState<string[]>([]);
	const [offsetTypeSelected, setOffsetTypeSelected] = useState<string>("");
	const [dataBlockOffsets, setDataBlockOffsets] = useState<any>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { configurationFile, offsetTypeTagColor } = useSelector((state: any) => state.configurationFile);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postDefinition,
		{ isSuccess: isSuccessPost, isError: isErrorPost, error: errorPost, isLoading: isLoadingPost, reset: resetPost },
	] = usePostDefinitionMutation();
	const [
		putDefinition,
		{ isSuccess: isSuccessPut, isError: isErrorPut, error: errorPut, isLoading: isLoadingPut, reset: resetPut },
	] = usePutDefinitionMutation();
	const [
		deleteDefinition,
		{
			isSuccess: isSuccessDelete,
			isError: isErrorDelete,
			error: errorDelete,
			isLoading: isLoadingDelete,
			reset: resetDelete,
		},
	] = useDeleteDefinitionMutation();
	const [triggerGetOffsetsTypes, { data: liftOffsetsType, isLoading: isLoadingGetOffsetTypes }] =
		useLazyGetAllOffsetsTypesQuery();
	const [triggerGetDataBlock, { data: configurationDataBlock, isLoading: isLoadingConfigurationDataBlock }] =
		useLazyGetOffsetsByDataBlockQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const onClickClose = () => {
		localStorage.setItem("headerTitle", "configurationCatalog");
		dispatch(updateBreadcrumbTitle("configurationCatalog"));
		setDefinitionBytes([]);
		setByteCount(0);
		dispatch(updateShowSpecialSwitches(false));
		setSpecialSwitches([]);
		dispatch(updateConfigurationFileBreadcrumb([]));
		resetPost();
		resetPut();
		resetDelete();
		navigate("/edge-management/configuration-catalog");
	};

	const onClickShowSpecialSwitches = () => {
		dispatch(updateShowSpecialSwitches(true));
	};

	const onChangeInputBoolean = (newValue: boolean, record: DefinitionsInterface, columnName: string) => {
		showLoading();
		const copy: any = structuredClone(definitionBytes);
		const index = copy[record.byte - 1].bits.findIndex((x: any) => x.id === record.id);
		copy[record.byte - 1].bits[index][columnName] = newValue;
		setDefinitionBytes(copy);
	};

	const onChangeInputString = (newValue: string, record: DefinitionsInterface, columnName: string) => {
		showLoading();
		const copy: any = structuredClone(definitionBytes);
		const index = copy[record.byte - 1].bits.findIndex((x: any) => x.id === record.id);
		copy[record.byte - 1].bits[index][columnName] = newValue;
		setDefinitionBytes(copy);
	};

	const onChangeInputNumber = (newValue: number, record: DefinitionsInterface, columnName: string) => {
		showLoading();
		const copy: any = structuredClone(definitionBytes);
		const index = copy[record.byte - 1].bits.findIndex((x: any) => x.id === record.id);
		copy[record.byte - 1].bits[index][columnName] = newValue;
		setDefinitionBytes(copy);
	};

	const buildDefinitions = () => {
		const newObj: DefinitionsInterfacePostPut[] = [];
		if (definitionBytes && definitionBytes.length > 0) {
			definitionBytes.map((item: DefinitionByteInterface) => {
				if (item.bits) {
					item.bits.map((item2: DefinitionBitInterface) => {
						if (item2.shortName !== "" && item2.longName !== "") {
							const prevData: DefinitionsInterfacePostPut = {
								active_position: item2.activePosition,
								alert: item2.alert,
								bit: item2.bit,
								byte: item2.byte,
								confirm_seconds: item2.confirmSeconds,
								group: item2.group ? item2.group.toString() : "",
								help_id: item2.helpId ? item2.helpId.toString() : "",
								id: item2.idDefinition,
								log: item2.log,
								message: item2.longName,
								offset_type_id: idOffsetType,
								paging_scheme: item2.pagingScheme,
								priority: item2.priority,
								severity: item2.severity,
								short_message: item2.shortName,
								special_switch_id: item2.specialSwitchId,
								special_switch_name: item2.specialSwitchName,
								third_party_position: item2.thirdPartyPosition ? item2.thirdPartyPosition.toString() : "",
								group_name: item2.groupName ? item2.groupName.toString() : "",
								point_name: item2.pointName ? item2.pointName.toString() : "",
								fault_spect: item2.faultSpect,
								value: item2.value,
								is_clone: item2.isClone,
							};
							// Remove items if not exists in attributes
							if (attributes) {
								if (!attributes.activePosition) {
									delete prevData.active_position;
								}
								if (!attributes.alert) {
									delete prevData.alert;
								}
								if (!attributes.confirmSeconds) {
									delete prevData.confirm_seconds;
								}
								if (!attributes.group) {
									delete prevData.group;
								}
								if (!attributes.helpId) {
									delete prevData.help_id;
								}
								if (!attributes.log) {
									delete prevData.log;
								}
								if (!attributes.pagingScheme) {
									delete prevData.paging_scheme;
								}
								if (!attributes.priority) {
									delete prevData.priority;
								}
								if (!attributes.severity) {
									delete prevData.severity;
								}
								if (!attributes.specialSwitch) {
									delete prevData.special_switch_name;
								}
								if (!attributes.specialSwitch) {
									delete prevData.special_switch_id;
								}
								if (!attributes.thirdPartyPosition) {
									delete prevData.third_party_position;
								}
								if (!attributes.groupName) {
									delete prevData.group_name;
								}
								if (!attributes.pointName) {
									delete prevData.point_name;
								}
							}
							newObj.push(prevData);
						}
						return true;
					});
				}
				return true;
			});
		}
		// Separate data to create POST and PUT definitions
		const bodyPost: any = [];
		const bodyPut: any = [];
		newObj.map((item: any) => {
			if (item && item.id && item.id !== "") {
				bodyPut.push(item);
			} else {
				bodyPost.push(item);
			}
			return true;
		});
		return [bodyPost, bodyPut];
	};

	const onClickSave = async () => {
		const [bodyPost, bodyPut] = buildDefinitions();
		const token = await GETJwtToken();
		if (bodyPost && bodyPost.length > 0) {
			const BODY = {
				id: configurationFile.data_block.id,
				body: { definitions: bodyPost },
				token,
			};
			postDefinition(BODY);
		}
		if (bodyPut && bodyPut.length > 0) {
			const BODY = {
				id: configurationFile.data_block.id,
				body: { definitions: bodyPut },
				token,
			};
			putDefinition(BODY);
		}
	};

	const buildDataTable = (definitionBytes_: DefinitionByteInterface[]) => {
		const newArr: DefinitionsInterface[] = [];
		definitionBytes_.map((item: DefinitionByteInterface) => {
			item.bits.map((item2: DefinitionBitInterface) => {
				newArr.push({
					id: item2.id,
					byte: item2.byte,
					bit: item2.bit,
					longName: item2.longName,
					shortName: item2.shortName,
					offsets: [],
					type: "",
					specialSwitchId: item2.specialSwitchId,
					specialSwitchName: item2.specialSwitchName,
					activePosition: item2.activePosition,
					priority: item2.priority,
					log: item2.log,
					offset_id: "",
					pagingScheme: item2.pagingScheme,
					faultSpect: item2.faultSpect,
					alert: item2.alert,
					severity: item2.severity,
					confirmSeconds: item2.confirmSeconds,
					helpId: item2.helpId,
					group: item2.group,
					thirdPartyPosition: item2.thirdPartyPosition,
					groupName: item2.groupName,
					pointName: item2.pointName,
					isClone: item2.isClone,
					value: item2.value,
					idDefinition: item2.idDefinition || "",
				});
				return true;
			});
			return true;
		});
		setDataTable(newArr);
	};

	const buildPreviousDefinitions = (
		definitions: DefinitionsInterfacePostPut[],
		definitionBytes_: DefinitionByteInterface[]
	) => {
		showLoading();
		const newObj = [...definitionBytes_];
		definitions.map((definition: any) => {
			definitionBytes_.map((byte: DefinitionByteInterface, indexByte: number) => {
				byte.bits.map((bit: DefinitionBitInterface, indexBit: number) => {
					if (bit.byte === definition.byte && bit.bit === definition.bit && bit.isClone === definition.is_clone) {
						newObj[indexByte].bits[indexBit].idDefinition = definition.id;
						newObj[indexByte].bits[indexBit].shortName = definition.short_message;
						newObj[indexByte].bits[indexBit].longName = definition.message || "";
						newObj[indexByte].bits[indexBit].activePosition = definition.active_position;
						newObj[indexByte].bits[indexBit].priority = definition.priority;
						newObj[indexByte].bits[indexBit].specialSwitchName = definition.special_switch_name;
						newObj[indexByte].bits[indexBit].specialSwitchId = definition.special_switch_id;
						newObj[indexByte].bits[indexBit].alert = definition.alert;
						newObj[indexByte].bits[indexBit].log = definition.log;
						newObj[indexByte].bits[indexBit].severity = definition.severity;
						newObj[indexByte].bits[indexBit].confirmSeconds = definition.confirm_seconds;
						newObj[indexByte].bits[indexBit].helpId = definition.help_id || 1;
						newObj[indexByte].bits[indexBit].group = definition.group || "";
						newObj[indexByte].bits[indexBit].pagingScheme = definition.paging_scheme;
						newObj[indexByte].bits[indexBit].thirdPartyPosition = definition.third_party_position || 1;
						newObj[indexByte].bits[indexBit].groupName = definition.group_name;
						newObj[indexByte].bits[indexBit].pointName = definition.point_name;
						newObj[indexByte].bits[indexBit].value = definition.value || 1;
					}
					return true;
				});
				return true;
			});
			return true;
		});
		setDefinitionBytes(newObj);
	};

	const getDataBlock = async () => {
		const token = await GETJwtToken();
		const response = await triggerGetDataBlock({
			token,
			dataBlockID: configurationFile.data_block.id,
		}).unwrap();
		if (response && response.data && response.data.offsets) {
			setDataBlockOffsets(response.data.offsets);
			triggerGetOffsetsTypes({ token });
		}
	};

	const onChangeSelectOffsets = (offsetTypeName: string) => {
		const totalBytes = getOffsetsCountByConfiguration(offsetTypeName, dataBlockOffsets);
		// Get list of attributes to build data table
		setAttributes(getOffsetsAttributesByConfiguration(offsetTypeName, dataBlockOffsets));
		// Get offset type id to create definitions
		setIdOffsetType(getOffsetIdByName(offsetTypeName, dataBlockOffsets));
		// Number of bytes per offset type
		setByteCount(totalBytes);
		const definitionObj = buildDefinitionsByteArray(totalBytes, offsetTypeName);
		setDefinitionBytes(definitionObj);
		// Get previous definitions already created and fill data table
		const definitions = getDefinitionsArrayByOffsetType(offsetTypeName, dataBlockOffsets);
		if (definitions && definitions.length > 0) {
			buildPreviousDefinitions(definitions, definitionObj);
		}
	};

	const initData = () => {
		showLoading();
		// Get configuration Data Block
		getDataBlock();
	};

	const onClickDownloadExcel = () => {
		const currentDateTime = moment().format("YYYY_MMM_DD");
		const configurationName = configurationFile.name;
		// eslint-disable-next-line no-useless-escape
		const reportName = `definitions_${configurationName.replace(/[\/\\:\*\?"<>\|\s]/g, "_")}_${currentDateTime}`;
		//
		const customAttributes = { id: true, isClone: true, ...attributes };
		// Remove all columns not used by attributes and undefined/null items
		const filteredData = dataTable.map((item: any) =>
			Object.keys(item)
				.filter((key: any) => customAttributes[key])
				.reduce((acc: any, key: any) => {
					let value = item[key] ?? (typeof item[key] === "number" ? 0 : "");
					if (key === "activePosition") {
						if (item[key] === undefined || item[key] === null || item[key] === false) {
							value = "FALSE";
						} else {
							value = "TRUE";
						}
					}
					return { ...acc, [key]: value };
				}, {})
		);
		// Convert boolean values into string
		const filteredData2 = filteredData.map((item) =>
			Object.keys(item).reduce((acc: any, key: any) => {
				// Check if the value is a boolean
				// eslint-disable-next-line no-nested-ternary
				acc[key] = typeof item[key] === "boolean" ? (item[key] ? "TRUE" : "FALSE") : item[key];
				return acc;
			}, {})
		);
		//
		generateExcelAndDownloadDefinitions(filteredData2, reportName);
	};

	const buildDataFromImportXlsx = (jsonData: any) => {
		const copy: DefinitionByteInterface[] = structuredClone(definitionBytes);
		copy.map((definition: DefinitionByteInterface, indexDefinition: number) => {
			definition.bits.map((bit: DefinitionBitInterface, indexBit: number) => {
				const index = jsonData.findIndex((x: any) => x.id === bit.id);
				if (index >= 0) {
					const customAttributes = { id: true, isClone: true, ...attributes };
					Object.entries(customAttributes).forEach(([key]) => {
						if (key === "isClone") {
							copy[indexDefinition].bits[indexBit][key] = jsonData[index][key] === "CLONE";
						} else if (key !== "bit" && key !== "byte" && key !== "id") {
							// eslint-disable-next-line no-nested-ternary
							copy[indexDefinition].bits[indexBit][key] =
								// eslint-disable-next-line no-nested-ternary
								jsonData[index][key] === "FALSE"
									? false
									: jsonData[index][key] === "TRUE"
									? true
									: jsonData[index][key];
						}
					});
				}
				return true;
			});
			return true;
		});
		setDefinitionBytes(copy);
	};

	const handleUpload = (file: File) => {
		showLoading();
		const reader = new FileReader();
		reader.onload = (e) => {
			try {
				const binaryStr = e.target?.result;
				const workbook = XLSX.read(binaryStr, { type: "binary" });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(sheet);
				buildDataFromImportXlsx(jsonData);
				message.success("File uploaded and data processed successfully!");
			} catch (error) {
				message.error("Error reading the Excel file. Please try again.");
			}
		};
		reader.readAsBinaryString(file); // Read the file as binary
		return false; // Prevent default upload behavior
	};

	const onChangeOffsetType = (newValue: string) => {
		if (newValue && newValue !== "") {
			setOffsetTypeSelected(newValue);
			onChangeSelectOffsets(newValue);
		}
	};

	const onClickDeleteDefinition = async (record: DefinitionsInterface) => {
		showLoading();
		const token = await GETJwtToken();
		const BODY = {
			id: record.idDefinition,
			token,
		};
		deleteDefinition(BODY);
		//
		showLoading();
		const copy: DefinitionByteInterface[] = structuredClone(definitionBytes);
		const index = copy[record.byte - 1].bits.findIndex((x: any) => x.id === record.id);
		copy[record.byte - 1].bits[index] = {
			id: "c_1_0",
			byte: 1,
			bit: 0,
			longName: "",
			shortName: "",
			offsets: [],
			type: "",
			specialSwitchId: "",
			specialSwitchName: "",
			activePosition: false,
			priority: 0,
			log: false,
			offset_id: "",
			pagingScheme: 0,
			faultSpect: 0,
			alert: false,
			severity: "",
			confirmSeconds: 0,
			helpId: 0,
			group: 0,
			thirdPartyPosition: 0,
			isClone: true,
			value: 1,
			idDefinition: "",
			register_type: "",
		};
		setDefinitionBytes(copy);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (isSuccessPost || isSuccessPut || isSuccessDelete) {
			setTimeout(() => {
				dispatch(
					updateGlobalAlert({
						title: t("general.success"),
						description: t("configuration.configurationFiles.definitionsCreated"),
						status: "success",
					})
				);
			}, 150);
		}
		if (isErrorPost || isErrorPut || isErrorDelete) {
			setTimeout(() => {
				dispatch(
					updateGlobalAlert({
						title: t("general.error"),
						description: errorPost || errorPut || errorDelete,
						status: "error",
					})
				);
			}, 150);
		}
	}, [isSuccessPost, isSuccessPut, isErrorPost, isErrorPut, isSuccessDelete, isErrorDelete]);

	useEffect(() => {
		if (configurationFile && configurationFile.data_block) {
			initData();
		}
		return () => {
			onClickClose();
		};
	}, []);

	useEffect(() => {
		if (specialSwitches.length > 0) {
			addSpecialSwitchesToDefinitions(definitionBytes, specialSwitches);
		}
	}, [specialSwitches]);

	useEffect(() => {
		buildDataTable(definitionBytes);
	}, [definitionBytes, specialSwitches]);

	useEffect(() => {
		if (liftOffsetsType && liftOffsetsType.data) {
			const newOffsetTypeList: string[] = [];
			liftOffsetsType.data.map((item: any) => {
				if (getOffsetsCountByConfiguration(item.name, configurationDataBlock.data.offsets)) {
					newOffsetTypeList.push(item);
				}
				return true;
			});
			setOffsetTypeList(newOffsetTypeList);
		}
	}, [liftOffsetsType]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='generalStyles__pageHeader' style={{ display: "flex", justifyContent: "space-between" }}>
				<div>
					<div className='generalStyles__flex'>
						<div className='generalStyles__mrFix25'>
							<p className='configuration__configurationNameTitle'>
								{configurationFile && configurationFile.name ? configurationFile.name : "--"}
							</p>
							<p style={{ fontSize: "17px", fontWeight: "bolder", marginTop: "-20px" }}>{offsetTypeSelected || "--"}</p>
						</div>
					</div>
				</div>
				<div className='generalStyles__flex mt-3'>
					<div className='generalStyles__width250px'>
						<Select
							showSearch
							optionFilterProp='children'
							allowClear
							className='generalStyles__width100'
							size='small'
							value={offsetTypeSelected !== "" ? offsetTypeSelected : undefined}
							placeholder='Select Offset Type'
							onChange={onChangeOffsetType}
							loading={isLoadingGetOffsetTypes || isLoadingConfigurationDataBlock}
							disabled={isLoadingGetOffsetTypes || isLoadingConfigurationDataBlock || isLoading}
						>
							{offsetTypeList.map((item: any) => (
								<Option key={item.id} value={item.name}>
									{item.name}
								</Option>
							))}
						</Select>
					</div>
					<div>
						<Tooltip title={t("general.downloadFile")}>
							<Button
								className={`${offsetTypeSelected === "" ? "" : "buttonStyle__13"}  generalStyles__mlFix`}
								style={{ marginTop: "-5px" }}
								onClick={onClickDownloadExcel}
								icon={<FontAwesomeIcon className='' icon={faDownload} />}
								disabled={offsetTypeSelected === ""}
							/>
						</Tooltip>
					</div>
					<div>
						<Upload accept='.xlsx, .xls' beforeUpload={handleUpload} showUploadList={false}>
							<Tooltip title={t("general.uploadFile")}>
								<Button
									className={`${offsetTypeSelected === "" ? "" : "buttonStyle__20"}  generalStyles__mlFix`}
									style={{ marginTop: "-5px" }}
									icon={<FontAwesomeIcon className='' icon={faUpload} />}
									disabled={offsetTypeSelected === ""}
								/>
							</Tooltip>
						</Upload>
					</div>
					<div>
						<Tooltip title={t("general.save")}>
							<Button
								icon={<FontAwesomeIcon className='' icon={faSave} />}
								className={`${offsetTypeSelected === "" ? "" : "buttonStyle__17"}  generalStyles__mlFix`}
								style={{ marginTop: "-5px" }}
								onClick={onClickSave}
								disabled={offsetTypeSelected === ""}
							/>
						</Tooltip>
					</div>
				</div>
			</div>
			<div>
				<div>
					{/** ---------------------------------------------------- */}
					{/** TABLE */}
					<Table
						rowClassName={(record, index) => getTableRowOffsetClass(index, theme, record.byte)}
						dataSource={dataTable}
						pagination={{
							defaultPageSize: 8,
							showSizeChanger: true,
							pageSizeOptions: ["8", "20", "50", "100"],
						}}
						loading={
							isLoading ||
							isLoadingPost ||
							isLoadingPut ||
							isLoadingGetOffsetTypes ||
							isLoadingConfigurationDataBlock ||
							isLoadingDelete
						}
						scroll={{ x: 1300 }}
						onChange={showLoading}
						size='small'
						rowKey={(record) => record.id}
					>
						{/** COLUMN STATUS */}
						<Column
							title='Status'
							dataIndex='status'
							key='status'
							className='generalStyles__tableHeaderLink'
							width={15}
							render={(text, record: DefinitionsInterface) => (
								<div>
									{record.bit === 0 ? (
										<Badge.Ribbon
											color={!record.isClone ? offsetTypeTagColor : "gray"}
											text={` ${record.isClone ? "Clone" : ""} Byte ${record.byte}`}
											style={{ marginTop: "-18px" }}
											placement='start'
										>
											<div style={{ marginLeft: "75px" }}>
												{record.shortName !== "" && record.longName !== "" ? (
													<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__success' />
												) : (
													<></>
												)}
											</div>
										</Badge.Ribbon>
									) : (
										<div style={{ marginLeft: "75px" }}>
											{record.shortName !== "" && record.longName !== "" ? (
												<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__success' />
											) : (
												<></>
											)}
										</div>
									)}
								</div>
							)}
						/>
						{/** CLONE */}
						<Column
							title='Clone'
							align='center'
							className='generalStyles__tableHeaderLink'
							render={(text, record: any) => (
								<>
									{record.isClone ? (
										<FontAwesomeIcon icon={faCircleExclamation} style={{ color: "gray" }} />
									) : (
										<FontAwesomeIcon icon={faCircleExclamation} className=' generalStyles__info' />
									)}
								</>
							)}
						/>
						{/** BYTE */}
						<Column
							title='Byte'
							dataIndex='byte'
							key='byte'
							className='generalStyles__tableHeaderLink'
							render={(text) => (
								<>
									<div>
										{text} / {byteCount}
									</div>
								</>
							)}
						/>
						{/** BIT */}
						{attributes.bit && (
							<Column
								title={byteCount > 1 ? "Bit" : "Value"}
								dataIndex='bit'
								key='bit'
								className='generalStyles__tableHeaderLink'
								render={(text) => (
									<>
										<div>{text}</div>
									</>
								)}
							/>
						)}
						{/** SPECIAL SWITCH */}
						{attributes.specialSwitch && (
							<Column
								title='Special Switch'
								dataIndex='specialSwitchName'
								key='specialSwitchName'
								className='generalStyles__tableHeaderLink'
								render={(text) => (
									<div className='generalStyles__textEllipsis'>
										<span
											className='generalStyles__info '
											role='button'
											tabIndex={0}
											onClick={() => {
												onClickShowSpecialSwitches();
											}}
											onKeyDown={() => {
												onClickShowSpecialSwitches();
											}}
										>
											{text || "--"}
										</span>
									</div>
								)}
							/>
						)}
						{/** SHORT NAME */}
						{attributes.shortName && (
							<Column
								title='Short Name'
								dataIndex='shortName'
								key='shortName'
								className='generalStyles__tableHeaderLink'
								render={(text, record: DefinitionsInterface) => (
									<TextArea
										bordered={false}
										placeholder={`${t("configuration.configurationFiles.shortName")} ${record.bit}`}
										value={text}
										onChange={(e: any) => {
											onChangeInputString(e.currentTarget.value, record, "shortName");
										}}
										style={{ height: 40, resize: "none" }}
									/>
								)}
							/>
						)}
						{/** LONG NAME */}
						{attributes.longName && (
							<Column
								title='Long Name'
								dataIndex='longName'
								key='longName'
								className='generalStyles__tableHeaderLink'
								render={(text, record: DefinitionsInterface) => (
									<TextArea
										bordered={false}
										placeholder={`${t("configuration.configurationFiles.longName")} ${record.bit}`}
										value={text}
										onChange={(e: any) => {
											onChangeInputString(e.currentTarget.value, record, "longName");
										}}
										style={{ height: 40, resize: "none" }}
									/>
								)}
							/>
						)}
						{/** ACTIVE POSITION */}
						{attributes.activePosition && (
							<Column
								title='Active Position'
								dataIndex='activePosition'
								key='activePosition'
								className='generalStyles__tableHeaderLink'
								render={(text, record: DefinitionsInterface) => (
									<Switch
										checkedChildren='High'
										unCheckedChildren='Low'
										checked={text}
										onChange={(e) => {
											onChangeInputBoolean(e, record, "activePosition");
										}}
									/>
								)}
							/>
						)}
						{/** PRIORITY */}
						{attributes.priority && (
							<Column
								title='Priority'
								dataIndex='priority'
								key='priority'
								render={(text, record: DefinitionsInterface) => (
									<InputNumber
										bordered={false}
										style={{ width: "100%" }}
										value={text}
										onChange={(e) => {
											onChangeInputNumber(e, record, "priority");
										}}
										min={0}
										max={99}
									/>
								)}
							/>
						)}
						{/** SEVERITY */}
						{attributes.severity && (
							<Column
								title='Severity'
								dataIndex='severity'
								key='severity'
								render={(text, record: DefinitionsInterface) => (
									<Select
										className='configuration__inputSizeL'
										size='small'
										bordered={false}
										value={text}
										onChange={(e) => {
											onChangeInputString(e, record, "severity");
										}}
									>
										{["None", "Trouble", "Warning", "Ready", "Maint", "Log", "Ignore"].map(
											(severity: string, index: number) => (
												<Option key={index}>{severity}</Option>
											)
										)}
									</Select>
								)}
							/>
						)}
						{/** ALERT */}
						{attributes.alert && (
							<Column
								title='Alert'
								dataIndex='alert'
								key='alert'
								render={(text, record: DefinitionsInterface) => (
									<Switch
										checkedChildren='Alert'
										unCheckedChildren='No'
										checked={text}
										onChange={(e) => {
											onChangeInputBoolean(e, record, "alert");
										}}
									/>
								)}
							/>
						)}
						{/** LOG */}
						{attributes.log && (
							<Column
								title='Log'
								dataIndex='log'
								key='log'
								render={(text, record: DefinitionsInterface) => (
									<Switch
										checkedChildren='Log'
										unCheckedChildren='No'
										checked={text}
										onChange={(e) => {
											onChangeInputBoolean(e, record, "log");
										}}
									/>
								)}
							/>
						)}
						{/** CONFIRM SECONDS */}
						{attributes.confirmSeconds && (
							<Column
								title='Confirm Seconds'
								dataIndex='confirmSeconds'
								key='confirmSeconds'
								render={(text, record: DefinitionsInterface) => (
									<InputNumber
										bordered={false}
										className='configuration__inputSizeS'
										min={0}
										max={999}
										precision={0}
										value={text}
										onChange={(e) => {
											onChangeInputNumber(e, record, "confirmSecond");
										}}
									/>
								)}
							/>
						)}
						{/** HELP ID */}
						{attributes.helpId && (
							<Column
								title='Help ID'
								dataIndex='helpId'
								key='helpId'
								width='100px'
								render={(text, record: DefinitionsInterface) => (
									<InputNumber
										bordered={false}
										className='configuration__inputSizeXXS'
										min={0}
										max={999}
										precision={0}
										value={text}
										onChange={(e) => {
											onChangeInputNumber(e, record, "helpId");
										}}
									/>
								)}
							/>
						)}
						{/** GROUP */}
						{attributes.group && (
							<Column
								title='Group'
								dataIndex='group'
								key='group'
								render={(text, record: DefinitionsInterface) => (
									<InputNumber
										bordered={false}
										className='configuration__inputSizeS'
										min={0}
										max={999}
										precision={0}
										value={text}
										onChange={(e) => {
											onChangeInputNumber(e, record, "group");
										}}
									/>
								)}
							/>
						)}
						{/** PAGING SCHEME */}
						{attributes.pagingScheme && (
							<Column
								title='Paging Scheme'
								dataIndex='pagingScheme'
								key='pagingScheme'
								render={(text, record: DefinitionsInterface) => (
									<InputNumber
										bordered={false}
										className='configuration__inputSizeS'
										min={0}
										max={999}
										precision={0}
										value={text}
										onChange={(e) => {
											onChangeInputNumber(e, record, "pagingScheme");
										}}
									/>
								)}
							/>
						)}
						{/** THIRD PARTY POSITION */}
						{attributes.thirdPartyPosition && (
							<Column
								title='3rd Party Position'
								dataIndex='thirdPartyPosition'
								key='thirdPartyPosition'
								render={(text, record: DefinitionsInterface) => (
									<InputNumber
										bordered={false}
										className='configuration__inputSizeS'
										min={0}
										max={999}
										precision={0}
										value={text}
										onChange={(e) => {
											onChangeInputNumber(e, record, "thirdPartyPosition");
										}}
									/>
								)}
							/>
						)}
						{/** GROUP NAME */}
						{attributes.groupName && (
							<Column
								title='Group Name'
								dataIndex='groupName'
								key='groupName'
								render={(text, record: DefinitionsInterface) => (
									<Input
										bordered={false}
										className='configuration__inputSizeS'
										value={text}
										onChange={(e) => {
											onChangeInputString(e.target.value, record, "groupName");
										}}
									/>
								)}
							/>
						)}
						{/** POINT NAME */}
						{attributes.pointName && (
							<Column
								title='Point Name'
								dataIndex='pointName'
								key='pointName'
								render={(text, record: DefinitionsInterface) => (
									<Input
										bordered={false}
										className='configuration__inputSizeS'
										value={text}
										onChange={(e) => {
											onChangeInputString(e.target.value, record, "pointName");
										}}
									/>
								)}
							/>
						)}
						{/** VALUE */}
						{attributes.pointName && (
							<Column
								title='Value'
								dataIndex='value'
								key='value'
								render={(text, record: DefinitionsInterface) => (
									<InputNumber
										bordered={false}
										className='configuration__inputSizeS'
										min={0}
										max={1}
										precision={0}
										value={text}
										onChange={(e) => {
											onChangeInputNumber(e, record, "value");
										}}
									/>
								)}
							/>
						)}
						{/** DELETE DEFINITION */}
						<Column
							title='Delete'
							dataIndex='delete'
							key='delete'
							render={(text, record: DefinitionsInterface) => (
								<Button
									onClick={() => {
										onClickDeleteDefinition(record);
									}}
									shape='circle'
									className={record.idDefinition === "" ? "" : "buttonStyle__26"}
									disabled={record.idDefinition === ""}
								>
									<FontAwesomeIcon icon={faTrash} />
								</Button>
							)}
						/>
					</Table>
				</div>
			</div>
			{/** ---------------------------------------------------- */}
			{/** SPECIAL SWITCHES COMPONENT */}
			<SpecialSwitchesTable
				showLoading={showLoading}
				byteCount={byteCount}
				setSpecialSwitches={setSpecialSwitches}
				definitionBytes={definitionBytes}
			/>
		</div>
	);
}

export default DefinitionsTable;
