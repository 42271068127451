import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const reportAPI = createApi({
	reducerPath: "reportAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetReports"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* OLD GENERAL REPORT */
		getLiftReport: builder.query({
			query: (body: any) => ({
				url: `/lift-records/?page=${body.page}&limit=${
					body.limit
				}&sort_direction=asc&${body.searchAndFilterString || ""}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetReports"],
		}),
		//* *********************************************************** */
		//* GET GENERAL REPORT */
		postGeneralReport: builder.mutation({
			query: (body: any) => ({
				url: "/lift-records/general",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET REPORT BENEFICIAL */
		postBeneficialReport: builder.mutation({
			query: (body: any) => ({
				url: "/lift-records/beneficial-usage",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET REPORT PERFORMANCE */
		postPerformanceReport: builder.mutation({
			query: (body: any) => ({
				url: "/lift-records/performance",
				headers: { authorization: body.token },
				method: "post",
				body: body.body,
			}),
		}),
		//* *********************************************************** */
		//* GET REPORT OUT OF SERVICE */
		postOutOfServiceReport: builder.mutation({
			query: (body: any) => ({
				url: "/lift-records/out-of-service",
				method: "post",
				headers: { authorization: body.token },
				body: body.body,
			}),
		}),
		//* *********************************************************** */
		//* GET REPORT LOG */
		postFaultLogReport: builder.mutation({
			query: (body: any) => ({
				url: "/reports/faults/log",
				method: "post",
				headers: { authorization: body.token },
				body: body.body,
			}),
		}),
		//* *********************************************************** */
		//* GET REPORT RECENT FAULTS */
		postFaultRecentReport: builder.mutation({
			query: (body: any) => ({
				url: "/reports/faults/recent",
				method: "post",
				headers: { authorization: body.token },
				body: body.body,
			}),
		}),
		//* *********************************************************** */
		//* GET REPORT TRAFFIC SUMMARY DAY */
		postTrafficSummaryDay: builder.mutation({
			query: (body: any) => ({
				url: "/reports/traffic/summary/day",
				method: "post",
				headers: { authorization: body.token },
				body: body.body,
			}),
		}),
		//* *********************************************************** */
		//* GET REPORT TRAFFIC SUMMARY FLOOR */
		postTrafficSummaryFloor: builder.mutation({
			query: (body: any) => ({
				url: "/reports/traffic/summary/floor",
				method: "post",
				headers: { authorization: body.token },
				body: body.body,
			}),
		}),
		//* *********************************************************** */
		//* GET TRAFFIC DISTRIBUTION BY HOURLY INTERVAL */
		postTrafficWaitTimeList: builder.mutation({
			query: (body: any) => ({
				url: "/reports/traffic/wait-time",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET CAR SERVICE LOG */
		postCarServiceLog: builder.mutation({
			query: (body: any) => ({
				url: "/reports/service/log",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET CAR SERVICE STATISTICS */
		postCarServiceStatistics: builder.mutation({
			query: (body: any) => ({
				url: "/reports/service/statistics",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET ALL BANKS ESCALATORS IN USE */
		postAllBanksEscalatorsInUse: builder.mutation({
			query: (body: any) => ({
				url: "/reports/all-banks/escalators-use",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET ALL BANKS BENEFICIAL */
		postAllBanksBeneficial: builder.mutation({
			query: (body: any) => ({
				url: "/reports/all-banks/beneficial",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET ALL BANKS PERFORMANCE */
		postAllBanksPerformance: builder.mutation({
			query: (body: any) => ({
				url: "/reports/all-banks/performance",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
        //* GET ALL BANKS INTERRUPTIONS */
        postAllBanksInterruptions: builder.mutation({
            query: (body: any) => ({
                url: "/reports/all-banks/interruptions",
                body: body.body,
                method: "post",
                headers: { authorization: body.token },
            }),
        }),
		//* *********************************************************** */
		//* GET ALL BANKS FIRE SERVICE */
		postAllBanksFireService: builder.mutation({
			query: (body: any) => ({
				url: "/reports/all-banks/fire-service",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET FREQUENCY CAR */
		postFrequencyCar: builder.mutation({
			query: (body: any) => ({
				url: "/reports/frequency/lift",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET FREQUENCY FAULT */
		postFrequencyFault: builder.mutation({
			query: (body: any) => ({
				url: "/reports/frequency/fault",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetLiftReportQuery,
	usePostGeneralReportMutation,
	usePostBeneficialReportMutation,
	usePostOutOfServiceReportMutation,
	usePostPerformanceReportMutation,
	usePostFaultLogReportMutation,
	usePostFaultRecentReportMutation,
	usePostTrafficSummaryDayMutation,
	usePostTrafficSummaryFloorMutation,
	usePostTrafficWaitTimeListMutation,
	usePostCarServiceLogMutation,
	usePostCarServiceStatisticsMutation,
	usePostAllBanksBeneficialMutation,
	usePostAllBanksEscalatorsInUseMutation,
	usePostAllBanksPerformanceMutation,
	usePostAllBanksInterruptionsMutation,
	usePostAllBanksFireServiceMutation,
	usePostFrequencyCarMutation,
	usePostFrequencyFaultMutation,
} = reportAPI;
