import React from "react";
// NAVIGATION
import { useNavigate, useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";

function KeysBankBreadcrumb() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const navigate = useNavigate();
	const [t] = useTranslation("global");
	const location = useLocation();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const navigateToAdapters = () => {
		navigate({
			pathname: "/edge-management/adapters",
		});
	};

	const navigateToBanks = () => {
		navigate({
			pathname: "/edge-management/banks",
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Breadcrumb className=''>
			<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
			{location.pathname === "/edge-management/adapter/keys" && (
				<Breadcrumb.Item
					onClick={() => {
						navigateToAdapters();
					}}
					className='generalStyles__breadcrumbItemInactive'
				>
					{t("header.adapters")}
				</Breadcrumb.Item>
			)}
			{location.pathname === "/edge-management/bank/keys" && (
				<Breadcrumb.Item
					onClick={() => {
						navigateToBanks();
					}}
					className='generalStyles__breadcrumbItemInactive'
				>
					{t("header.banks")}
				</Breadcrumb.Item>
			)}
			<Breadcrumb.Item className='generalStyles__breadcrumbItem'>Keys</Breadcrumb.Item>
		</Breadcrumb>
	);
}

export default KeysBankBreadcrumb;
