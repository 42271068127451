import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const keyAPI = createApi({
	reducerPath: "keyAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetAdapter", "tagGetBank", "tagGetKeys", "tagPostKey", "tagPutKey", "tagDeleteKey"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ADAPTER */
		getAdapter: builder.query({
			query: (body: any) => ({
				url: `/adapters/${body.adapterId}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetAdapter"],
		}),
		//* *********************************************************** */
		//* GET BANK */
		getBank: builder.query({
			query: (body: any) => ({
				url: `/banks/${body.bankId}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetBank"],
		}),
		//* *********************************************************** */
		//* GET ADAPTER KEYS */
		getAdapterKeys: builder.query({
			query: (body: any) => ({
				url: `/keys/?&page=1&limit=9999&filter_target=adapter_id&filter=${body.adapterId}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetKeys"],
		}),
		//* *********************************************************** */
		//* POST ADAPTER KEY */
		postAdapterKey: builder.mutation({
			query: (body: any) => ({
				url: `/keys/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* PUT ADAPTER KEY */
		putAdapterKey: builder.mutation({
			query: (body: any) => ({
				url: `/keys/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* DELETE ADAPTER KEY */
		deleteAdapterKey: builder.mutation({
			query: (body: any) => ({
				url: `/keys/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* GET BANK KEYS */
		getBankKeys: builder.query({
			query: (body: any) => ({
				url: `/custom-keys/?&page=1&limit=9999&filter_target=bank_id&filter=${body.adapterId}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetKeys"],
		}),
		//* *********************************************************** */
		//* POST BANK KEY */
		postBankKey: builder.mutation({
			query: (body: any) => ({
				url: `/custom-keys/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* PUT BANK KEY */
		putBankKey: builder.mutation({
			query: (body: any) => ({
				url: `/custom-keys/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* DELETE BANK KEY */
		deleteBankKey: builder.mutation({
			query: (body: any) => ({
				url: `/custom-keys/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* IMPORT KEY */
		importKey: builder.mutation({
			query: (body: any) => {
				const bodyFormData = new FormData();
				bodyFormData.append("csv", body.body.csv);
				bodyFormData.append("adapterID", body.body.adapter_id);
				return {
					url: `/keys/import/`,
					method: "post",
					body: bodyFormData,
					headers: { authorization: body.token },
					formData: true,
				};
			},
			invalidatesTags: ["tagGetKeys"],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetBankQuery,
	useLazyGetAdapterQuery,
	useLazyGetAdapterKeysQuery,
	usePostAdapterKeyMutation,
	usePutAdapterKeyMutation,
	useDeleteAdapterKeyMutation,
	useLazyGetBankKeysQuery,
	usePostBankKeyMutation,
	usePutBankKeyMutation,
	useDeleteBankKeyMutation,
	useImportKeyMutation,
} = keyAPI;
