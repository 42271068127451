import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Input, Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faCode, faEye, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";
import SelectInterfaceTemplates from "../select/SelectInterfaceTemplates";
import SelectInterfaceItem from "../select/SelectInterfaceItem";
// INTERFACES
import { RootState } from "../../../app/store";
import { DrawerCreateInterfaceObjectInterface } from "../../../interfaces/Components.interface";

function DrawerCreateInterfaceObject({
	jsonInterface,
	setJsonInterface,
	displayJsonItem,
	displayJsonTemplate,
}: DrawerCreateInterfaceObjectInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [key, setKey] = useState<string>("");
	const [keys, setKeys] = useState<string[]>([]);
	const [duplicatedKey, setDuplicatedKey] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickResetData = () => {
		setKey("");
		setKeys([]);
		setDuplicatedKey(false);
	};

	const inputOnChange = (newValue: string) => {
		setKey(newValue);
	};

	const disabledAddButton = () => {
		if (key !== "" && !duplicatedKey) {
			return false;
		}
		return true;
	};

	const onClickAddNewKey = () => {
		if (key !== "") {
			setKey("");
			const copy: string[] = structuredClone(keys);
			copy.push(key.replace(" ", "_"));
			setKeys(copy);
			// Generate new JSON object
			const newObject: any = {};
			copy.forEach((item: string) => {
				newObject[item] = "";
			});
			setJsonInterface(newObject);
		}
	};

	const onKeyDownAddNewKey = async (event: any) => {
		if (event.key === "Enter" && !duplicatedKey) {
			onClickAddNewKey();
		}
	};

	const onClickDeleteKey = (deleteValue: string) => {
		const copy = structuredClone(keys);
		const index = copy.findIndex((item: string) => item === deleteValue);
		if (index >= 0) {
			copy.splice(index, 1);
		}
		setKeys(copy);
	};

	const setJsonItem = (newValue: string) => {
		if (!keys.includes(newValue)) {
			setKey("");
			const copy: string[] = structuredClone(keys);
			copy.push(newValue.replace(" ", "_"));
			setKeys(copy);
			// Generate new JSON object
			const newObject: any = {};
			copy.forEach((item: string) => {
				newObject[item] = "";
			});
			setJsonInterface(newObject);
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			onClickResetData();
		};
	}, []);

	useEffect(() => {
		setKeys(Object.keys(jsonInterface));
	}, [jsonInterface]);

	useEffect(() => {
		if (keys.includes(key)) {
			setDuplicatedKey(true);
		} else {
			setDuplicatedKey(false);
		}
	}, [key]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ------------------------------------------------------------- */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faCode} title={t("general.interfaceAssign")} />
			<div className={`drawer__box__${theme}`}>
				{displayJsonTemplate && (
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title'>JSON Templates</p>
						</div>
						<div className='drawer__textContainer' style={{ width: "268px" }}>
							<SelectInterfaceTemplates setJsonInterface={setJsonInterface} />
						</div>
					</div>
				)}
				{displayJsonItem && (
					<div className='generalStyles__flex mt-2'>
						<div>
							<p className='drawer__title'>Tags</p>
						</div>
						<div className='drawer__textContainer' style={{ width: "268px" }}>
							<SelectInterfaceItem setJsonItem={setJsonItem} />
						</div>
					</div>
				)}
				<div className='generalStyles__flex mt-2'>
					<div>
						<p className='drawer__title'>Key:</p>
					</div>
					<div className='drawer__textContainer generalStyles__width100'>
						<div className='generalStyles__flex mb-2'>
							<div className='generalStyles__width100'>
								<Input
									className='generalStyles__input'
									size='small'
									value={key}
									onKeyDown={onKeyDownAddNewKey}
									onChange={(event: React.FormEvent<HTMLInputElement>) => {
										inputOnChange(event.currentTarget.value);
									}}
									status={!keys.includes(key) ? "" : "error"}
								/>
								{keys.includes(key) && (
									<span className='generalStyles__error' style={{ fontSize: "8px" }}>
										{t("general.keyAlreadyExist")}
									</span>
								)}
							</div>
							<div className='generalStyles__mlFix'>
								<Tooltip title={t("general.add")} placement='left'>
									<Button
										className='generalStyles__mrFix'
										shape='circle'
										icon={<FontAwesomeIcon icon={faPlusCircle} />}
										disabled={disabledAddButton()}
										onClick={onClickAddNewKey}
									/>
								</Tooltip>
							</div>
						</div>
						{keys.map((item: string) => (
							<div className='generalStyles__flex mt-1' key={item}>
								<div className='generalStyles__width100'>
									<Input className='generalStyles__input' size='small' value={item} disabled />
								</div>
								<div className='generalStyles__mlFix'>
									<Tooltip title={t("general.delete")} placement='left'>
										<Button
											style={{ marginTop: "-3px" }}
											className='generalStyles__mrFix buttonStyle__26'
											shape='circle'
											icon={<FontAwesomeIcon icon={faTrash} />}
											onClick={() => {
												onClickDeleteKey(item);
											}}
										/>
									</Tooltip>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<br />
			{/** ------------------------------------------------------------- */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faEye} title={t("general.preview")} />
			<div className={`drawer__box__${theme}`}>
				<div className='generalStyles__flex drawer__container'>
					<div>
						<pre className='drawer__text'>{JSON.stringify(jsonInterface, null, 2)}</pre>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DrawerCreateInterfaceObject;
