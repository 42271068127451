import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import OrganizationTable from "./OrganizationsTable";
import OrganizationsHeader from "./OrganizationsHeader";
import OrganizationCreateEditForm from "./OrganizationCreateEditForm";
import OrganizationDrawer from "./OrganizationDrawer";
import OrganizationsDrawerHolidays from "./OrganizationsDrawerHolidays";
import OrganizationInfoButton from "./OrganizationInfoButton";
// TOUR
import { TourProvider } from "../../tour/TourOrganizationHoliday";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const OrganizationPage = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const { permissions } = useSelector((state: RootState) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("view-organization-management-organizations", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<TourProvider>
						<OrganizationsHeader />
						<OrganizationTable />
						<OrganizationCreateEditForm />
						<OrganizationDrawer />
						<OrganizationsDrawerHolidays />
						{validatePermission("view-application-global-tours", permissions) && <OrganizationInfoButton />}
					</TourProvider>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default OrganizationPage;
