import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Input, Spin, Divider } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield, faWifi, faBuildingShield } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutRoleMutation } from "../../../redux/roles/roleAPI";
import { updateOpenModalEditRole } from "../../../redux/roles/roleSlice";
// COMPONENTS
import RolesDinamicRolesTree from "./RolesDinamicRolesTree";
import GlobalAlert2 from "../../home/GlobalAlert2";
import ButtonClose from "../../components/buttons/ButtonClose";
import ButtonSave from "../../components/buttons/ButtonSave";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function RolesEditRoleForm() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { role, openModalEditRole } = useSelector((state: any) => state.role);
	const [t] = useTranslation("global");
	const [menuOnline, setMenuOnline] = useState<any>({});
	const [menuOffline, setMenuOffline] = useState<any>({});
	const { theme, userLogged } = useSelector((state: any) => state.home);
	const [roleName, setRoleName] = useState("");

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		putRole,
		{
			isSuccess: isSuccessPutRole,
			isError: isErrorPutRole,
			error: errorPutRole,
			isLoading: isLoadingPutRole,
			reset: resetPutRole,
		},
	] = usePutRoleMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const onClickClose = () => {
		dispatch(updateOpenModalEditRole(false));
	};

	const getValidPermissions = (connectionType: string) => {
		// Create a copy of menu
		let copy: any = {};
		if (connectionType === "active") {
			copy = structuredClone(menuOnline);
		} else if (connectionType === "offline") {
			copy = structuredClone(menuOffline);
		}
		// Extract all permissions by tree key
		const validPermissions: any = {};
		// PAGES
		copy.pages.map((page: any) => {
			if (page && page[connectionType]) {
				validPermissions[page.tree_key] = true;
			}
			// SUB PAGES
			page.sub_pages.map((subPage: any) => {
				if (subPage && subPage.tree_key && subPage[connectionType]) {
					validPermissions[subPage.tree_key] = true;
				}
				// ACTIONS
				subPage.actions.map((action: any) => {
					if (action && action.tree_key && action[connectionType]) {
						validPermissions[action.tree_key] = true;
					}
					return true;
				});
				return true;
			});
			return true;
		});
		return validPermissions;
	};

	const generateGlobalPermissions = (validOnlinePermissions: any, validOfflinePermissions: any, menu: any) => {
		const copy = structuredClone(menu);
		copy.pages.map((page: any, pageIndex: number) => {
			// Check online permissions
			if (validOnlinePermissions[page.tree_key]) {
				copy.pages[pageIndex].active = true;
			} else {
				copy.pages[pageIndex].active = false;
			}
			// Check offline permissions
			if (validOfflinePermissions[page.tree_key]) {
				copy.pages[pageIndex].offline = true;
			} else {
				copy.pages[pageIndex].offline = false;
			}
			// SUB PAGES
			page.sub_pages.map((subPage: any, subPageIndex: number) => {
				// Check online permissions
				if (validOnlinePermissions[subPage.tree_key]) {
					copy.pages[pageIndex].sub_pages[subPageIndex].active = true;
				} else {
					copy.pages[pageIndex].sub_pages[subPageIndex].active = false;
				}
				// Check offline permissions
				if (validOfflinePermissions[subPage.tree_key]) {
					copy.pages[pageIndex].sub_pages[subPageIndex].offline = true;
				} else {
					copy.pages[pageIndex].sub_pages[subPageIndex].offline = false;
				}
				// ACTIONS
				subPage.actions.map((action: any, actionIndex: number) => {
					// Check online permissions
					if (validOnlinePermissions[action.tree_key]) {
						copy.pages[pageIndex].sub_pages[subPageIndex].actions[actionIndex].active = true;
					} else {
						copy.pages[pageIndex].sub_pages[subPageIndex].actions[actionIndex].active = false;
					}
					// Check offline permissions
					if (validOfflinePermissions[action.tree_key]) {
						copy.pages[pageIndex].sub_pages[subPageIndex].actions[actionIndex].offline = true;
					} else {
						copy.pages[pageIndex].sub_pages[subPageIndex].actions[actionIndex].offline = false;
					}
					return true;
				});
				return true;
			});
			return true;
		});
		return copy;
	};

	const buildMenuOfflineAndOnline = () => {
		const validOnlinePermissions = getValidPermissions("active");
		const validOfflinePermissions = getValidPermissions("offline");
		if (userLogged && userLogged.role && userLogged.role.menu) {
			const globalPermissions = generateGlobalPermissions(
				validOnlinePermissions,
				validOfflinePermissions,
				userLogged.role.menu
			);
			return globalPermissions;
		}
		return {};
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const newMenu = buildMenuOfflineAndOnline();
		// eslint-disable-next-line no-unused-vars
		const BODY = {
			id: role.id,
			body: {
				active: role.active,
				menu: JSON.stringify(newMenu),
				name: roleName,
				org_id: role.org_id,
			},
			token,
		};
		putRole(BODY);
	};

	const onClose = () => {
		dispatch(updateOpenModalEditRole(false));
		setRoleName("");
	};

	const onFill = () => {
		if (role) {
			setRoleName(role.name);
			if (role.menu) {
				setMenuOffline(role.menu);
				setMenuOnline(role.menu);
			}
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (openModalEditRole) {
			onFill();
			resetPutRole();
		}
	}, [openModalEditRole]);

	useEffect(() => {
		dispatch(updateOpenModalEditRole(false));
	}, [isSuccessPutRole, isErrorPutRole]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='550'
			placement='right'
			onClose={onClose}
			closable={false}
			open={openModalEditRole}
			footer={
				<div className='container generalStyles__flex mt-4'>
					<div className='generalStyles__width100 generalStyles__mrFix'>
						<ButtonClose onClick={onClickClose} />
					</div>
					<div className='generalStyles__width100 generalStyles__mlFix'>
						<ButtonSave onClick={onClickSave} disabledButton={false} isLoading={false} />
					</div>
				</div>
			}
		>
			<Spin spinning={isLoadingPutRole} tip={t("general.loading")}>
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faUserShield} className='generalStyles__info generalStyles__mrFix' />
							{t("organizationManagement.editRole")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					<div className='col-md-12'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>{t("organizationManagement.roleName")}</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									className='generalStyles__input'
									onChange={(event: React.FormEvent<HTMLInputElement>) => {
										setRoleName(event.currentTarget.value);
									}}
									value={roleName}
									size='small'
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
				</div>
				<br />
				{/** ************************************************* */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faWifi} className='generalStyles__info generalStyles__mrFix' />
							Online {t("organizationManagement.permissions")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					<div>
						<RolesDinamicRolesTree setMenu={setMenuOnline} roleData={role} connectionType='active' />
					</div>
				</div>
				<br />

				{/** ************************************************* */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faBuildingShield} className='generalStyles__info generalStyles__mrFix' />
							Offline {t("organizationManagement.permissions")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					<div>
						<RolesDinamicRolesTree setMenu={setMenuOffline} roleData={role} connectionType='offline' />
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPutRole}
				isSuccess={isSuccessPutRole}
				requestType='PUT'
				error={errorPutRole}
				name='Role'
			/>
		</Drawer>
	);
}

export default RolesEditRoleForm;
