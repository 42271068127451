import React, { useEffect, useState } from "react";
import { Button, Input, Drawer, Spin, Divider, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faDownload } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetExportServerInfoQuery } from "../../../redux/servers/serverAPI";
import { updateOpenModalExportServer } from "../../../redux/servers/serverSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

function ExportFileComponent() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [isLoading, setIsLoading] = useState(false);
	const [fileName, setFileName] = useState("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { server, openModalExportServer } = useSelector((state: any) => state.server);
	const { theme } = useSelector((state: any) => state.home);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [getExportServerInfo, { isSuccess, isLoading: isLoadingApi, isError, error }] =
		useLazyGetExportServerInfoQuery();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onClickClose = () => {
		dispatch(updateOpenModalExportServer(false));
		setFileName("");
	};

	const onChangeFileName = (newValue: string) => {
		setFileName(newValue);
	};

	const initData = () => {
		showIsLoading();
	};

	const extractFileName = (contentDisposition: string) => {
		const match = contentDisposition.match(/filename="(.+)"/);
		return match ? match[1] : "exported_file.dat";
	};

	const handleDownload = async () => {
		try {
			const token = await GETJwtToken();
			const result = await getExportServerInfo({ serverId: server.id, token }).unwrap();

			if (!result || !result.blob) {
				throw new Error("no valid file was received.");
			}

			const finalFileName = fileName.trim() !== "" ? `${fileName}.dat` : extractFileName(result.contentDisposition);
			const blob = new Blob([result.blob], { type: "application/octet-stream" });

			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = finalFileName;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		} catch (err) {
			// GlobalAlert display the error
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, [openModalExportServer]);

	useEffect(() => {
		onClickClose();
	}, [isSuccess, isError]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='550' placement='right' onClose={onClickClose} closable={false} open={openModalExportServer}>
			<Spin spinning={isLoading || isLoadingApi} tip={t("general.loading")}>
				{/** ---------------------------------------------------- */}
				{/** SERVER INFORMATION  */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faDownload} className='generalStyles__info generalStyles__mrFix' />
							{t("edgeManagement.servers.exportData")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme} row`}>
					{/** NAME  */}
					<div className='col-md-12'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='drawer__text'>{t("edgeManagement.servers.exportFileName")}</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									className='generalStyles__input'
									onChange={(event) => onChangeFileName(event.target.value)}
									value={fileName}
									size='small'
									placeholder={t("edgeManagement.servers.defaultFileName", { name: server.name })}
								/>
							</div>
							<Tooltip title={t("edgeManagement.servers.fileNameOptional")} placement='left'>
								<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__infoTooltipIcon' />
							</Tooltip>
						</div>
					</div>
				</div>
				{/** ---------------------------------------------------- */}
				<div className='mt-4'>
					<div>
						<Button type='default' onClick={onClickClose} className='buttonStyle__3'>
							{t("edgeManagement.close")}
						</Button>
						<Button onClick={handleDownload} disabled={isLoadingApi} type='primary' className='buttonStyle__3'>
							{t("general.download")}
						</Button>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2 isError={isError} isSuccess={isSuccess} requestType='GET' error={error} name='Sever' />
		</Drawer>
	);
}

export default ExportFileComponent;
