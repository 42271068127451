import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetBanksLightQuery } from "../../../redux/banks/bankAPI";
import {
	updateFilters,
	updateBanks,
	updateIdBankPositionCero,
	updateServersGroups,
} from "../../../redux/reports/reportsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";
import { BankInterface } from "../../../interfaces/Bank.interface";

interface ReportsFilterGetBanksProps {
	bankSearchAndFilterString: string;
}

function ReportsFilterGetBanks({ bankSearchAndFilterString }: ReportsFilterGetBanksProps) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option, OptGroup } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters, banks, serversGroups } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetBanks, { data, isError, isLoading }] = useLazyGetBanksLightQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initialData = async () => {
		const token = await GETJwtToken();
		triggerGetBanks({
			page: 1,
			limit: 9999,
			token,
			searchAndFilterString: bankSearchAndFilterString,
		});
	};

	const onChangeBank = (newValue: string) => {
		const index = banks.findIndex((object: any) => object.id === newValue);
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		if (banks && banks[index]) {
			copy.bankId = banks[index].id;
			copy.bankName = banks[index].name;
			if (banks[index].channel && banks[index].channel.server) {
				copy.serverId = banks[index].channel.server.id;
				copy.serverName = banks[index].channel.server.name;
				if (banks[index].channel.server.org) {
					copy.organizationId = banks[index].channel.server.org.id;
					copy.organizationName = banks[index].channel.server.org.name;
				}
			}
		}
		dispatch(updateFilters(copy));
	};

	const callFirstBank = () => {
		if (banks && banks[0] && banks[0].id) {
			onChangeBank(banks[0].id);
		}
	};

	const generateGroupsServers = (response: BankInterface[]) => {
		const newGroups: any = [];
		response.map((item: BankInterface) => {
			if (item && item.channel && item.channel.server && item.channel.server.id && item.channel.server.name) {
				newGroups.push({
					name: item.channel.server.name,
					id: item.channel.server.id,
				});
			}
			return true;
		});
		// Sort by name
		newGroups.sort((a: any, b: any) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		// Remove repeat items
		const uniqueArray = newGroups.filter(
			(item: any, index: any, self: any) => index === self.findIndex((obj: any) => obj.id === item.id)
		);
		//
		dispatch(updateServersGroups(uniqueArray));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		initialData();
	}, []);

	useEffect(() => {
		if (data && data.data && data.data.length > 0) {
			dispatch(updateBanks(data.data));
			dispatch(updateIdBankPositionCero(data.data[0].id));
			generateGroupsServers(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			dispatch(updateBanks([]));
		}
	}, [isError]);

	useEffect(() => {
		callFirstBank();
	}, [banks]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			<Tooltip title={banks.length === 0 ? t("report.noBanks") : ""}>
				<Select
					className='generalStyles__width100'
					disabled={banks.length === 0 || blockFilters}
					showSearch
					optionFilterProp='children'
					placeholder={t("report.selectBank")}
					value={filters.bankId === "" ? null : filters.bankId}
					size='small'
					onChange={onChangeBank}
					loading={isLoading}
				>
					{serversGroups.map((group: any) => (
						<OptGroup key={group.id} label={`SERVER / ${group.name}`}>
							{banks
								.filter(
									(bank: BankInterface) =>
										bank &&
										bank.channel &&
										bank.channel.server &&
										bank.channel.server.id &&
										bank.channel.server.id === group.id
								)
								.map((server: BankInterface) => (
									<Option key={server.id} value={server.id}>
										{server.name}
									</Option>
								))}
						</OptGroup>
					))}
				</Select>
			</Tooltip>
		</div>
	);
}

export default ReportsFilterGetBanks;
