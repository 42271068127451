import React from "react";
// ANT DESIGN COMPONENTS
import { Tooltip } from "antd";
// REDUX
import { useSelector } from "react-redux";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// COMPONENTS
import MonitoringLiftElevatorFullViewIcon from "./MonitoringLiftElevatorFullViewIcon";
import MonitoringElevatorFullViewName from "./MonitoringElevatorFullViewName";
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import {
	MonitoringElevatorsFullViewInterface,
	LiftInterface,
	BankFloorInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function MonitoringElevatorsFullView({
	layout,
	socketMessage,
	elevatorFirstStateIcon,
}: MonitoringElevatorsFullViewInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const checkHallCalls = (floorNo: number) => {
		let hasHallCall = false;
		if (
			socketMessage.hall_calls.up.front.includes(floorNo) ||
			socketMessage.hall_calls.up.rear.includes(floorNo) ||
			socketMessage.hall_calls.down.front.includes(floorNo) ||
			socketMessage.hall_calls.down.rear.includes(floorNo)
		) {
			hasHallCall = true;
		}
		return hasHallCall;
	};

	const checkUpDownHallCall = (floorNo: number) => {
		if (socketMessage.hall_calls.up.front.includes(floorNo) || socketMessage.hall_calls.up.rear.includes(floorNo)) {
			return true;
		}
		return false;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{layout && layout.bank_floors && (
				<div className='monitoring__elevatorFullView__container' style={{ marginTop: "-20px" }}>
					{layout.bank_floors.map((floor: BankFloorInterface, floorIndex: number) => (
						<div key={floorIndex}>
							<div className='generalStyles__flex' key={floorIndex}>
								{/** *********************************************************** */}
								{/** FLOORS */}
								<div
									className={`monitoring__card__floor generalStyles__mlFix ${
										layout && checkHallCalls(floor.floor_no)
											? `${
													checkUpDownHallCall(floor.floor_no)
														? `monitoring__card__floor__active__green__${theme}`
														: `monitoring__card__floor__active__red__${theme}`
											  } `
											: `${
													floor.front_door
														? `monitoring__card__floor__door__${theme}`
														: `monitoring__card__floor__inactive__${theme}`
											  }`
									}`}
								>
									{(socketMessage &&
										socketMessage.hall_calls &&
										socketMessage.hall_calls.up &&
										socketMessage.hall_calls.up.front_lock &&
										socketMessage.hall_calls.up.front_lock.includes(floor.floor_index)) ||
									(socketMessage &&
										socketMessage.hall_calls &&
										socketMessage.hall_calls.down &&
										socketMessage.hall_calls.down.front_lock &&
										socketMessage.hall_calls.down.front_lock.includes(floor.floor_index)) ? (
										<Tooltip title={t("general.frontDoorLocked")}>
											<div>
												<IconDefinitionIcons
													isMap={false}
													expanded={false}
													liftType='ELEVATOR'
													icon='elLocked'
													iconSize={13}
												/>
											</div>
										</Tooltip>
									) : (
										<span>{(floor && floor.name.toUpperCase().substring(0, 2)) || floor.floor_no || "--"}</span>
									)}
								</div>
								{/** *********************************************************** */}
								{/** DOTS AND ELEVATOR CONTAINER */}
								<div className='monitoring__elevatorFullView__dotsContainer'>
									{/** LIFTS */}
									{layout && layout.lifts && (
										<div className='generalStyles__spaceEvenly monitoring__elevatorFullView__liftContainer'>
											{layout.lifts
												.slice() // Create a shallow copy to avoid mutating the original array
												.sort((a, b) => {
													// Compare values based on a specific property (e.g., lift.name)
													if (a.position < b.position) return -1;
													if (a.position > b.position) return 1;
													return 0;
												})
												.map((lift: LiftInterface, liftIndex: number) => (
													<div key={liftIndex}>
														<MonitoringLiftElevatorFullViewIcon
															lift={lift}
															socketMessage={socketMessage}
															floorIndex={floorIndex}
															totalFloors={layout.bank_floors.length}
															floor={floor}
															stops={layout.bank_floors.length}
															elevatorFirstStateIcon={elevatorFirstStateIcon}
															startFloorNo={layout.start_floor_no}
														/>
													</div>
												))}
										</div>
									)}
									{/** DOTS STYLE */}
									<div className='monitoring__elevatorFullView__dotsStyleContainer'>
										<div
											className={`${
												layout && layout.floorsInUse && layout.floorsInUse.includes(floor.floor_no)
													? `monitoring__card__dotStyle__${theme}__active`
													: `monitoring__card__dotStyle__${theme}__inactive`
											}`}
										/>
									</div>
									{/** UP AND DOWN HALL CALLS DOTS STYLE */}
									<div className='monitoring__elevatorFullView__dotsStyleContainer'>
										<div
											className={`${
												checkHallCalls(floor.floor_no)
													? `${
															checkUpDownHallCall(floor.floor_no)
																? "monitoring__card__dotStyle__hallCalls__green"
																: "monitoring__card__dotStyle__hallCalls__red"
													  }`
													: ``
											}`}
										/>
									</div>
								</div>
								{/** *********************************************************** */}
								{/** FLOORS */}
								<div
									className={`monitoring__card__floor ${
										layout && checkHallCalls(floor.floor_no)
											? `${
													checkUpDownHallCall(floor.floor_no)
														? `monitoring__card__floor__active__green__${theme}`
														: `monitoring__card__floor__active__red__${theme}`
											  } `
											: `${
													floor.rear_door
														? `monitoring__card__floor__door__${theme}`
														: `monitoring__card__floor__inactive__${theme}`
											  }`
									}`}
								>
									{(socketMessage &&
										socketMessage.hall_calls &&
										socketMessage.hall_calls.up &&
										socketMessage.hall_calls.up.rear_lock &&
										socketMessage.hall_calls.up.rear_lock.includes(floor.floor_index)) ||
									(socketMessage &&
										socketMessage.hall_calls &&
										socketMessage.hall_calls.down &&
										socketMessage.hall_calls.down.rear_lock &&
										socketMessage.hall_calls.down.rear_lock.includes(floor.floor_index)) ? (
										<Tooltip title={t("general.rearDoorLocked")}>
											<div>
												<IconDefinitionIcons
													isMap={false}
													expanded={false}
													liftType='ELEVATOR'
													icon='elLocked'
													iconSize={13}
												/>
											</div>
										</Tooltip>
									) : (
										<span>{(floor && floor.name.toUpperCase().substring(0, 2)) || floor.floor_no || "--"}</span>
									)}
								</div>
							</div>
							{floorIndex + 1 === layout.bank_floors.length && (
								<div className='generalStyles__flex'>
									{/** *********************************************************** */}
									{/** FLOORS */}
									<div className='monitoring__card__floor generalStyles__mlFix'>
										<span />
									</div>
									{/** *********************************************************** */}
									{/** DOTS AND ELEVATOR CONTAINER */}
									<div className='monitoring__elevatorFullView__dotsContainer'>
										{/** LIFTS */}
										{layout && layout.lifts && (
											<div className='generalStyles__spaceEvenly monitoring__elevatorFullView__liftContainer'>
												{layout.lifts
													.slice() // Create a shallow copy to avoid mutating the original array
													.sort((a, b) => {
														// Compare values based on a specific property (e.g., lift.name)
														if (a.position < b.position) return -1;
														if (a.position > b.position) return 1;
														return 0;
													})
													.map((lift: LiftInterface, liftIndex: number) => (
														<div
															style={{
																width: "35px",
																height: "30px",
																textAlign: "center",
															}}
															key={liftIndex}
														>
															<MonitoringElevatorFullViewName
																lift={lift}
																layout={layout}
																socketMessage={lift && lift.id === socketMessage.lift_id ? socketMessage : null}
															/>
														</div>
													))}
											</div>
										)}
									</div>
									{/** *********************************************************** */}
									{/** FLOORS */}
									<div className='monitoring__card__floor'>
										<span />
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default MonitoringElevatorsFullView;
