/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACE
import { ServerInterface } from "../../interfaces/Server.interface";

export interface ServerState {
	server: ServerInterface;
	idServerSelected: String;
	nameServerSelected: String;
	openModalCreateNewServer: boolean;
	openModalEditServer: boolean;
	openModalDeleteServer: boolean;
	openModalExportServer: boolean;
	openModalImportServer: boolean;
	openDrawerServer: boolean;
	serverTableParams: Object;
	serverRefreshTable: boolean;
	serverBanksRefreshTable: boolean;
	serverList: Array<any>;
	serverBreadcrumb: Array<String>;
	serverUpdateBreadcrumb: Object;
	openModalSecretClient: boolean;
	clearFilters: boolean;
}

const initialState: ServerState = {
	server: {
		id: "",
		ip: "",
		name: "",
		next_records_sync_run: "",
		next_server_sync_run: "",
		org_id: "",
		record_sync_size: 0,
		synced_at: "",
		sync_sched: "",
		channels: [],
	},
	idServerSelected: "",
	nameServerSelected: "",
	openModalCreateNewServer: false,
	openModalEditServer: false,
	openModalDeleteServer: false,
	openModalExportServer: false,
	openModalImportServer: false,
	openDrawerServer: false,
	serverTableParams: {
		pagination: {
			current: 1,
			pageSize: 10,
			showSizeChanger: true,
			pageSizeOptions: ["10", "20", "50", "100"],
		},
	},
	serverRefreshTable: false,
	serverBanksRefreshTable: false,
	serverList: [],
	serverBreadcrumb: [],
	serverUpdateBreadcrumb: {
		update: false,
		id: "",
		name: "",
	},
	openModalSecretClient: false,
	clearFilters: false,
};

export const serverSlice = createSlice({
	name: "server",
	initialState,
	reducers: {
		updateServer: (state, action: PayloadAction<ServerInterface>) => {
			state.server = action.payload;
		},
		updateIdServerSelected: (state, action: PayloadAction<String>) => {
			state.idServerSelected = action.payload;
		},
		updateNameServerSelected: (state, action: PayloadAction<String>) => {
			state.nameServerSelected = action.payload;
		},
		updateOpenModalCreateNewServer: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateNewServer = action.payload;
		},
		updateOpenModalEditServer: (state, action: PayloadAction<boolean>) => {
			state.openModalEditServer = action.payload;
		},
		updateOpenModalDeleteServer: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteServer = action.payload;
		},
		updateOpenModalExportServer: (state, action: PayloadAction<boolean>) => {
			state.openModalExportServer = action.payload;
		},
		updateOpenModalImportServer: (state, action: PayloadAction<boolean>) => {
			state.openModalImportServer = action.payload;
		},
		updateOpenDrawerServer: (state, action: PayloadAction<boolean>) => {
			state.openDrawerServer = action.payload;
		},
		updateServerTableParams: (state, action: PayloadAction<Object>) => {
			state.serverTableParams = action.payload;
		},
		updateServerRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.serverRefreshTable = action.payload;
		},
		updateServerBanksRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.serverBanksRefreshTable = action.payload;
		},
		updateServerList: (state, action: PayloadAction<any>) => {
			state.serverList = action.payload;
		},
		updateServerBreadcrumb: (state, action: PayloadAction<any>) => {
			state.serverBreadcrumb = action.payload;
		},
		updateServerUpdateBreadcrumb: (state, action: PayloadAction<Object>) => {
			state.serverUpdateBreadcrumb = action.payload;
		},
		updateOpenModalSecretClient: (state, action: PayloadAction<boolean>) => {
			state.openModalSecretClient = action.payload;
		},
		updateClearFilters: (state, action: PayloadAction<boolean>) => {
			state.clearFilters = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateServer,
	updateIdServerSelected,
	updateNameServerSelected,
	updateOpenModalCreateNewServer,
	updateOpenModalEditServer,
	updateOpenModalDeleteServer,
	updateOpenModalExportServer,
	updateOpenModalImportServer,
	updateOpenDrawerServer,
	updateServerTableParams,
	updateServerRefreshTable,
	updateServerBanksRefreshTable,
	updateServerList,
	updateServerBreadcrumb,
	updateServerUpdateBreadcrumb,
	updateOpenModalSecretClient,
	updateClearFilters,
} = serverSlice.actions;

export default serverSlice.reducer;
