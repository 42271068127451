import React, { useState, useEffect } from "react";
// QUEUE HOOK
import { useQueue } from "@uidotdev/usehooks";
// GRID LAYOUT
import "react-grid-layout/css/styles.css";
import GridLayout from "react-grid-layout";
// ANT DESIGN COMPONENTS
import { Spin, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateBank } from "../../../redux/banks/bankSlice";
import {
	updateDetailViewLayout,
	updateTopViewLayout,
	updateStatusViewLayout,
	updateServiceViewLayout,
	updateIconDefinitionElevator,
	updateIconDefinitionEscalator,
	updateIconDefinitionMovingwalk,
	updateOpenModalControlPanelBank,
	updateLayoutSelected,
	updateControlPanelDrawerPlacement,
} from "../../../redux/monitoring/monitoringSlice";
// REDUX
import { useLazyGetIconDefinitionsQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// GRID RANGES
import gridRanges from "./GridRanges";
// COMPONENTS
import MonitoringBank from "./MonitoringBank";
import MonitoringWebSocketConnection from "../socketConnection/MonitoringWebSocketConnection";
import MonitoringWebSocketConnectionEmulator from "../socketConnection/MonitoringWebSocketConnectionEmulator";
import MonitoringWebSocketConnectionOffline from "../socketConnection/MonitoringWebSocketConnectionOffline";
import TerminalPage from "../terminal/TerminalPage";
import MonitoringHeartBeat from "./MonitoringHeartBeat";
import ControlPanel from "../ControlPanel/ControlPanel";
import ControlPanelBank from "../ControlPanelBank/ControlPanelBank";
import ControlPanelBankActions from "../ControlPanel/ControlPanelActions";
import MonitoringAppFocus from "./MonitoringAppFocus";
// AUTHORIZATION
import { MapViewInterface } from "../../../interfaces/MapView.interface";
import GETJwtToken from "../../../redux/authentication/authentication";
import { useLazyGetMapViewQuery } from "../../../redux/mapView/mapViewAPI";
import { updateMapViewArray, updateMapViewItem, updateSelectedOrganization } from "../../../redux/mapView/mapViewSlice";
// INTERFACES
import { LayoutInterface, ServerSocketConnectionInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
import MonitoringMapView from "../mapView/MonitoringMapView";
import { BankInterface } from "../../../interfaces/Bank.interface";

const MonitoringGrid = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const liftsAllowedPerColumn = 3;
	const baseLayoutGrid = 120;
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const READ_FREQUENCY = 10;

	// ************************************************ */
	// QUEE HOOK ************************************** */
	const { add, size, first, remove, clear } = useQueue<any>([]);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [layouts, setLayouts] = useState<LayoutInterface[]>([
		{
			value: "",
			label: "",
			children: [],
			type: "",
			idOrganization: "",
			idServer: "",
			idChannel: "",
			idBank: "",
			nameOrganization: "",
			nameServer: "",
			nameChannel: "",
			typeChannel: "",
			id: "",
			active: false,
			board: 0,
			columns: 0,
			floor_below_lobby: 0,
			floor_count: 0,
			logical_port: 0,
			name: "",
			number_of_lifts: 0,
			channel_id: "",
			rear_doors: false,
			stops: 0,
			control_panel: {},
			bank_floors: [],
			lifts: [],
			floorsInUse: [],
			i: "",
			x: 0,
			y: 0,
			w: 0,
			h: 0,
			l: 0,
			f: 0,
			floor: 0,
			start_floor_no: 1,
		},
	]);
	const [mainContainerWidth, setMainContainerWidth] = useState(0);
	const [gridBanks, setGridBanks] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [colWidh, setColWidth] = useState(102);
	const [colHeight, setColHeight] = useState(90);
	const [mapViewMultipleOrgsError, setMapViewMultipleOrgsError] = useState(false);
	const [serverSocketConnections, setServerSocketConnections] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { organizationSelectMultipleData } = useSelector((state: RootState) => state.organization);
	const {
		enableDragAndDrop,
		refreshPage,
		topViewLayout,
		detailViewLayout,
		statusViewLayout,
		serviceViewLayout,
		monitoringGridType,
		clearQueue,
		monitorViewIsLoading,
		socketConnection,
		openGlobalTerminal,
		openModalControlPanel,
		openModalControlPanelBank,
		terminalIdLiftSelected,
		terminalShowAllMessages,
	} = useSelector((state: RootState) => state.monitoring);
	const { theme, userLogged } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetIconDefinition, { data: dataIconDefinition }] = useLazyGetIconDefinitionsQuery();
	const [triggerGetMapView, { data: dataGetMapView }] = useLazyGetMapViewQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetIconDefinition({
			token,
		});
	};

	const getMapViewData = async (orgId: string) => {
		const token = await GETJwtToken();
		const BODY: any = {
			org_id: orgId,
		};
		triggerGetMapView({
			token,
			body: BODY,
		});
	};

	const buildIconDefinitionObject = (data: any) => {
		const newObjectElevator: any = {};
		const newObjectEscalator: any = {};
		const newObjectMovingwalk: any = {};
		data.map((item: any) => {
			if (item.lift_type === "ELEVATOR") {
				newObjectElevator[item.short_message] = item.icon;
			} else if (item.lift_type === "ESCALATOR") {
				newObjectEscalator[item.short_message] = item.icon;
			} else if (item.lift_type === "MOVINGWALK") {
				newObjectMovingwalk[item.short_message] = item.icon;
			}
			return true;
		});
		dispatch(updateIconDefinitionElevator(newObjectElevator));
		dispatch(updateIconDefinitionEscalator(newObjectEscalator));
		dispatch(updateIconDefinitionMovingwalk(newObjectMovingwalk));
	};
	const displayIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const layoutChange = (newLayout: any) => {
		const copy = structuredClone(newLayout);
		if (monitoringGridType === "TOPVIEW") {
			dispatch(updateTopViewLayout(copy));
		} else if (monitoringGridType === "STATUSVIEW") {
			dispatch(updateStatusViewLayout(copy));
		} else if (monitoringGridType === "FULLVIEW") {
			dispatch(updateDetailViewLayout(copy));
		} else if (monitoringGridType === "SERVICEVIEW") {
			dispatch(updateServiceViewLayout(copy));
		}
	};

	const calculateCardWidth = (
		totalLifts: number,
		liftsAllowed: number,
		bankType: string,
		actions: any,
		gridType: string
	) => {
		if (gridType === "SERVICEVIEW") {
			return 3;
		}
		if (bankType === "GPIO") {
			let hasToggle = false;
			let hasHolback = false;
			let hasTurnbak = false;
			actions.map((action: any) => {
				if (action && action.button_type && action.button_type === "TOGGLE") {
					hasToggle = true;
				}
				if (action && action.button_type && action.button_type === "HOLDBACK") {
					hasHolback = true;
				}
				if (action && action.button_type && action.button_type === "TURNBACK") {
					hasTurnbak = true;
				}
				return true;
			});
			return (hasToggle ? 4 : 0) + (hasHolback ? 4 : 0) + (hasTurnbak ? 4 : 0);
		}
		if (totalLifts <= 3) {
			return 2;
		}
		return Math.ceil(totalLifts / liftsAllowed) + 2;
	};

	const calculateCardHeight = (
		totalFloors: number,
		totalLifts: number,
		bankType: string,
		gridType: string,
		actions: any
	) => {
		let cardHeight = 1;
		// *********************************
		if (bankType === "GPIO") {
			let totalToggle = 0;
			let totalHoldback = 0;
			let totalTurnback = 0;
			actions.map((action: any) => {
				if (action && action.button_type === "TOGGLE") {
					totalToggle += 1;
				} else if (action && action.button_type === "HOLDBACK") {
					totalHoldback += 1;
				} else if (action && action.button_type === "TURNBACK") {
					totalTurnback += 1;
				}
				return true;
			});
			if (totalToggle >= totalHoldback && totalToggle >= totalTurnback) {
				return 4 + totalToggle / 2;
			}
			const maxActionsPerType = Math.max(totalHoldback, totalTurnback);
			return 3 + maxActionsPerType / 3;
		}
		//
		if (gridType === "SERVICEVIEW") {
			if (totalLifts <= 3) {
				return 2;
			}
			return totalLifts / 2;
		}
		//
		if (gridType === "STATUSVIEW" || gridType === "TOPVIEW" || bankType === "ESCALATOR" || bankType === "MOVINGWALK") {
			return 2;
		}
		if (totalLifts === 0) {
			cardHeight = 2;
		} else {
			gridRanges.data.map((range: any) => {
				if (totalFloors >= range.minFloors && totalFloors <= range.maxFloors) {
					cardHeight = range.cardHeight;
				}
				return true;
			});
		}
		return cardHeight;
	};

	const updateFormSelectSavedGrid = (newArr: any, oldLayout: any) => {
		const copy = structuredClone(newArr);
		copy.map((item: any, indexItem1: number) => {
			oldLayout.map((item2: any) => {
				if (item.value === item2.i) {
					copy[indexItem1].x = Math.ceil(item2.x);
					copy[indexItem1].y = Math.ceil(item2.y);
					copy[indexItem1].w = Math.ceil(item2.w);
					copy[indexItem1].h = Math.ceil(item2.h);
				}
				return true;
			});
			return true;
		});
		setLayouts(copy);
	};

	const generateInitialGrid = (widthContainer: number, containerWidth: number) => {
		displayIsLoading();
		let counterX = 0;
		let counterY = 0;
		const newArr: LayoutInterface[] = [];
		const banksCopy = structuredClone(gridBanks);
		banksCopy.sort(
			(a: any, b: any) =>
				parseFloat(a.bank && a.bank.floor_count ? a.bank.floor_count : 0) -
				parseFloat(b.bank && b.bank.floor_count ? b.bank.floor_count : 0)
		);
		banksCopy.map((bank: any) => {
			const totalLifts = bank && bank.lifts ? bank.lifts.length : 0;
			const totalFloors = bank && bank.floor_count ? bank.floor_count : 0;
			// Sort floors
			let floorsSorted = [];
			if (bank && bank.bank_floors) {
				floorsSorted = bank.bank_floors.sort((a: any, b: any) => b.floor_no - a.floor_no);
			}
			// Create floors in use array
			// eslint-disable-next-line camelcase
			const floorsInUse: any = [];
			//
			newArr.push({
				...bank,
				bank_floors: floorsSorted,
				floorsInUse,
				i: bank.id,
				x: Math.ceil(counterX),
				y: counterY,
				w: calculateCardWidth(
					totalLifts,
					liftsAllowedPerColumn,
					bank.type,
					bank && bank.actions ? bank.actions : [],
					monitoringGridType
				),
				h: calculateCardHeight(
					totalFloors,
					totalLifts,
					bank.type,
					monitoringGridType,
					bank && bank.actions ? bank.actions : []
				),
				l: totalLifts,
				f: totalFloors,
				floor: 3,
				start_floor_no: bank.start_floor_no,
			});
			if (monitoringGridType === "FULLVIEW") {
				counterX = Math.ceil(totalLifts / liftsAllowedPerColumn) + (counterX === 0 ? 2 : 1) + counterX;
				if (counterX > Math.floor(widthContainer / baseLayoutGrid)) {
					counterX = 0;
					counterY += 1;
				}
			} else if (monitoringGridType === "SERVICEVIEW") {
				counterX = Math.ceil(totalLifts / liftsAllowedPerColumn) + (counterX === 0 ? 2 : 1) + counterX;
				if (counterX > Math.floor(widthContainer / baseLayoutGrid)) {
					counterX = 0;
					counterY += 1;
				}
			} else if (monitoringGridType === "STATUSVIEW" || monitoringGridType === "TOPVIEW") {
				const maxColumnsInTotalScreen = Math.ceil(containerWidth / 120);
				counterX += Math.ceil(totalLifts <= 3 ? 2 : Math.ceil(totalLifts / liftsAllowedPerColumn) + 2);
				if (counterX > maxColumnsInTotalScreen) {
					counterX = 0;
					counterY += 1;
				}
			}
			return true;
		});
		if (topViewLayout && topViewLayout.length > 0 && monitoringGridType === "TOPVIEW") {
			updateFormSelectSavedGrid(newArr, topViewLayout);
		} else if (detailViewLayout && detailViewLayout.length > 0 && monitoringGridType === "FULLVIEW") {
			updateFormSelectSavedGrid(newArr, detailViewLayout);
		} else if (statusViewLayout && statusViewLayout.length > 0 && monitoringGridType === "STATUSVIEW") {
			updateFormSelectSavedGrid(newArr, statusViewLayout);
		} else if (serviceViewLayout && serviceViewLayout.length > 0 && monitoringGridType === "SERVICEVIEW") {
			updateFormSelectSavedGrid(newArr, serviceViewLayout);
		} else {
			setLayouts(newArr);
		}
	};

	const getDrawerPlacement = (e: any) => {
		const clickX = e.clientX;
		const screenWidth = window.innerWidth;
		if (clickX > screenWidth / 2) {
			dispatch(updateControlPanelDrawerPlacement("left"));
		} else {
			dispatch(updateControlPanelDrawerPlacement("right"));
		}
	};

	const onClickOpenDrawerBank = (e: any, layout: LayoutInterface) => {
		if (layout) {
			const bank: BankInterface = {
				id: layout.id || "--",
				name: layout.name || "--",
				type: layout.type || "--",
				channel_id: layout.idChannel || "--",
				server_id: layout.idServer || "--",
				columns: layout.columns || 0,
				stops: layout.stops || 0,
				number_of_lifts: layout.l || 0,
				logical_port: layout.logical_port || 0,
				floor_count: layout.floor_count || 0,
				floor_below_lobby: layout.floor_below_lobby || 0,
				board: layout.board || 0,
				rear_doors: layout.rear_doors || false,
				active: layout.active || false,
				bank_floors: layout.bank_floors || [],
				lifts: layout.lifts || [],
				actions: layout.actions || [],
				start_floor_no: 1,
				interface: {},
			};
			getDrawerPlacement(e);
			dispatch(updateBank(bank));
			dispatch(updateLayoutSelected(layout));
			dispatch(updateOpenModalControlPanelBank(true));
		}
	};

	MonitoringAppFocus(() => {
		clear();
	});

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		const elementId = document.getElementById("mainContainer");
		if (elementId && gridBanks && gridBanks.length > 0) {
			setMainContainerWidth(elementId.clientWidth);
			generateInitialGrid(elementId.clientWidth, elementId.clientWidth);
		}
	}, [gridBanks]);

	useEffect(() => {
		if (organizationSelectMultipleData && organizationSelectMultipleData.length > 0) {
			setGridBanks(organizationSelectMultipleData);
			if (monitoringGridType === "MAPVIEW") {
				if (userLogged.role.name !== "MASTER ADMIN") {
					setMapViewMultipleOrgsError(false);
					getMapViewData(userLogged.org_id);
					dispatch(updateSelectedOrganization(userLogged.org_id));
					return;
				}
				const idOrganizationsArr = organizationSelectMultipleData
					.map((i) => i.idOrganization)
					// get an array with strings of unique values
					.filter((value, index, self) => self.indexOf(value) === index);
				if (idOrganizationsArr.length > 1) {
					setMapViewMultipleOrgsError(true);
				} else if (!idOrganizationsArr[0]) {
					setMapViewMultipleOrgsError(true);
				} else {
					setMapViewMultipleOrgsError(false);
					getMapViewData(idOrganizationsArr[0]);
					dispatch(updateSelectedOrganization(idOrganizationsArr[0]));
				}
			}
		} else {
			setGridBanks([]);
			setLayouts([]);
		}
	}, [organizationSelectMultipleData, monitoringGridType]);

	useEffect(() => {
		const startMapView = dataGetMapView?.data?.find((m: MapViewInterface) => m.start === true);
		dispatch(updateMapViewItem(startMapView || dataGetMapView?.data[0]));
		dispatch(updateMapViewArray(dataGetMapView?.data));
	}, [dataGetMapView]);

	useEffect(() => {
		if (refreshPage) {
			displayIsLoading();
		}
	}, [refreshPage]);

	useEffect(() => {
		if (clearQueue) {
			clear();
		}
	}, [clearQueue]);

	useEffect(() => {
		const elementId = document.getElementById("mainContainer");
		if (elementId && gridBanks && gridBanks.length > 0) {
			generateInitialGrid(elementId.clientWidth, elementId.clientWidth);
		}
		//
		if (monitoringGridType === "TOPVIEW") {
			setColHeight(72);
			setColWidth(102);
		} else if (monitoringGridType === "STATUSVIEW") {
			setColHeight(40);
			setColWidth(75);
		} else if (monitoringGridType === "FULLVIEW") {
			setColHeight(90);
			setColWidth(102);
		} else if (monitoringGridType === "SERVICEVIEW") {
			setColHeight(100);
			setColWidth(100);
		} else if (monitoringGridType === "MAPVIEW") {
			setColHeight(90);
			setColWidth(124);
		}
	}, [monitoringGridType]);

	useEffect(() => {
		setServerSocketConnections([]);
		if (layouts && layouts.length > 0) {
			const serversId: ServerSocketConnectionInterface[] = [];
			const unikeServerId: string[] = [];
			const firstLiftByBank: any = [];
			layouts.map((layout: LayoutInterface) => {
				if (layout && layout.lifts && layout.lifts[0]) {
					firstLiftByBank.push({
						serverId: layout.idServer,
						lift: layout.lifts[0],
					});
				}
				if (!unikeServerId.includes(layout.idServer)) {
					serversId.push({
						id: layout.idServer,
						name: layout.nameServer,
						channelType: layout.typeChannel,
						firstLiftId: [],
					});
					unikeServerId.push(layout.idServer);
				}
				return true;
			});
			const copy: any = structuredClone(serversId);
			copy.map((item: any, index: number) => {
				const newArray: any = [];
				firstLiftByBank.map((item2: any) => {
					if (item2 && item2.serverId && item && item.id && item.id === item2.serverId) {
						newArray.push(item2.lift);
					}
					return true;
				});
				copy[index].firstLiftId = newArray;
				return true;
			});
			setServerSocketConnections(copy);
		}
	}, [layouts]);

	useEffect(() => {
		if (dataIconDefinition && dataIconDefinition.data) {
			buildIconDefinitionObject(dataIconDefinition.data);
		}
	}, [dataIconDefinition]);

	useEffect(() => {
		clear();
	}, [openModalControlPanel, openModalControlPanelBank]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoading || monitorViewIsLoading}>
			{/** *********************************************************** */}
			{/** EDGE SERVER CONNECTION */}
			{process.env.REACT_APP_ENVIRONMENT !== "OFFLINE" && (
				<>
					{socketConnection === "EDGE" && (
						<div className='generalStyles__flexWrap'>
							{serverSocketConnections.map((serverSocketConnection: ServerSocketConnectionInterface) => (
								<div key={serverSocketConnection.id}>
									<MonitoringWebSocketConnection
										size={size}
										add={add}
										remove={remove}
										clear={clear}
										readFrequency={READ_FREQUENCY}
										serverId={serverSocketConnection.id}
										serverName={serverSocketConnection.name}
										channelType={serverSocketConnection.channelType}
										firstLiftId={serverSocketConnection.firstLiftId}
									/>
								</div>
							))}
						</div>
					)}
				</>
			)}
			{/** *********************************************************** */}
			{/** OFFLINE SERVER CONNECTION */}
			{process.env.REACT_APP_ENVIRONMENT === "OFFLINE" && (
				<>
					{socketConnection === "OFFLINE" && (
						<div className='generalStyles__flexWrap'>
							{serverSocketConnections.map((serverSocketConnection: ServerSocketConnectionInterface) => (
								<div key={serverSocketConnection.id}>
									<MonitoringWebSocketConnectionOffline
										size={size}
										add={add}
										remove={remove}
										clear={clear}
										readFrequency={READ_FREQUENCY}
										serverId={serverSocketConnection.id}
										serverName={serverSocketConnection.name}
									/>
								</div>
							))}
						</div>
					)}
				</>
			)}
			{/** *********************************************************** */}
			{/** EMULATOR CONNECTION */}
			{process.env.REACT_APP_ENVIRONMENT !== "OFFLINE" && (
				<>
					{socketConnection === "EMULATOR" && (
						<div className='generalStyles__flexWrap'>
							<MonitoringWebSocketConnectionEmulator
								size={size}
								add={add}
								remove={remove}
								clear={clear}
								serverName='311 Wacker'
								readFrequency={READ_FREQUENCY}
							/>
						</div>
					)}
				</>
			)}
			{/** *********************************************************** */}
			{/** MONITORING GRID */}
			{!openGlobalTerminal ? (
				<div id='mainContainer' className='monitoring__100pxBox'>
					{mainContainerWidth > 0 && layouts && layouts.length > 0 && (
						<div>
							{monitoringGridType === "MAPVIEW" ? (
								<>
									{mapViewMultipleOrgsError ? (
										<div className='generalStyles__flexCenter mt-3'>
											<span className='mt-4'>{t("monitoring.mapViewMultipleOrgsError")}</span>
										</div>
									) : (
										<MonitoringMapView lastSocketMessage={first} layouts={layouts} />
									)}
								</>
							) : (
								<GridLayout
									className='layout'
									layout={layouts}
									onLayoutChange={layoutChange}
									cols={mainContainerWidth / colWidh}
									rowHeight={colHeight}
									autoSize
									width={mainContainerWidth}
									isResizable={false}
									margin={[20, 20]}
									containerPadding={[20, 20]}
									isDraggable={enableDragAndDrop}
								>
									{layouts.map((layout: LayoutInterface) => (
										<div className={`monitoring__card__${theme}`} id={layout.i} key={layout.i}>
											<div className='monitoring__card__container'>
												{/** *********************************************************** */}
												{/** HEADER */}
												<div className='monitoring__card__header'>
													<div className='generalStyles__flex'>
														<MonitoringHeartBeat
															socketMessage={
																layout && layout.id && first && first.bank_id && first.bank_id === layout.id
																	? first
																	: undefined
															}
														/>
														<div
															className='generalStyles__textEllipsis'
															role='button'
															tabIndex={0}
															onKeyDown={() => {}}
															onClick={(e: any) => {
																if (!enableDragAndDrop) {
																	onClickOpenDrawerBank(e, layout);
																}
															}}
														>
															<Tooltip placement='topLeft' title={layout.name}>
																<h6>{layout.name}</h6>
															</Tooltip>
														</div>
													</div>
												</div>
												{/** *********************************************************** */}
												{/** BODY */}
												{layout.type === "GPIO" ? (
													<div style={{ padding: "10px" }}>
														<ControlPanelBankActions
															carService=''
															liftId={
																layout && layout.lifts && layout.lifts[0] && layout.lifts[0].id
																	? layout.lifts[0].id
																	: ""
															}
															actions={layout && layout.actions ? layout.actions : null}
															serverId={layout && layout.idServer ? layout.idServer : ""}
															layout={layout}
															isControlPanel={false}
														/>
													</div>
												) : (
													<>
														{layout && layout.lifts && layout.lifts.length > 0 ? (
															<MonitoringBank
																key={layout.id}
																layout={layout}
																socketMessage={first && first.bank_id && first.bank_id === layout.id ? first : null}
															/>
														) : (
															<div className='generalStyles__flexCenter mt-3'>
																<span className='mt-4'>{t("edgeManagement.lifts.noLiftsFound")}</span>
															</div>
														)}
													</>
												)}
											</div>
										</div>
									))}
								</GridLayout>
							)}
						</div>
					)}
				</div>
			) : (
				<>
					{/** ************************************************** */}
					{/** MONITORING GLOBAL TERMINAL */}
					<TerminalPage
						socketMessage={
							(first && terminalIdLiftSelected !== "" && first.lift_id === terminalIdLiftSelected) ||
							(first && terminalShowAllMessages)
								? first
								: null
						}
						layouts={layouts}
					/>
				</>
			)}
			{/** *********************************************************** */}
			{/** CONTROL PANEL LIFT */}
			<ControlPanel socketMessage={first} />
			{/** *********************************************************** */}
			{/** CONTROL PANEL BANK */}
			<ControlPanelBank socketMessage={first} />
		</Spin>
	);
};

export default MonitoringGrid;
