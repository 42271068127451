/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { MessageInterface } from "../../interfaces/Message.interface";

export interface MessageState {
	message: MessageInterface;
	openDrawer: boolean;
	openForm: boolean;
	showTable: boolean;
	refreshTable: boolean;
	formAction: string;
	openModalDelete: boolean;
}

const initialState: MessageState = {
	message: {
		created_at: "",
		updated_at: "",
		deleted_at: null,
		short_message: "NORMAL",
		description: "",
		keys: [
			{
				created_at: "",
				updated_at: "",
				adapter: null,
				message: null,
				deleted_at: null,
				value: { bit: "1", byte: "1" },
				id: "",
				message_id: "",
				adapter_id: "",
			},
		],
		message_type: {
			created_at: "",
			updated_at: "",
			deleted_at: null,
			name: "CARSERVICE",
			id: 2,
		},
		message_type_id: 2,
		enum_id: 1,
		id: "",
		position: true,
	},
	openDrawer: false,
	openForm: false,
	showTable: false,
	refreshTable: false,
	formAction: "CREATE",
	openModalDelete: false,
};

export const messageSlice = createSlice({
	name: "messageType",
	initialState,
	reducers: {
		updateMessage: (state, action: PayloadAction<MessageInterface>) => {
			state.message = action.payload;
		},
		updateOpenDrawer: (state, action: PayloadAction<boolean>) => {
			state.openDrawer = action.payload;
		},
		updateOpenForm: (state, action: PayloadAction<boolean>) => {
			state.openForm = action.payload;
		},
		updateShowTable: (state, action: PayloadAction<boolean>) => {
			state.showTable = action.payload;
		},
		updateRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.refreshTable = action.payload;
		},
		updateFormAction: (state, action: PayloadAction<string>) => {
			state.formAction = action.payload;
		},
		updateOpenModalDelete: (state, action: PayloadAction<boolean>) => {
			state.openModalDelete = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateMessage,
	updateOpenDrawer,
	updateOpenForm,
	updateShowTable,
	updateRefreshTable,
	updateFormAction,
	updateOpenModalDelete,
} = messageSlice.actions;

export default messageSlice.reducer;
