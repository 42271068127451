import React from "react";
// ANT DESIGN COMPONENTS
import { Button, Tooltip } from "antd";
// MOMENT
import moment from "moment";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
// INTERFACES
import { ButtonSquareDownloadCSVKeysInterface } from "../../../interfaces/Components.interface";
import { RootState } from "../../../app/store";
// UTILS
import { generateExcelAndDownloadDefinitions } from "../../../utils/utils";

function ButtonSquareDownloadCSVKeys({
	interfaceJson,
	fileName,
	data,
	disabledButton,
	isLoading,
}: ButtonSquareDownloadCSVKeysInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickDownloadExcel = () => {
		const currentDateTime = moment().format("YYYY_MMM_DD");
		const configurationName = fileName;
		// eslint-disable-next-line no-useless-escape
		const reportName = `definitions_${configurationName.replace(/[\/\\:\*\?"<>\|\s]/g, "_")}_${currentDateTime}`;
		const customAttributes: any = {
			idMessage: true,
			message: true,
			type: true,
			...Object.fromEntries(Object.entries(interfaceJson).map(([key, value]) => [key, value === "" ? true : value])),
		};
		// Remove all columns not used by attributes and undefined/null items
		const filteredData = data.map((item: any) =>
			Object.keys(item)
				.filter((key: any) => customAttributes[key])
				.reduce((acc: any, key: any) => {
					const value = item[key];
					return { ...acc, [key]: value };
				}, {})
		);
		// Convert boolean values into string
		const filteredData2 = filteredData.map((item: any) =>
			Object.keys(item).reduce((acc: any, key: any) => {
				// Check if the value is a boolean
				// eslint-disable-next-line no-nested-ternary
				acc[key] = typeof item[key] === "boolean" ? (item[key] ? "CLONE" : "") : item[key];
				return acc;
			}, {})
		);
		//
		generateExcelAndDownloadDefinitions(filteredData2, reportName);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Tooltip title={t("general.downloadFile")}>
				<Button
					className={`${
						disabledButton ? `buttonStyle__inactive__1__${theme}` : "buttonStyle__13"
					} generalStyles__mlFix`}
					style={{ marginTop: "-5px" }}
					onClick={onClickDownloadExcel}
					disabled={disabledButton}
					loading={isLoading}
					icon={<FontAwesomeIcon className='' icon={faDownload} />}
				/>
			</Tooltip>
		</div>
	);
}

export default ButtonSquareDownloadCSVKeys;
