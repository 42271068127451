import React, { useEffect, useState, useRef } from "react";
// NAVIGATION
import { useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Tag, Button, InputNumber, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleCheck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { messageAPI } from "../../../redux/messages/messagesAPI";
import { updateBank } from "../../../redux/banks/bankSlice";
import { updateOpenDrawerAssignInterfaceBank } from "../../../redux/keys/keysSlice";
import {
	useLazyGetBankQuery,
	useLazyGetAdapterKeysQuery,
	useDeleteBankKeyMutation,
	usePostBankKeyMutation,
	usePutBankKeyMutation,
	keyAPI,
} from "../../../redux/keys/keysAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { BankInterface } from "../../../interfaces/Bank.interface";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import GlobalAlert2 from "../../home/GlobalAlert2";
import SelectMessageType from "../../components/select/SelectMessageType";
import ButtonSquareSave from "../../components/buttons/ButtonSquareSave";
import ButtonSquareRefresh from "../../components/buttons/ButtonSquareRefresh";
import ButtonSquareUploadCSV from "../../components/buttons/ButtonSquareUploadCSV";
import ButtonSquareDownloadCSV from "../../components/buttons/ButtonSquareDownloadCSVKeys";
import ButtonSquareCode from "../../components/buttons/ButtonSquareCode";
// UTILS
import { findDuplicateIndices, updateSpecificKeys, buildCustomKeysObject, buildAssignedKeysobject } from "./utils";

function KeysBankTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const isFirstRender = useRef(true);
	const { Column } = Table;
	const [t] = useTranslation("global");
	const location = useLocation();
	const bankId = new URLSearchParams(location.search).get("idBank");
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [totalRows, setTotalRows] = useState<number>(0);
	const [errorMessage, setErrorMessage] = useState<any>({});
	const [data, setData] = useState<any>([]);
	const [interfaceKeys, setInterfaceKeys] = useState<string[]>([]);
	const [bank, setBank] = useState<BankInterface>({
		id: "",
		name: "",
		type: "ELEVATOR",
		channel_id: "",
		server_id: "",
		columns: 0,
		stops: 0,
		number_of_lifts: 0,
		logical_port: 0,
		floor_count: 0,
		floor_below_lobby: 0,
		board: 1,
		rear_doors: false,
		active: true,
		control_panel: "",
		lifts: [],
		offsets: [],
		floor_names: [],
		direct_link: "",
		configurations: [],
		data_blocks: [],
		start_floor_no: 1,
		interface: { key: "", value: "", bacnet: "" },
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [messageTypeSelected, setMessageTypeSelected] = useState<string>("ALL");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetBank,
		{
			data: dataGetBank,
			isLoading: isLoadingGetBank,
			isFetching: isFetchingGetBank,
			isError: isErrorGetBank,
			error: errorGetBank,
		},
	] = useLazyGetBankQuery();

	const [
		triggerGetKeysAdapter,
		{
			data: dataGetKeysAdapter,
			isLoading: isLoadingGetKeysAdapter,
			isFetching: isFetchingKeysAdapter,
			isError: isErrorGetKeysAdapter,
			error: errorGetKeysAdapter,
		},
	] = useLazyGetAdapterKeysQuery();

	const [
		triggerDeleteKey,
		{ isLoading: isLoadingDeleteKey, isSuccess: isSuccessDeleteKey, error: errorDeleteKey, isError: isErrorDeleteKey },
	] = useDeleteBankKeyMutation();

	const [
		triggerPostKey,
		{
			isSuccess: isSuccessPostKey,
			isLoading: isLoadingPostKey,
			isError: isErrorPostKey,
			error: errorPostKey,
			reset: resetPostKey,
		},
	] = usePostBankKeyMutation();

	const [
		triggerPutKey,
		{
			isSuccess: isSuccessPutKey,
			isError: isErrorPutKey,
			error: errorPutKey,
			isLoading: isLoadingPutKey,
			reset: resetPutKey,
		},
	] = usePutBankKeyMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const launchIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	};

	const getKeysAssignedAdapter = async (id: string) => {
		const token = await GETJwtToken();
		triggerGetKeysAdapter({
			token,
			adapterId: id,
		});
	};

	const buildFirstData = (bankInterfaceKeys: any, messages: any) => {
		const newData = buildCustomKeysObject(messages, bankInterfaceKeys);
		const duplicateIndices = findDuplicateIndices(newData, interfaceKeys, messageTypeSelected);
		if (duplicateIndices && duplicateIndices.length > 0 && bank && bank.custom_keys) {
			const copy = buildAssignedKeysobject(duplicateIndices, bank.custom_keys);
			setData(copy);
		} else {
			setData(duplicateIndices);
		}
	};

	const getInitialData = async () => {
		if (bankId && bankId !== "") {
			const token = await GETJwtToken();
			dispatch(keyAPI.util.resetApiState());
			dispatch(messageAPI.util.resetApiState());
			await triggerGetBank({ token, bankId }).unwrap();
		}
	};

	const onClickDelete = async (item: any) => {
		const token = await GETJwtToken();
		triggerDeleteKey({ id: item.idKey, token });
	};

	const onChangeInputNumber = (newValue: number, item: string, index: number) => {
		const copy = structuredClone(data);
		copy[index][item] = newValue;
		copy[index].isValid = true;
		const duplicateIndices = findDuplicateIndices(copy, interfaceKeys, messageTypeSelected);
		setData(duplicateIndices);
	};

	const buildAdapterKeys = (adapterKeys: any) => {
		const newMessages: any = [];
		adapterKeys.forEach((item: any) => {
			if (item && item.message) {
				newMessages.push(item.message);
			}
		});
		if (newMessages && newMessages.length > 0) {
			setTotalRows(newMessages.length);
			if (bank && bank.interface && JSON.stringify(bank.interface) !== "{}" && newMessages.length > 0) {
				buildFirstData(bank.interface, newMessages);
				setInterfaceKeys(Object.keys(bank.interface));
			}
		}
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const requests = data.map((item: any) => {
			if (item.isValid) {
				const newValue = interfaceKeys.reduce((acc: any, key: any) => {
					acc[key] = item[key];
					return acc;
				}, {});
				const requestBody = {
					bank_id: bank.id,
					message_id: item.idMessage,
					value: JSON.stringify(newValue),
				};
				return item.methodType === "POST"
					? triggerPostKey({ token, body: requestBody })
					: triggerPutKey({ token, id: item.idKey, body: requestBody });
			}
			return true;
		});
		await Promise.all(requests);
	};

	const setUploadCSVData = (jsonData: any) => {
		const updatedObj1 = updateSpecificKeys(data, jsonData, interfaceKeys);
		const duplicateIndices = findDuplicateIndices(updatedObj1, interfaceKeys, messageTypeSelected);
		setData(duplicateIndices);
	};

	const onChangeMessageType = (newValue: string) => {
		setMessageTypeSelected(newValue);
	};

	const handleOpenAssignInterface = () => {
		dispatch(updateBank(bank));
		dispatch(updateOpenDrawerAssignInterfaceBank(true));
	};

	const refreshPage = () => {
		if (isFirstRender.current) return;
		launchIsLoading();
		getInitialData();
		resetPostKey();
		resetPutKey();
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (dataGetBank && dataGetBank.data && dataGetBank.data.adapter_id) {
			getKeysAssignedAdapter(dataGetBank.data.adapter_id);
		}
	}, [dataGetBank]);

	useEffect(() => {
		if (dataGetBank && dataGetBank.data) {
			setBank(dataGetBank.data);
		}
	}, [dataGetBank]);

	useEffect(() => {
		if (errorGetKeysAdapter || errorGetBank) {
			setErrorMessage(errorGetKeysAdapter || errorGetBank);
		}
	}, [errorGetKeysAdapter, errorGetBank]);

	useEffect(() => {
		if (dataGetKeysAdapter && dataGetKeysAdapter.data) {
			buildAdapterKeys(dataGetKeysAdapter.data);
		}
	}, [dataGetKeysAdapter]);

	useEffect(() => {
		if (isFirstRender.current) return;
		if (isSuccessDeleteKey || isSuccessPostKey || isSuccessPutKey) {
			refreshPage();
		}
	}, [isSuccessDeleteKey, isSuccessPostKey, isSuccessPutKey]);

	useEffect(() => {
		if (isFirstRender.current) return;
		getInitialData();
	}, [messageTypeSelected]);

	useEffect(() => {
		launchIsLoading();
		getInitialData();
		isFirstRender.current = false;
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__pageHeader' style={{ display: "flex", justifyContent: "space-between" }}>
				<div>
					<div className='generalStyles__flex'>
						<h5 className='generalStyles__info'>{bank.name || "--"}</h5>
					</div>
				</div>
				<div className='generalStyles__flex '>
					<div className=' generalStyles__width100'>
						<SelectMessageType setFilteredValue={onChangeMessageType} />
					</div>
					<div>
						<ButtonSquareRefresh onClick={refreshPage} isLoading={isLoading} disabledButton={isLoading} />
					</div>
					<div>
						<ButtonSquareDownloadCSV
							interfaceJson={bank.interface}
							fileName={bank.name}
							data={data}
							disabledButton={isLoading}
							isLoading={isLoading}
						/>
					</div>
					<div>
						<ButtonSquareUploadCSV
							setUploadCSVData={setUploadCSVData}
							isLoading={isLoading}
							disabledButton={isLoading}
						/>
					</div>
					<div>
						<ButtonSquareCode onClick={handleOpenAssignInterface} isLoading={isLoading} disabledButton={isLoading} />
					</div>
					<div>
						<ButtonSquareSave onClick={onClickSave} disabledButton={isLoading} isLoading={isLoading} />
					</div>
				</div>
			</div>
			<Table
				locale={{
					emptyText: (
						<>
							{isErrorGetKeysAdapter || isErrorGetBank ? (
								<TableNoDataBox
									isError={isErrorGetKeysAdapter || isErrorGetBank}
									errorMessage={
										errorMessage && errorMessage.data && errorMessage.data.message ? errorMessage.data.message : "Error"
									}
									noItemsFound='No actions found'
								/>
							) : (
								<div>
									{(bank.interface === null || JSON.stringify(bank.interface) === "{}") && (
										<div className='generalStyles__flexCenter' style={{ height: "300px" }}>
											<div>
												{((dataGetKeysAdapter && dataGetKeysAdapter.data && dataGetKeysAdapter.data.length === 0) ||
													dataGetKeysAdapter === undefined) && <p>Adapter does not have keys assigned</p>}
												<p>{t("general.interfaceNoAssigned")}</p>
												<Button style={{ marginTop: "-5px" }} onClick={handleOpenAssignInterface}>
													Assign Interface
												</Button>
											</div>
										</div>
									)}
								</div>
							)}
						</>
					),
				}}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={data}
				pagination={{
					defaultPageSize: 10,
					showSizeChanger: true,
					pageSizeOptions: ["10", "25", "50", "100"],
				}}
				rowKey={(record) => record.idMessage}
				size='small'
				className='mt-3'
				loading={
					isFetchingKeysAdapter ||
					isLoadingGetKeysAdapter ||
					isLoadingDeleteKey ||
					isFetchingGetBank ||
					isLoadingGetBank ||
					isLoadingPostKey ||
					isLoadingPutKey ||
					isLoading
				}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{totalRows} Rows</Tag>
					</div>
				)}
			>
				<Column
					title={t("general.state")}
					dataIndex='state'
					key='state'
					align='center'
					render={(text, record: any) => (
						<>
							{record.isValid ? (
								<FontAwesomeIcon className='generalStyles__success' icon={faCircleCheck} />
							) : (
								<Tooltip title={t("general.keyDuplicated")}>
									<FontAwesomeIcon className='generalStyles__error' icon={faXmarkCircle} />
								</Tooltip>
							)}
						</>
					)}
				/>
				<Column title={t("general.message")} dataIndex='message' key='message' render={(text) => <>{text}</>} />
				<Column title={t("general.type")} dataIndex='type' key='type' render={(text) => <>{text}</>} />
				{interfaceKeys.map((item: string) => (
					<Column
						title={item}
						dataIndex={item}
						key={item}
						render={(text, record: any, index: number) => (
							<InputNumber
								bordered={false}
								className='configuration__inputSizeS'
								min={0}
								max={999}
								precision={0}
								value={record[item]}
								onChange={(e) => {
									onChangeInputNumber(e, item, index);
								}}
							/>
						)}
					/>
				))}
				{/** DELETE */}
				<Column
					title='Delete'
					dataIndex='delete'
					key='delete'
					render={(text, record: any) => (
						<Button
							onClick={() => {
								onClickDelete(record);
							}}
							shape='circle'
							className={!record.isValid ? "" : "buttonStyle__26"}
							disabled={!record.isValid || record.methodType === "POST"}
						>
							<FontAwesomeIcon icon={faTrash} />
						</Button>
					)}
				/>
			</Table>
			<GlobalAlert2
				isError={isErrorDeleteKey}
				isSuccess={isSuccessDeleteKey}
				requestType='DELETE'
				error={errorDeleteKey}
				name='Key'
			/>
			<GlobalAlert2
				isError={isErrorPostKey}
				isSuccess={isSuccessPostKey}
				requestType='POST'
				error={errorPostKey}
				name='Key'
			/>
			<GlobalAlert2
				isError={isErrorPutKey}
				isSuccess={isSuccessPutKey}
				requestType='PUT'
				error={errorPutKey}
				name='Key'
			/>
		</div>
	);
}

export default KeysBankTable;
