import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Checkbox, Input, Table, InputNumber, Empty } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// NAVIGATION
import { useLocation } from "react-router-dom";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetBankQuery, usePutBankMutation } from "../../../redux/banks/bankAPI";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import BanksFloorConfigurationHeader from "./BanksFloorConfigurationHeader";
import GlobalAlert2 from "../../home/GlobalAlert2";
import TableNoDataBox from "../../components/table/TableNoDataBox";
// INTERFACES
import { BankFloorInterface, LiftInterface } from "../../../interfaces/EdgeManagement.interface";
import { BankInterface } from "../../../interfaces/Bank.interface";
import { RootState } from "../../../app/store";

function BanksFloorConfigurationTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const location = useLocation();
	const bankId = new URLSearchParams(location.search).get("idBank");
	const { Column, ColumnGroup } = Table;
	const [t] = useTranslation("global");
	const BLANK_BANK: BankInterface = {
		id: "",
		active: false,
		board: 0,
		columns: 0,
		floor_below_lobby: 0,
		floor_count: 0,
		logical_port: 0,
		name: "",
		number_of_lifts: 0,
		channel_id: "",
		rear_doors: false,
		stops: 0,
		type: "",
		control_panel: "",
		bank_floors: [],
		lifts: [],
		server_id: "",
		start_floor_no: 1,
		interface: {},
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [bank, setBank] = useState<BankInterface>(BLANK_BANK);
	const [errorMessage, setErrorMessage] = useState<any>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetBank,
		{ data, isLoading: isLoadingGetBank, isFetching: isFetchingGetBank, isError: isErrorGetBank, error: errorGetBank },
	] = useLazyGetBankQuery();

	const [
		triggerPutBank,
		{
			isSuccess: isSuccessPutBank,
			isError: isErrorPutBank,
			error: errorPutBank,
			isLoading: isLoadingPutBank,
			reset: resetPutBank,
		},
	] = usePutBankMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetBank({
			bankId,
			token,
		});
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		//
		const copy: BankInterface = structuredClone(bank);
		delete copy.data_blocks;
		delete copy.lifts;
		const BODY = {
			id: bank.id,
			body: {
				...copy,
				lift_floors: bank.lifts,
			},
			token,
		};
		triggerPutBank(BODY);
	};

	const onChangeStartFloorNumber = (newValue: boolean) => {
		const copy: BankInterface = structuredClone(bank);
		copy.start_floor_no = newValue ? 1 : 0;
		setBank(copy);
	};

	const onChangeInputTextValue = (newValue: any, bankFloorId: string, inputName: string) => {
		if (bank.bank_floors) {
			const index = bank.bank_floors.findIndex((x: any) => x.id === bankFloorId);
			if (index >= 0) {
				const copy: any = structuredClone(bank);
				copy.bank_floors[index][inputName] = newValue;
				setBank(copy);
			}
		}
	};

	const onChangeDoorLift = (newValue: any, liftId: string, bankFloorId: string, doorType: string) => {
		const copy = structuredClone(bank);
		if (copy.lifts) {
			const liftIndex = copy.lifts.findIndex((x: any) => x.id === liftId);
			if (liftIndex >= 0) {
				if (copy && copy.lifts && copy.lifts[liftIndex] && copy.lifts[liftIndex].lift_floors) {
					const floorIndex = copy.lifts[liftIndex].lift_floors.findIndex((x: any) => x.bank_floor_id === bankFloorId);
					if (floorIndex >= 0) {
						if (doorType === "front") {
							copy.lifts[liftIndex].lift_floors[floorIndex].front_door = newValue.target.checked;
						} else if (doorType === "rear") {
							copy.lifts[liftIndex].lift_floors[floorIndex].rear_door = newValue.target.checked;
						}
					}
				}
			}
		}
		setBank(copy);
	};

	const onChangeDoorBank = (newValue: any, bankFloorId: string, doorType: string) => {
		const copy = structuredClone(bank);
		if (copy.bank_floors) {
			const index = copy.bank_floors.findIndex((x: any) => x.id === bankFloorId);
			if (index >= 0) {
				if (doorType === "front") {
					copy.bank_floors[index].front_door = newValue.target.checked;
				} else {
					copy.bank_floors[index].rear_door = newValue.target.checked;
				}
				setBank(copy);
			}
		}
	};

	const checkInterminateCheckBox = (returnValue: string, object: any, valueName: string) => {
		// Extracting the values of the property
		const values = object.map((obj: any) => obj[valueName]);
		// Checking if all values are true
		const allTrue = values.every((value: any) => value === true);
		// Checking if all values are false
		const allFalse = values.every((value: any) => value === false);
		// Checking if there are mixed values
		const mixedValues = !allTrue && !allFalse;
		if (returnValue === "mixed") {
			return mixedValues;
		}
		if (allTrue) {
			return true;
		}
		return false;
	};

	const checkAllBoxes = (e: any, objectName: string, valueName: string, liftId: string) => {
		if (objectName === "bank_floors" && bank.bank_floors) {
			const copy: any = structuredClone(bank);
			copy.bank_floors.map((item: any, index: number) => {
				copy.bank_floors[index][valueName] = e.target.checked;
				return true;
			});
			setBank(copy);
		} else if (objectName === "lifts" && bank.lifts) {
			const copy: any = structuredClone(bank);
			const liftIndex = copy.lifts.findIndex((x: any) => x.id === liftId);
			if (liftIndex >= 0) {
				copy.lifts[liftIndex].lift_floors.map((item: any, index: number) => {
					copy.lifts[liftIndex].lift_floors[index][valueName] = e.target.checked;
					return true;
				});
			}
			setBank(copy);
		}
	};

	const doorCheckedValue = (liftId: string, bankFloorId: string, doorType: string) => {
		if (bank.lifts) {
			const liftIndex = bank.lifts.findIndex((x: any) => x.id === liftId);
			if (liftIndex >= 0) {
				const floorIndex = bank.lifts[liftIndex].lift_floors.findIndex((x: any) => x.bank_floor_id === bankFloorId);
				if (floorIndex >= 0) {
					if (doorType === "front") {
						return bank.lifts[liftIndex].lift_floors[floorIndex].front_door;
					}
					return bank.lifts[liftIndex].lift_floors[floorIndex].rear_door;
				}
			}
		}
		return false;
	};

	const refreshPage = () => {
		getInitialData();
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		getInitialData();
		return () => {
			resetPutBank();
		};
	}, []);

	useEffect(() => {
		if (data && data.data) {
			setBank(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (errorGetBank) {
			setErrorMessage(errorGetBank);
		}
	}, [errorGetBank]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='mb-2'>
				<BanksFloorConfigurationHeader
					startFloorNumber={bank.start_floor_no === 1}
					bankName={bank.name}
					onClickSave={onClickSave}
					onClickRefreshPage={refreshPage}
					onChangeStartFloorNumber={onChangeStartFloorNumber}
				/>
			</div>
			<Table
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				locale={{
					emptyText: (
						<>
							{isErrorGetBank ? (
								<TableNoDataBox
									isError={isErrorGetBank}
									errorMessage={
										errorMessage && errorMessage.data && errorMessage.data.message ? errorMessage.data.message : "Error"
									}
									noItemsFound='Configuration not found'
								/>
							) : (
								<div className='generalStyles__flexCenter' style={{ height: "300px" }}>
									<Empty description='Configuration not found' />
								</div>
							)}
						</>
					),
				}}
				className='customTable'
				dataSource={bank.bank_floors}
				rowKey={(record) => record.id}
				pagination={{
					defaultPageSize: 9999,
					showSizeChanger: false,
				}}
				size='small'
				loading={isLoadingGetBank || isLoadingPutBank || isFetchingGetBank}
			>
				<ColumnGroup>
					{/** FLOOR INDEX */}
					<Column
						title='Floor Index'
						dataIndex='floor_index'
						key='floor_index'
						filterSearch
						width='50px'
						render={(text, record: BankFloorInterface) => (
							<InputNumber
								placeholder={t("edgeManagement.banks.floorName")}
								value={text}
								onChange={(e) => {
									onChangeInputTextValue(e, record.id, "floor_index");
								}}
								bordered={false}
							/>
						)}
					/>
					{/** FLOOR NO */}
					<Column
						title='Floor No'
						dataIndex='floor_no'
						key='floor_no'
						filterSearch
						width='50px'
						render={(text, record: BankFloorInterface) => (
							<InputNumber
								placeholder={t("edgeManagement.banks.floorName")}
								value={text}
								onChange={(e) => {
									onChangeInputTextValue(e, record.id, "floor_no");
								}}
								bordered={false}
							/>
						)}
					/>
					{/** POSITION */}
					<Column
						title='Position'
						dataIndex='position'
						key='position'
						filterSearch
						width='50px'
						render={(text, record: BankFloorInterface) => (
							<InputNumber
								placeholder={t("edgeManagement.banks.floorName")}
								value={text}
								onChange={(e) => {
									onChangeInputTextValue(e, record.id, "position");
								}}
								bordered={false}
							/>
						)}
					/>
					{/** NAME */}
					<Column
						title={t("general.name")}
						dataIndex='name'
						key='name'
						className='generalStyles__tableHeaderLink'
						filterSearch
						width='50px'
						render={(text, record: BankFloorInterface) => (
							<div className='edgeManagement__floorNameName'>
								<Input
									placeholder={t("edgeManagement.banks.floorName")}
									value={text}
									onChange={(e) => {
										onChangeInputTextValue(e.target.value, record.id, "name");
									}}
									bordered={false}
								/>
							</div>
						)}
					/>
				</ColumnGroup>
				{/** BANK FLOORS */}
				<ColumnGroup title='Bank Floors'>
					<Column
						title={
							<div>
								<span className='generalStyles__mrFix'>F</span>
								<span>
									<Checkbox
										indeterminate={checkInterminateCheckBox("mixed", bank.bank_floors, "front_door")}
										checked={checkInterminateCheckBox("all", bank.bank_floors, "front_door")}
										onChange={(e) => {
											checkAllBoxes(e, "bank_floors", "front_door", "");
										}}
										className='edgeManagement__floorNameTootle'
									/>
								</span>
							</div>
						}
						align='center'
						width={70}
						render={(text, record: BankFloorInterface) => (
							<>
								<Checkbox
									className='edgeManagement__floorNameTootle'
									checked={record.front_door}
									onChange={(e) => {
										onChangeDoorBank(e, record.id, "front");
									}}
								/>
							</>
						)}
					/>
					<Column
						title={
							<div>
								<span className='generalStyles__mrFix'>R</span>
								<span>
									<Checkbox
										indeterminate={checkInterminateCheckBox("mixed", bank.bank_floors, "rear_door")}
										checked={checkInterminateCheckBox("all", bank.bank_floors, "rear_door")}
										onChange={(e) => {
											checkAllBoxes(e, "bank_floors", "rear_door", "");
										}}
										className='edgeManagement__floorNameTootle'
									/>
								</span>
							</div>
						}
						align='center'
						width={70}
						render={(text, record: BankFloorInterface) => (
							<>
								<Checkbox
									className='edgeManagement__floorNameTootle'
									checked={record.rear_door}
									onChange={(e) => {
										onChangeDoorBank(e, record.id, "rear");
									}}
								/>
							</>
						)}
					/>
				</ColumnGroup>
				{/** LIFTS */}
				{bank && bank.lifts && (
					<>
						{bank.lifts
							.slice() // Create a shallow copy to avoid mutating the original array
							.sort((a: any, b: any) => {
								// Compare values based on a specific property (e.g., lift.name)
								if (a.position < b.position) return -1;
								if (a.position > b.position) return 1;
								return 0;
							})
							.map((lift: LiftInterface) => (
								<ColumnGroup title={lift.name} key={lift.id}>
									<Column
										title={
											<div>
												<span className='generalStyles__mrFix'>F</span>
												<span>
													<Checkbox
														indeterminate={checkInterminateCheckBox("mixed", lift.lift_floors, "front_door")}
														checked={checkInterminateCheckBox("all", lift.lift_floors, "front_door")}
														onChange={(e) => {
															checkAllBoxes(e, "lifts", "front_door", lift.id);
														}}
														className='edgeManagement__floorNameTootle'
													/>
												</span>
											</div>
										}
										align='center'
										width={100}
										render={(text, record: BankFloorInterface) => (
											<>
												<Checkbox
													className='edgeManagement__floorNameTootle'
													checked={doorCheckedValue(lift.id, record.id, "front")}
													onChange={(e) => {
														onChangeDoorLift(e, lift.id, record.id, "front");
													}}
												/>
											</>
										)}
									/>
									<Column
										title={
											<div>
												<span className='generalStyles__mrFix'>R</span>
												<span>
													<Checkbox
														indeterminate={checkInterminateCheckBox("mixed", lift.lift_floors, "rear_door")}
														checked={checkInterminateCheckBox("all", lift.lift_floors, "rear_door")}
														onChange={(e) => {
															checkAllBoxes(e, "lifts", "rear_door", lift.id);
														}}
														className='edgeManagement__floorNameTootle'
													/>
												</span>
											</div>
										}
										align='center'
										width={100}
										render={(text, record: BankFloorInterface) => (
											<>
												<Checkbox
													className='edgeManagement__floorNameTootle'
													checked={doorCheckedValue(lift.id, record.id, "rear")}
													onChange={(e) => {
														onChangeDoorLift(e, lift.id, record.id, "rear");
													}}
												/>
											</>
										)}
									/>
								</ColumnGroup>
							))}
					</>
				)}
			</Table>
			<GlobalAlert2
				isError={isErrorPutBank}
				isSuccess={isSuccessPutBank}
				requestType='PUT'
				error={errorPutBank}
				name='Bank'
			/>
		</div>
	);
}

export default BanksFloorConfigurationTable;
