import React from "react";
// ANT DESIGN COMPONENTS
import { Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
// INTERFACES
import { ButtonInterface } from "../../../interfaces/Components.interface";
import { RootState } from "../../../app/store";

function ButtonSquareRefresh({ onClick, disabledButton, isLoading }: ButtonInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Tooltip title={t("general.refreshPage")}>
				<Button
					className={`${disabledButton ? `buttonStyle__inactive__1__${theme}` : "buttonStyle__8"} generalStyles__mlFix`}
					style={{ marginTop: "-5px" }}
					onClick={onClick}
					loading={isLoading}
					disabled={disabledButton}
					icon={<FontAwesomeIcon className='' icon={faRefresh} />}
				/>
			</Tooltip>
		</div>
	);
}

export default ButtonSquareRefresh;
