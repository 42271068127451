import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Radio, Empty, Spin, Switch, Alert, Tag } from "antd";
// MOMENT
import moment from "moment";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePostMonitoringCommandMutation,
	usePostEmulatorCommandMutation,
} from "../../../redux/monitoring/monitoringAPI";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import { ControlPanelBankFloors, ControlPanelBankVIPInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
// AUTHENTICATION
import GETJwtToken from "../../../redux/authentication/authentication";

function ControlPanelBankVIP({
	hasFrontDoors,
	hasRearDoors,
	bankFloors,
	liftVip,
	socketMessage,
	clearVipMode,
	onClickClose,
	vipServiceModeStopped,
}: ControlPanelBankVIPInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const hallCallSelected = "U";
	const defaultTimer = 60;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [doorSelected, setDoorSelected] = useState<"F" | "R">("F");
	const [secureCalls, setSecureCalls] = useState<boolean>(false);
	const [spinningFloorNo, setSpinningFloorNo] = useState<number>(0);
	const [serviceModeIndex, setServiceModeIndex] = useState<number>(0);
	const [pickUpFloorIndex, setPickUpFloorIndex] = useState<number>(0);
	const [dropOffFloorIndex, setDropOffFloorIndex] = useState<number>(0);
	const [startPositionName, setStartPositionName] = useState<any>("--");
	const [pickUpFloorName, setPickUpFloorName] = useState<any>("--");
	const [dropOffFloorName, setDropOffFloorName] = useState<any>("--");
	const [carIsOnPosition, setCarIsOnPosition] = useState<boolean>(true);
	const [vipState, setVipState] = useState<"SERVICE_MODE" | "PICK_UP" | "DROP_OFF" | "COMPLETE">("SERVICE_MODE");
	const [showFinalAlert, setShowFinalAlert] = useState<boolean>(false);
	const [timeLeft, setTimeLeft] = useState(defaultTimer);
	const [isRunning, setIsRunning] = useState(false);
	const [vipServiceInterrupted, setVipServiceInterrupted] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { liftSelected, layoutSelected, socketConnection, openModalControlPanelBank } = useSelector(
		(state: RootState) => state.monitoring
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [postCommand, { isLoading: isLoadingPostCommand, isError: isErrorPostCommand, error: errorPostCommand }] =
		usePostMonitoringCommandMutation();
	const [postCommandEmulator, { isLoading: isLoadingPostCommandEmulator }] = usePostEmulatorCommandMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickHallCallVIP = async (floor: number) => {
		setCarIsOnPosition(false);
		const token = await GETJwtToken();
		if (socketConnection !== "EMULATOR") {
			const BODY = {
				token,
				body: {
					cmd_adapter: layoutSelected && layoutSelected.typeChannel && layoutSelected.typeChannel === "ADAPTER",
					cmd_off: false,
					lift_id: layoutSelected.lifts[0].id,
					short_msg: `VIPHC${floor}`,
					type: "CONTROL",
				},
				serverId: layoutSelected.idServer,
			};
			postCommand(BODY);
		} else {
			const BODY = {
				token,
				body: {
					type: "VIP",
					lift_id: liftSelected.id,
					floor,
					bank_id: layoutSelected.idBank,
					front_rear: doorSelected === "F" ? "front" : "rear",
					upDown: hallCallSelected === "U" ? "up" : "down",
					upDownAssigned: hallCallSelected === "U" ? "upAssigned" : "downAssigned",
				},
				serverId: layoutSelected.idServer,
			};
			postCommandEmulator(BODY);
		}
	};

	const onClickCarCall = async (floor: number) => {
		const token = await GETJwtToken();
		if (socketConnection !== "EMULATOR") {
			const BODY = {
				token,
				body: {
					cmd_adapter: layoutSelected && layoutSelected.typeChannel && layoutSelected.typeChannel === "ADAPTER",
					cmd_off: false,
					lift_id: layoutSelected.lifts[0].id,
					short_msg: `${secureCalls ? "S" : ""}E${doorSelected === "R" ? "R" : ""}CC${floor}`,
					type: "CONTROL",
				},
				serverId: layoutSelected.idServer,
			};
			postCommand(BODY);
		} else {
			const BODY = {
				token,
				body: {
					type: "VIP_CALL",
					lift_id: liftVip.id,
					floor,
					bank_id: layoutSelected.idBank,
					front_rear: doorSelected === "F" ? "front" : "rear",
					upDown: hallCallSelected === "U" ? "up" : "down",
					upDownAssigned: hallCallSelected === "U" ? "upAssigned" : "downAssigned",
				},
				serverId: layoutSelected.idServer,
			};
			postCommandEmulator(BODY);
		}
	};

	const sendEmulatorClearCommand = async () => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				type: "VIP_RESET",
				lift_id: liftVip.id,
				floor: socketMessage?.position || 1,
				bank_id: layoutSelected.idBank,
				front_rear: "",
			},
			serverId: layoutSelected.idServer,
		};
		postCommandEmulator(BODY);
	};

	const pauseTimer = () => setIsRunning(false);

	const resetTimer = () => setTimeLeft(defaultTimer);

	const startTimer = () => setIsRunning(true);

	const clearEmulatorVIPMode = () => {
		setCarIsOnPosition(true);
		setPickUpFloorIndex(0);
		setPickUpFloorName("--");
		setDropOffFloorIndex(0);
		setDropOffFloorName("--");
		setServiceModeIndex(0);
		setStartPositionName("--");
		setVipState("SERVICE_MODE");
		setShowFinalAlert(false);
		clearVipMode();
		sendEmulatorClearCommand();
		resetTimer();
		pauseTimer();
	};

	const onClickHallCall = (floor: number, floorName: any) => {
		setCarIsOnPosition(false);
		if (vipState === "SERVICE_MODE") {
			onClickHallCallVIP(floor);
			setStartPositionName(floorName);
			setServiceModeIndex(floor);
			setVipState("PICK_UP");
			resetTimer();
		} else if (vipState === "PICK_UP") {
			onClickCarCall(floor);
			setPickUpFloorName(floorName);
			setPickUpFloorIndex(floor);
			setVipState("DROP_OFF");
			pauseTimer();
			resetTimer();
		} else if (vipState === "DROP_OFF") {
			onClickCarCall(floor);
			setDropOffFloorName(floorName);
			setDropOffFloorIndex(floor);
			setVipState("COMPLETE");
			resetTimer();
			pauseTimer();
		}
	};

	const onChangeSecureCalls = (checked: boolean) => {
		setSecureCalls(checked);
	};

	const getAlertDescription = () => {
		let alertInformation: [string, string, "warning" | "info" | "error" | "success"] = [
			"Select Start Position",
			"Please select the start position.",
			"warning",
		];
		if (vipState === "PICK_UP") {
			if (!liftVip.id) {
				alertInformation = ["Locating", "Locating the lift for assignment", "warning"];
			}
			if (
				liftVip &&
				liftVip.id &&
				socketMessage &&
				socketMessage.position &&
				socketMessage.position !== serviceModeIndex
			) {
				alertInformation = [`${liftVip.name} Assigned`, "Lift moving toward the start position", "warning"];
			}
			if (socketMessage && socketMessage.position && socketMessage.position === serviceModeIndex) {
				alertInformation = [
					`${liftVip.name} is on position ${startPositionName}`,
					"Please select Pick Up call",
					"warning",
				];
			}
		}
		if (vipState === "DROP_OFF") {
			if (
				liftVip &&
				liftVip.id &&
				socketMessage &&
				socketMessage.position &&
				socketMessage.position !== pickUpFloorIndex
			) {
				alertInformation = ["Pick Up floor assigned", "Lift moving toward the pick up position", "warning"];
			}
			if (socketMessage && socketMessage.position && socketMessage.position === pickUpFloorIndex) {
				alertInformation = [
					`${liftVip.name} is on position ${pickUpFloorName}`,
					"Please select Drop Off call",
					"warning",
				];
			}
		}
		if (vipState === "COMPLETE") {
			if (
				liftVip &&
				liftVip.id &&
				socketMessage &&
				socketMessage.position &&
				socketMessage.position !== dropOffFloorIndex
			) {
				alertInformation = ["Drop Off floor assigned", "Lift moving toward the drop off position", "warning"];
			}
		}
		return alertInformation;
	};

	const formatDuration = () => {
		const duration = moment.duration(timeLeft, "seconds");
		return `${duration.minutes()} min ${duration.seconds()} sec`;
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (socketMessage && socketMessage.position) {
			if (socketMessage.position === serviceModeIndex) {
				setCarIsOnPosition(true);
				startTimer();
				setVipServiceInterrupted(false);
			}
			if (socketMessage.position === pickUpFloorIndex) {
				setCarIsOnPosition(true);
				startTimer();
				setVipServiceInterrupted(false);
			}
			if (socketMessage.position === dropOffFloorIndex) {
				setCarIsOnPosition(true);
				setShowFinalAlert(true);
				sendEmulatorClearCommand();
				setVipServiceInterrupted(false);
			}
		}
	}, [socketMessage]);

	useEffect(() => {
		if (!openModalControlPanelBank) {
			sendEmulatorClearCommand();
			clearEmulatorVIPMode();
		}
	}, [openModalControlPanelBank]);

	useEffect(() => {
		if (isErrorPostCommand) {
			clearEmulatorVIPMode();
		}
	}, [isErrorPostCommand]);

	useEffect(() => {
		if (!isRunning || timeLeft <= 0) {
			if (timeLeft === 0) {
				clearEmulatorVIPMode();
			}
			return;
		}

		const timer = setInterval(() => {
			setTimeLeft((prev) => prev - 1);
		}, 1000);

		// eslint-disable-next-line consistent-return
		return () => clearInterval(timer);
	}, [timeLeft, isRunning]);

	useEffect(() => {
		if (vipServiceModeStopped && vipState !== "COMPLETE") {
			clearEmulatorVIPMode();
			setVipServiceInterrupted(true);
			setShowFinalAlert(true);
		}
	}, [vipServiceModeStopped]);

	useEffect(() => {
		if (!hasFrontDoors && hasRearDoors) {
			setDoorSelected("R");
		} else if (hasFrontDoors && !hasRearDoors) {
			setDoorSelected("F");
		} else {
			setDoorSelected("F");
		}
	}, [hasFrontDoors, hasRearDoors]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className={`montoringView__controlPanel__${theme}`}
			style={{ minHeight: "400px", height: "auto", padding: "20px" }}
		>
			{showFinalAlert ? (
				<div className={` drawer__box__control__panel__${theme}  `}>
					{vipServiceInterrupted ? (
						<Alert
							message='VIP Service mode interrupted'
							type='error'
							action={
								<Button size='small' type='primary' onClick={clearEmulatorVIPMode}>
									Accept
								</Button>
							}
							closable={false}
						/>
					) : (
						<Alert
							message='VIP Service mode complete'
							type='success'
							action={
								<Button
									size='small'
									type='primary'
									onClick={() => {
										clearEmulatorVIPMode();
										setVipServiceInterrupted(false);
									}}
								>
									Accept
								</Button>
							}
							closable={false}
						/>
					)}
				</div>
			) : (
				<>
					<div>
						{/** STATUS VIP SERVICE */}
						<div className={` drawer__box__control__panel__${theme}`}>
							<Alert
								className='mb-3'
								message={getAlertDescription()[0]}
								description={getAlertDescription()[1]}
								type={getAlertDescription()[2]}
								showIcon
								icon={
									<FontAwesomeIcon
										icon={faLock}
										fontSize={18}
										className='monitoring__modalSettings__icon generalStyles__mrFix'
									/>
								}
							/>
							<div className='generalStyles__spaceBetween'>
								<div>
									Start Position:
									<Button
										className={
											startPositionName !== "--"
												? "monitoringView__controlPanelNumber__active__vip monitoring__modalSettings__button__1"
												: "monitoring__modalSettings__button__1"
										}
										shape='circle'
										disabled
									>
										<span>{startPositionName}</span>
									</Button>
								</div>
								<div className='generalStyles__mlFix25'>
									Pick Up Floor:
									<Button
										className={
											pickUpFloorName !== "--"
												? "monitoringView__controlPanelNumber__active__vip monitoring__modalSettings__button__1"
												: "monitoring__modalSettings__button__1"
										}
										shape='circle'
										disabled
									>
										<span>{pickUpFloorName}</span>
									</Button>
								</div>
								<div className='generalStyles__mlFix25'>
									Drop off Floor:{" "}
									<Button
										className={
											dropOffFloorName !== "--"
												? "monitoringView__controlPanelNumber__active__vip monitoring__modalSettings__button__1"
												: " monitoring__modalSettings__button__1"
										}
										shape='circle'
										disabled
									>
										<span>{dropOffFloorName}</span>
									</Button>
								</div>
							</div>
						</div>
						{/** PANEL / CIRCLE BUTTONS */}
						<div className={`drawer__box__control__panel__${theme} mt-3`}>
							<Spin spinning={!carIsOnPosition}>
								{(hasFrontDoors || hasRearDoors) && (
									<>
										{/** ACTIONS */}
										<div className='generalStyles__spaceBetween'>
											<div className='generalStyles__flex'>
												<Radio.Group
													value={doorSelected}
													onChange={(e) => {
														setDoorSelected(e.target.value);
													}}
												>
													{hasFrontDoors && <Radio value='F'>{t("general.frontDoors")}</Radio>}
													{hasRearDoors && <Radio value='R'>{t("general.rearDoors")}</Radio>}
												</Radio.Group>
											</div>
											{layoutSelected &&
												layoutSelected.idOrganization &&
												layoutSelected.idOrganization === "88bed3b9-28b1-452f-a2bb-f4a29646bbf8" && (
													<div>
														{secureCalls && (
															<FontAwesomeIcon
																icon={faLock}
																fontSize={18}
																className='monitoring__modalSettings__icon generalStyles__mrFix'
															/>
														)}
														<span className='generalStyles__mrFix' style={{ marginTop: "5px" }}>
															{t("general.secureCalls")}
														</span>
														<Switch checked={secureCalls} onChange={onChangeSecureCalls} />
													</div>
												)}
										</div>
									</>
								)}
								<div className='monitoringView__controlPanelPanel mt-3'>
									{hasFrontDoors || hasRearDoors ? (
										<>
											{bankFloors.map((floor: ControlPanelBankFloors) => (
												<div key={floor.id}>
													<Button
														className='monitoring__modalSettings__button__1'
														shape='circle'
														onClick={() => {
															onClickHallCall(
																floor.floor_no,
																floor && floor.name && floor.name !== ""
																	? floor.name.substring(0, 3).toUpperCase()
																	: floor.floor_no
															);
															setSpinningFloorNo(floor.floor_no);
														}}
														disabled={
															(doorSelected === "F" && !floor.front_door) ||
															(doorSelected === "R" && !floor.rear_door) ||
															!carIsOnPosition
														}
													>
														{(isLoadingPostCommand || isLoadingPostCommandEmulator) &&
														spinningFloorNo === floor.floor_no ? (
															<Spin
																spinning={
																	(isLoadingPostCommand || isLoadingPostCommandEmulator) &&
																	spinningFloorNo === floor.floor_no
																}
															/>
														) : (
															<span style={{ marginTop: "3px" }}>
																{floor && floor.name && floor.name !== ""
																	? floor.name.substring(0, 3).toUpperCase()
																	: floor.floor_no}
															</span>
														)}
													</Button>{" "}
												</div>
											))}
										</>
									) : (
										<Empty description={<span>{t("general.bankHasNoFrontRearDoors")}</span>} />
									)}
								</div>
							</Spin>
							<div className='generalStyles__flexCenter mt-3'>
								{isRunning && (
									<div>
										<Tag color='blue'>{formatDuration()} </Tag>
									</div>
								)}
							</div>
						</div>
						{/** CANCEL PROCESS */}
						{(vipState === "PICK_UP" || vipState === "DROP_OFF") && (
							<div className='generalStyles__flexCenter mt-3 generalStyles__width100'>
								<Button onClick={onClickClose} className='buttonStyle__25 generalStyles__width100'>
									CANCEL
								</Button>
							</div>
						)}
					</div>
				</>
			)}
			<GlobalAlert2
				isError={isErrorPostCommand}
				isSuccess={false}
				requestType='POST'
				error={errorPostCommand}
				name='VIP'
			/>
		</div>
	);
}

export default ControlPanelBankVIP;
