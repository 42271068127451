/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Empty } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faTrash,
	faPenToSquare,
	faBan,
	faKey,
	faSearch,
	faFilter,
	faFileExport,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetServersQuery, usePostSecretClientTokenMutation } from "../../../redux/servers/serverAPI";
import {
	updateOpenModalDeleteServer,
	updateOpenModalEditServer,
	updateOpenDrawerServer,
	updateServer,
	updateServerTableParams,
	updateServerBreadcrumb,
	updateIdServerSelected,
	updateNameServerSelected,
	updateOpenModalSecretClient,
	updateOpenModalExportServer,
} from "../../../redux/servers/serverSlice";
import { updateRenderComponent } from "../../../redux/channels/channelSlice";
import { udpateOpenModalTotpToken, updateTotpTokenIsValid } from "../../../redux/authentication/authenticationSlice";
import { updateBreadcrumbTitle } from "../../../redux/home/homeSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import AuthenticationTOTP from "../../authentication/AuthenticationTOTP";
import ServerModalSecretClient from "./ServerModalSecretClient";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
import FilterSelectOrganization from "../../components/filters/FilterSelectOrganization";
// UTILS
import { getTableRowClass, buildFilterString } from "../../../utils/utils";

const ServersTable = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const navigate = useNavigate();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [servers, setServers] = useState<any[]>([]);
	const [selectedItem, setSelectedItem] = useState<any>({});
	const [idServerSelected, setIdServerSelected] = useState("");
	const [secretClientToken, setSecretClientToken] = useState("");
	const [filterName, setFilterName] = useState("");
	const [filterIP, setFilterIP] = useState("");
	const [filterOrganizationName, setFilterOrganizationName] = useState("");
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { totpTokenIsValid, totpToken } = useSelector((state: any) => state.authentication);
	const { theme, userLogged } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetServersTable, { data, isLoading, isError, isFetching }] = useLazyGetServersQuery();
	const [triggerGetSecretClientMutation, { data: dataSecretClientToken, isLoading: isLoadingSecretClientToken }] =
		usePostSecretClientTokenMutation();
	const { serverTableParams, serverRefreshTable, clearFilters } = useSelector((state: any) => state.server);

	// ************************************************ */
	// TABLE ACTIONS ************************************ */

	const { Column } = Table;

	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faBan} />
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const SECRET_CLIENT = validatePermission("servers-oauth-create", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalTotpToken();
							}}
							onKeyDown={() => {
								handleOpenModalTotpToken();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faKey} />
								{t("edgeManagement.servers.secretClient")}
							</span>
						</div>
					),
					key: "2",
			  }
			: null;

		const EDIT = validatePermission("servers-update", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalEdit();
							}}
							onKeyDown={() => {
								handleOpenModalEdit();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faPenToSquare} />
								{t("organizationManagement.edit")}
							</span>
						</div>
					),
					key: "3",
			  }
			: null;

		const DELETE = validatePermission("servers-delete", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalDelete();
							}}
							onKeyDown={() => {
								handleOpenModalDelete();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faTrash} />
								{t("organizationManagement.delete")}
							</span>
						</div>
					),
					key: "4",
			  }
			: null;

			const EXPORTOPTION = validatePermission("servers-export", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalExport();
							}}
							onKeyDown={() => {
								handleOpenModalExport();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faFileExport} />
								{t("organizationManagement.exportDescription")}
							</span>
						</div>
					),
					key: "5",
			  }
			: null;

		// RETURN OBJECT WITH VALID ACTIONS
		if (EDIT || DELETE || SECRET_CLIENT || EXPORTOPTION) {
			return [SECRET_CLIENT, EDIT, DELETE, EXPORTOPTION];
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalTotpToken = () => {
		dispatch(udpateOpenModalTotpToken(true));
	};

	const handleOpenModalEdit = () => {
		dispatch(updateServer(selectedItem));
		dispatch(updateOpenModalEditServer(true));
	};

	const handleOpenModalDelete = () => {
		dispatch(updateServer(selectedItem));
		dispatch(updateOpenModalDeleteServer(true));
	};

	const handleOpenModalExport = () => {
		dispatch(updateServer(selectedItem));
		dispatch(updateOpenModalExportServer(true));
	};

	const onCellNameClick = (record: any) => {
		if (record) {
			dispatch(updateServerBreadcrumb([record.name]));
			dispatch(updateIdServerSelected(record.id));
			dispatch(updateNameServerSelected(record.name));
			dispatch(updateServer(record));
		}
	};

	const onCellChannelsClick = (record: any) => {
		if (record) {
			navigate("/edge-management/servers/channels");
			localStorage.setItem("headerTitle", "channels");
			dispatch(updateBreadcrumbTitle("channels"));
			dispatch(updateRenderComponent("servers"));
			dispatch(updateServer(record));
		}
	};

	const onCellClick = (record: any) => {
		if (record) {
			dispatch(updateServer(record));
			dispatch(updateOpenDrawerServer(true));
		}
	};

	const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
		dispatch(
			updateServerTableParams({
				pagination,
				filters,
				...sorter,
			})
		);
		//
		const token = await GETJwtToken();
		triggerGetServersTable({
			page: pagination.current,
			limit: pagination.pageSize,
			token,
			orgId: idServerSelected,
		});
	};

	const refreshTable = (orgId: string) => {
		const searchAndFilterString = buildFilterString({
			filterName,
			filterIP,
			filterOrganizationName,
		});
		getData(orgId, searchAndFilterString);
	};

	const getData = async (orgId: string, searchAndFilterString: string) => {
		dispatch(
			updateServerTableParams({
				pagination: {
					current: 1,
					pageSize: 10,
					total: serverTableParams.pagination.total,
				},
				filters: {},
				sorter: {},
			})
		);
		//
		const token = await GETJwtToken();
		await triggerGetServersTable({
			page: 1,
			limit: 10,
			token,
			orgId,
			searchAndFilterString,
		}).unwrap();
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetServersTable({
			page: serverTableParams.pagination.current,
			limit: serverTableParams.pagination.pageSize,
			token,
		});
	};

	const TotpTokenIsValid = async () => {
		if (totpTokenIsValid) {
			const token = await GETJwtToken();
			triggerGetSecretClientMutation({
				token,
				body: {
					server_id: selectedItem.id,
					totpToken,
				},
			});
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (userLogged && userLogged.org) {
			setIdServerSelected(userLogged.org.id);
			getInitialData();
		}
	}, []);

	useEffect(() => {
		if (data && data.data) {
			dispatch(
				updateServerTableParams({
					...serverTableParams,
					pagination: {
						...serverTableParams.pagination,
						total: data.total,
					},
				})
			);
			setServers(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			setServers([]);
		}
	}, [isError]);

	useEffect(() => {
		if (serverRefreshTable) {
			refreshTable(idServerSelected);
		}
	}, [serverRefreshTable]);

	useEffect(() => {
		if (totpTokenIsValid) {
			TotpTokenIsValid();
			setTimeout(() => {
				dispatch(updateTotpTokenIsValid(false));
			}, 1000);
		}
	}, [totpTokenIsValid]);

	useEffect(() => {
		if (dataSecretClientToken && dataSecretClientToken.data && dataSecretClientToken.data.Secret) {
			setSecretClientToken(dataSecretClientToken.data.Secret);
			dispatch(updateOpenModalSecretClient(true));
		}
	}, [dataSecretClientToken]);

	useEffect(() => {
		if (clearFilters) {
			setFilterName("");
			setFilterIP("");
			setFilterOrganizationName("");
			getData(idServerSelected, "");
		}
	}, [clearFilters]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("servers-view", permissions) ? (
				<>
					{!isError ? (
						<Table
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							className='customTable'
							dataSource={servers}
							loading={isLoading || isFetching || isLoadingSecretClientToken}
							rowKey={(record) => record.id}
							size='small'
							pagination={serverTableParams.pagination}
							onChange={handleTableChange}
							scroll={{ x: 900 }}
						>
							{/** NAME */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellNameClick(record);
									},
								})}
								title={t("edgeManagement.servers.serverName")}
								dataIndex='name'
								key='name'
								className='generalStyles__tableHeaderLink'
								filterIcon={<FontAwesomeIcon icon={faSearch} />}
								filterDropdown={() => (
									<FilterSearchText
										placeHolder={t("general.name")}
										filterValue={filterName}
										setFilterValue={setFilterName}
										refreshTable={() => {
											refreshTable(idServerSelected);
										}}
										refreshRequireParamenters
										refreshParameters={idServerSelected}
									/>
								)}
								render={(text) => (
									<>
										<div>{text}</div>
									</>
								)}
							/>
							{/** TOTAL CHANNELS ASIGNED */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellChannelsClick(record);
									},
								})}
								width='150px'
								className='generalStyles__tableHeaderLink'
								title={t("edgeManagement.channels.channels")}
								dataIndex='organizacion'
								key='organization'
								render={(text, record: any) => (
									<>
										<div>{record && record.channels ? record.channels.length : 0}</div>
									</>
								)}
							/>
							{/** IP */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("edgeManagement.servers.ip")}
								dataIndex='ip'
								key='ip'
								filterIcon={<FontAwesomeIcon icon={faSearch} />}
								filterDropdown={() => (
									<FilterSearchText
										placeHolder='IP'
										filterValue={filterIP}
										setFilterValue={setFilterIP}
										refreshTable={() => {
											refreshTable(idServerSelected);
										}}
										refreshRequireParamenters
										refreshParameters={idServerSelected}
									/>
								)}
								render={(text) => (
									<>
										<div>{text}</div>
									</>
								)}
							/>

							{/** TOTAL BANKS ASIGNED */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								width='150px'
								title={t("edgeManagement.banks.banks")}
								dataIndex='organizacion'
								key='organization'
								render={(text, record: any) => (
									<>
										<div>{record && record.banks ? record.banks.length : 0}</div>
									</>
								)}
							/>
							{/** ORGANIZATION */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("edgeManagement.servers.organization")}
								dataIndex='organizacion'
								key='organization'
								filterIcon={<FontAwesomeIcon icon={faFilter} />}
								filterDropdown={() => (
									<FilterSelectOrganization
										placeHolder={t("organizationManagement.organization")}
										setFilterValue={setFilterOrganizationName}
										filterValue={filterOrganizationName}
										refreshTable={() => {
											refreshTable(idServerSelected);
										}}
										refreshParameters={[]}
									/>
								)}
								render={(text, record: any) => (
									<>
										<div>{record && record.org && record.org.name ? record.org.name : "--"}</div>
									</>
								)}
							/>
							{/** ACTIONS */}
							<Column
								title={t("edgeManagement.servers.actions")}
								key='action'
								width='150px'
								render={(_: any, record: any) => (
									<Dropdown overlay={menu} trigger={["click"]}>
										<Space
											className='generalStyles__hoverCursor'
											onClick={() => {
												setSelectedItem(record);
											}}
										>
											<FontAwesomeIcon icon={faEllipsis} />
										</Space>
									</Dropdown>
								)}
							/>
						</Table>
					) : (
						<Alert
							message={t("organizationManagement.errorDescription.error")}
							description={t("organizationManagement.errorDescription.text1")}
							type='error'
							showIcon
							className='mt-3'
						/>
					)}
				</>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty description={t("edgeManagement.servers.listNotAvailable")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</div>
				</>
			)}
			{/** ********************************************* */}
			{/** AUTHENTICATION TOPTP TOKEN */}
			<AuthenticationTOTP />
			{/** ********************************************* */}
			{/** SECRET CLIENT */}
			<ServerModalSecretClient secretClientToken={secretClientToken} />
		</div>
	);
};

export default ServersTable;
