import React from "react";
// ANT DESIGN COMPONENTS
import { Button, Tooltip, Checkbox, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faRefresh } from "@fortawesome/free-solid-svg-icons";
// INTERFACES
import { BanksFloorConfigurationHeaderInterface } from "../../../interfaces/Bank.interface";

function BanksFloorConfigurationHeader({
	bankName,
	startFloorNumber,
	onClickRefreshPage,
	onClickSave,
	onChangeStartFloorNumber,
}: BanksFloorConfigurationHeaderInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChange = (e: any) => {
		onChangeStartFloorNumber(e.target.checked);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__pageHeader' style={{ display: "flex", justifyContent: "space-between" }}>
			<div>
				<div className='generalStyles__flex'>
					{" "}
					<h5 className='generalStyles__info'>{bankName}</h5>
				</div>
			</div>
			<div>
				<div className='generalStyles__flexEnd '>
					<div>
						<Tag color='blue' style={{ marginTop: "4px" }}>
							Star Floor Number 1:
						</Tag>{" "}
						<Checkbox checked={startFloorNumber} onChange={onChange} />
					</div>
					<div>
						<Tooltip title={t("general.refreshPage")}>
							<Button
								className='buttonStyle__8 generalStyles__mlFix'
								style={{ marginTop: "-5px" }}
								onClick={onClickRefreshPage}
								icon={<FontAwesomeIcon className='' icon={faRefresh} />}
							/>
						</Tooltip>
					</div>
					<div>
						<Tooltip title={t("general.save")}>
							<Button
								icon={<FontAwesomeIcon className='' icon={faSave} />}
								className='buttonStyle__17 generalStyles__mlFix'
								style={{ marginTop: "-5px" }}
								onClick={onClickSave}
							/>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BanksFloorConfigurationHeader;
